<template>
  <div :class="rowColor" class="information-row-tab">
    <div class="information-row-tab-first-row" @click="changeView(poradie, relativeChange, absoluteChange)">
      <p class="change-pill-data">{{ relativeChange }}</p>
    </div>
    <div class="information-row-tab-second-row">
      <p class="information-row-tab-second-row-headerOf">{{ header }}</p>
      <div class="flex information-row-tab-second-row-numberOf-group">
        <p class="information-row-tab-second-row-numberOf">{{ number }}</p>
        <RouterLink
            :to=routeView
            class="router-link"
            :class="{'router-link-exact-active': $route.path === routeView}"
        >
          <i class="pi" :class="iconClass" style="color: black"></i>
        </RouterLink>
      </div>
    </div>
  </div>
  <div :class="rowColor" class="information-row-tab-mobile rounded-lg p-4">
    <div class="information-row-tab-second-row">
      <p class="information-row-tab-second-row-headerOf">{{ header }}</p>
      <div class="flex information-row-tab-second-row-numberOf-group">
        <p class="information-row-tab-second-row-numberOf">{{ number }}</p>
      </div>
    </div>
    <div class="information-row-tab-first-row">
      <p>{{ relativeChange }}</p>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  props: {
    header: String,
    relativeChange: String,
    number: String,
    iconClass: String,
    rowColor: String,
    routeView: String,
    absoluteChange: String,
    poradie: Number,
  },
  setup(props) {
    const changeView = (poradie, relativeChange, absoluteChange) => {
      //get all p of class change-pill-data
      if(document.getElementsByClassName("change-pill-data").item(poradie).innerText === absoluteChange){
        document.getElementsByClassName("change-pill-data").item(poradie).innerText = relativeChange;
        document.getElementsByClassName("change-pill-data").item(poradie).classList.remove("selected-pill");
      } else {
        document.getElementsByClassName("change-pill-data").item(poradie).innerText = absoluteChange;
        document.getElementsByClassName("change-pill-data").item(poradie).classList.add("selected-pill");
      };
    };
    return {
      changeView,
    };
  },
};
</script>

<style lang="scss">
.color-setting-first-tab {
  background-color: #FF5C33;
}

.color-setting-second-tab {
  background-color: #B2C3C0;
}

.color-setting-third-tab {
  background-color: #E5E4E3;
}
.information-row-tab{
  width: 100%;
  border-radius: 12px;
  margin-top: 20px;
  .information-row-tab-first-row{
    border: 1px solid black;
    border-radius: 15px;
    text-align: center;
    margin-top:20px;
    margin-left: 20px;
    display: inline-block;
    p{
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 4px;
    }
  }
  .information-row-tab-second-row{
    margin: 30px 0 10px 20px;
    .information-row-tab-second-row-numberOf-group{
      justify-content: space-between;
      align-items: center;
      i{
        margin-right: 20px;
        margin-top: 20px;
      }
    }
    .information-row-tab-second-row-headerOf{
      font-size: 18px;
    }
    .information-row-tab-second-row-numberOf{
      font-size: 48px;
      font-weight: 500;
    }
  }
}
.information-row-tab-mobile{
  display: none;
}

li {
  cursor: pointer;
  color: grey;
  padding-right: 10px;
}
li:last-child{
  margin-right: 0;
}
li.black {
  font-weight: bold;
  color: black;
}
@media only screen and (max-width: 1310px) {
  .information-row-tab-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .information-row-tab-second-row-headerOf{
      font-size: 18px;
    }
    .information-row-tab-second-row-numberOf{
      font-size: 48px;
      font-weight: 500;
    }
    .information-row-tab-first-row{
      border: 1px solid black;
      border-radius: 30px;
      text-align: center;
      margin-top:20px;
      margin-left: 20px;
      display: inline-block;
      p{
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .information-row-tab{
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .information-row-tab-second-row-headerOf{
    font-size: 12px !important;
  }
  .information-row-tab-second-row-numberOf{
    font-size: 18px !important;
    font-weight: 400;
  }
  .information-row-tabs {
    margin-top: 5px !important;
  }
}
</style>
