<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <main v-else
  >
    <!--    -->
    <!--    Cards-->
    <!--    -->
    <div class="information-row">
      <div class="information-row-filter flex flex-row">
        <div class="information-row-filter-left font-bold ml-4">
          <!--          <p>ID konta: 8622200</p>-->
        </div>
        <div class="information-row-filter-right">
          <ul class="flex">
            <li
                v-for="(filter, index) in filters"
                :key="index"
                @click="changeColor(index, filter.value)"
                :class="{ black: selected === index }"
                style="color: gray; padding-right: 20px;"
            >
              {{ filter.name }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isLoading" class="flex">
        <CSkeleton height="197px" class="mr-4 mt-5" borderRadius="16px"></CSkeleton>
        <CSkeleton height="197px" class="mr-4 mt-5" borderRadius="16px"></CSkeleton>
        <CSkeleton height="197px" class=" mt-5" borderRadius="16px"></CSkeleton>
      </div>
      <div class="information-row-tabs flex gap-4 ml-4" v-else>
        <Card
            v-for="data in cardData"
            :key="data.header"
            :header="data.header"
            :relativeChange="data.relativeChange"
            :number="data.number"
            :iconClass="data.iconClass"
            :rowColor="data.rowColor"
            :routeView="data.routeView"
            :absolute-change="data.absoluteChange"
            :poradie="data.poradie"
        />
      </div>
    </div>
    <!--    -->
    <!--Latest Transactions-->
    <!--    -->

    <!--    <div v-if="isLoading" class="flex">-->
    <!--      <CSkeleton height="40rem" class="m-2" borderRadius="16px"></CSkeleton>-->
    <!--    </div>-->
    <LatestTransactions :client-columns="clientColumns" :client-list="clientList"/>

    <div class="last-row flex gap-10" style="padding-bottom:5px;">
      <!--      -->
      <!--      Chart-->
      <!--      -->
      <div class="last-row-first-column rounded-lg shadow-md bg-white p-7">
        <div class="last-row-first-column-header">
          <div class="last-row-first-column-header-item flex">
            <h2 class="font-bold">{{ $t("property") }}</h2>
            <CSelectButton v-model="selectedStatus"
                           option-label="label"
                           option-value="value"
                           @change="selectedStatus = $event.value"
                           :options="formattedStatus"
                           aria-labelledby="basic"
                           :pt="{
                                button: ({ context }) => ({
                                    class: context.active ? 'switcher-flip-selected' : 'switcher-flip'
                                })
                            }"/>
          </div>
          <hr>
          <!--          <CChart type="bar" :data="chartData" :options="chartOptions" class="h-30rem" v-if="hasData" />-->
          <TotalAumChart v-if="chartData" type="bar" :data="chartData" :options="chartOptions"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {computed, onBeforeMount, onMounted, ref, watch} from 'vue';
import Card from "@/components/CustomUIComponents/Card.vue";


import {useDashboardStore} from "@/stores/dashboardStore";
import {useMainStore} from "@/stores/store";
import {useEnumsStore} from "@/stores/enumsStore";
import {useAuthStore} from "@/stores/authStore";
// import Loader from "@/components/Loader.vue";
import LatestTransactions from "@/components/Dashboard/LatestTransactions.vue";
import TotalAumChart from "@/components/Charts/TotalAumChart.vue";
import {useAccountStore} from "@/stores/accountStore";
import {useI18n} from "vue-i18n";



export default {
  name: 'HomeView',
  components: {LatestTransactions, Card, TotalAumChart},


  setup() {

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    const dashboardStore = useDashboardStore();
    const accountStore = useAccountStore();
    const mainStore = useMainStore();
    const enumsStore = useEnumsStore();
    const authStore = useAuthStore();
    const isLoading = computed(() => mainStore.getIsLoading);
    const hasData = computed(() => chartData);

    const headliness = computed(() => dashboardStore.getHeadliness);
    const lastDeposits = computed(() => dashboardStore.getLastTransactions);
    const totalAum = dashboardStore.getTotalAum;


    const clientList = ref([]);
    const cardData = ref([]);

    const chartData = ref();
    const chartOptions = ref();

    const headFilter = ref("365");

    const selectedStatus = ref('year');

    const totalAumData = ref([]);

    const filters = [
      {name: t("year"), value: "365"},
      {name: t("three_months"), value: "90"},
      {name: t("month"), value: "30"},
      {name: t("week"), value: "7"},
    ];

    const filter2 = ['1d', '1w', '1m', '3m', 'Spolu'];
    const selected = ref(null);
    const selected2 = ref(null);
    selected.value = 0;
    selected2.value = 0;

    watch([headFilter], ([newFilter]) => {
      fetchAndMapHeadlines(newFilter);
    });

    watch([selectedStatus], ([newFilter]) => {
      // dashboardStore.fetchTotalAum(newFilter);
      chartData.value = setChartData();
    });



    const fetchAndMapHeadlines = async (newFilter) => {

      await fetchHeadlinesWithDays(newFilter);



      if (headliness.value && headliness.value.length > 0) {
        cardData.value = headliness.value.map((item) => {
          let header;
          let relativeChange;
          let number;
          let iconClass;
          let rowColor;
          let routeView;
          let absoluteChange;
          let poradie;

          if (item.title === "assets") {
            poradie = 0;
            header = t("assets_volume");
            relativeChange = formatNumber(item.relative_change) + " %";
            absoluteChange = formatNumber(item.absolute_change) + " €";
            number = formatNumber(item.last_number) + " €";
            iconClass = "pi pi-arrow-right";
            rowColor = "color-setting-first-tab";
            routeView = "/assets";
          } else if (item.title === "cash") {
            poradie = 1;
            header = t("cash_volume");
            relativeChange = formatNumber(item.relative_change) + " %";
            absoluteChange = formatNumber(item.absolute_change) + " €";
            number = formatNumber(item.last_number) + " €";
            iconClass = "pi pi-arrow-right";
            rowColor = "color-setting-second-tab";
            routeView = "/assets";
          } else if (item.title === "clients") {
            poradie = 2;
            header = t("number_of_clients");
            relativeChange = formatNumber(item.relative_change) + " %";
            absoluteChange = item.absolute_change.toFixed(0);
            number = item.last_number;
            iconClass = "pi pi-arrow-right";
            rowColor = "color-setting-third-tab";
            routeView = "/clients";
          } else {
            // Handle other cases or set default values
            poradie = 0;
            header = "Unknown";
            absoluteChange = "N/A";
            relativeChange = "N/A";
            number = "N/A";
            iconClass = "pi pi-unknown-icon";
            rowColor = "color-setting-unknown-tab";
            routeView = "/";
          }

          return {
            header,
            relativeChange,
            absoluteChange,
            number,
            iconClass,
            rowColor,
            routeView,
            poradie,
          };
        });
      } else {
        // Handle the case where headliness data is empty or not available
      }
    };

    const setChartData = () => {
      const documentStyle = getComputedStyle(document.documentElement);
      if (selectedStatus.value === "year") {
        return {
          labels: dashboardStore.getYearsAum,
          datasets: [
            {
              label: 'Total AUM',
              backgroundColor: '#F37021',
              borderColor: documentStyle.getPropertyValue('--blue-500'),
              data: dashboardStore.getYearsAumData,
            },
          ],
        };
      } else {
        return {
          labels: dashboardStore.getMonthsAum,
          datasets: [
            {
              label: 'Total AUM',
              backgroundColor: '#F37021',
              borderColor: documentStyle.getPropertyValue('--blue-500'),
              data: dashboardStore.getMonthsAumData,
            },
          ],
        };
      }
    };

    const formatToSkFormat = (number) => {
      return number.toLocaleString('sk-SK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const changeColor = (index, data) => {
      headFilter.value = data;
      selected.value = index;
    };
    const changeColor2 = (index) => {
      selected2.value = index;
    };

    const setChartOptions = () => {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      return {


        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            display: false,
            labels: {
              fontColor: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    };

    onMounted(async () => {
      chartData.value = setChartData();
      chartOptions.value = setChartOptions();
    });

    const fetchAllEnums = async () => {
      try {
        // Call each action individually
        if (enumsStore.getCountries.length === 0) {
          await enumsStore.fetchTransactionsTypes();
          await enumsStore.fetchInvestmentStrategies();
          await enumsStore.fetchContractTypes();
          await enumsStore.fetchFileTypes();
          await enumsStore.fetchEquityTypes();
          await enumsStore.fetchPersonTypes();
          await enumsStore.fetchRequestTypes();
          await enumsStore.fetchRiskProfiles();
          await enumsStore.fetchIdcardTypes();
          await enumsStore.fetchCurrencies();
          await enumsStore.fetchBanks();
          await enumsStore.fetchCountries();
          await enumsStore.fetchAgentStrategies();
        }

        // Set loading state to false after all actions are completed
        isLoading.value = false;
      } catch (error) {
        console.error('Error fetching enums:', error);
        // Handle error as needed
        isLoading.value = false;
      }
    };

    // Fetch client list and other data when the component is mounted
    onBeforeMount(async () => {
      try {

        await dashboardStore.fetchLastTransactions();
        await dashboardStore.fetchTotalAum("year");
        await dashboardStore.fetchTotalAum("month");
        await fetchAndMapHeadlines(365);
        await fetchAllEnums();
        // await accountStore.fetchUserPermissions();

        chartData.value = setChartData();
        chartOptions.value = setChartOptions();

        if (lastDeposits.value && lastDeposits.value.length > 0) {
          clientList.value = lastDeposits.value.map((item) => {
            const strategia = item.investment_strategy.name;
            const transaction_time = formatTransactionTime(item.transaction_date); // Format to SK format
            const value = formatToSkFormat(item.value);
            const currency = item.currency.name;
            const client = item.client;
            const transaction_type = item.transaction_type.long_name;

            return {
              strategy: strategia,
              date: transaction_time,
              client: client.name + " " + client.surname,
              value: value,
              currency: currency,
              transaction_type: transaction_type,
            };
          });
        } else {
          // Handle the case where lastDeposits data is empty or not available
        }
        chartData.value = setChartData();
        chartOptions.value = setChartOptions();
      } catch (error) {
        // Handle errors, e.g., display an error message
        console.error("Error:", error);
      }
    });

    const fetchHeadlinesWithDays = async (days) => {
      try {
        await dashboardStore.fetchHeadliness(days);
      } catch (error) {
        console.error('Error fetching headlines:', error);
      }
    };

    const formatNumber = (num) => {
      // console.log("Formatting number:", num);
      if (isFinite(num)) {
        const parts = num.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(",");
      } else {
        console.log("Number is infinite:", num);
        return "0"; // Or any default value you prefer for infinity
      }
    };

// Add logging statements to other relevant parts of the code as needed


    const formatTransactionTime = (transactionTime) => {
      const date = new Date(transactionTime);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return date.toLocaleString("sk-SK", options);
    };

    const formattedStatus = computed(() => {
      return [
        {
          label: t('Year'),
          value: 'year'
        },
        {
          label: t('month'),
          value: 'month'
        },
      ];
    });



    return {
      clientColumns: [
        {field: "date", header: t("date")},
        {field: "transaction_type", header: t("transaction_type")},
        {field: "strategy", header: t("strategy")},
        {field: "client", header: t("client")},
        {field: "value", header: t("volume"), class: "font-bold !text-right !justify-end"},
        {field: "currency", header: t("currency")},
      ],

      t,
      currentLocale,
      changeLanguage,

      clientList,
      filters,
      selected,
      changeColor,
      filter2,
      selected2,
      changeColor2,
      cardData,
      lastDeposits,
      totalAum,
      headliness,
      chartData,
      chartOptions,
      isLoading,
      hasData,
      totalAumData,
      headFilter,
      formatNumber,
      formatTransactionTime,
      dashboardStore,
      selectedStatus,
      formattedStatus,
    };
  },
}
;
</script>

<style lang="scss">

.display-delete {
  display: none !important;
}

.header-action-custom {
  border-bottom: 0px solid rgba(0, 0, 0, 0.322) !important;
}
</style>

<style lang="scss" scoped>

.accordion-custom .p-accordion-header {
  background-color: #4CAF50; /* Change the background color of the accordion header */
  color: white; /* Change the text color of the accordion header */
}

.accordion-custom .p-accordion-content {
  background-color: #f4f4f4; /* Change the background color of the accordion content */
  color: black; /* Change the text color of the accordion content */
}

.information-row {
  padding: 2% 3%;
  background-color: white;

  .information-row-filter {
    justify-content: space-between;
  }
}

.table-row-tab-mobile .headerIcon {
  display: none;
}

.last-row {
  margin: 0% 32px 2% 32px;

  hr {
    height: 2px;
    background-color: black;
    margin: 10px 0;
  }

  h2 {
    font-size: 26px;
    font-weight: 400;
  }

  .last-row-first-column {
    .last-row-first-column-header-item {
      justify-content: space-between;
      align-items: center;
    }

    width: 100%
  }

  .last-row-second-column {
    width: 100%;

    .last-row-second-column-header-item {
      justify-content: space-between;
      align-items: center;
    }

    .last-row-second-column-data-table-item {
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px;
      border-radius: 5px;

      .last-row-second-column-data-table-item-name {
        width: 30%;
      }

      .last-row-second-column-data-table-item-plusminus {
        padding: 3px 5px;
        border: 1px solid black;
        border-radius: 12px;
        text-align: center;
        display: inline-block;
      }

      .last-row-second-column-data-table-item-percentage {
        width: 30%;
      }
    }

    .last-row-second-column-data-table-item:hover {
      background-color: black;

      .last-row-second-column-data-table-item-name {
        color: white;
      }

      .last-row-second-column-data-table-item-plusminus {
        border: 1px solid white;
        color: white;
      }

      .last-row-second-column-data-table-item-percentage {
        color: white;
      }

      i {
        color: white;
      }
    }
  }
}

.last-row {
  justify-content: space-between;
}

.color-setting-first-tab {
  background-color: #FF5C33;
}

.color-setting-second-tab {
  background-color: #B2C3C0;
}

.color-setting-third-tab {
  background-color: #E5E4E3;
}

.information-row-tab {
  width: 100%;
  border-radius: 12px;
  margin-top: 20px;

  .information-row-tab-first-row {
    border: 1px solid black;
    border-radius: 12px;
    text-align: center;
    margin-top: 20px;
    margin-left: 20px;
    display: inline-block;

    p {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .information-row-tab-second-row {
    margin: 30px 0 10px 20px;

    .information-row-tab-second-row-numberOf-group {
      justify-content: space-between;
      align-items: center;

      i {
        margin-right: 20px;
        margin-top: 20px;
      }
    }

    .information-row-tab-second-row-headerOf {
      font-size: 26px;
    }

    .information-row-tab-second-row-numberOf {
      font-size: 48px;
      font-weight: 500;
    }
  }
}


li {
  cursor: pointer;
  color: grey;
  padding-right: 10px;
}

li:last-child {
  margin-right: 0;
}

li.black {
  font-weight: bold;
  color: black !important;
  text-decoration: underline 2px;
}

@media only screen and (max-width: 1310px) {
  .information-row-tabs {
    margin-top: 20px;
    flex-direction: column !important;
    margin-left: 0 !important;
  }
  .last-row {
    flex-direction: column;
    margin-top: 0;
  }
  .last-row-first-column-header-item {
    flex-direction: column;
  }
}

@media only screen and (max-width: 560px) {
  .information-row {
    padding-left: 15px;
    padding-right: 15px;
  }
  .information-row-filter-left, .last-row-second-column-data-table-item-plusminus {
    display: none !important;
  }
  .last-row {
    h2 {
      font-size: 14px;
    }

    .last-row-second-column-header-item {
      flex-direction: column;
      align-items: start !important;
    }

    .last-row-first-column-header-item i {
      display: none;
    }

    p, .last-row-second-column-data-table-item-percentage {
      font-size: 12px;
    }
  }

}

</style>
