<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <CDialog v-model:visible="refDialogVisible" :header="t('send_referral_link')" :style="{ width: '25rem' }">
      <label for="email" class="font-semibold w-6rem">{{ t("email") }}</label>
      <div class="flex align-items-center gap-3 mb-5">
        <CInputText id="email" class="flex-auto" autocomplete="off" v-model="refEmail"/>
      </div>
      <div class="flex justify-content-end gap-2">
        <CButton class="black-button" type="button" :label="t('send')" @click="sendRef"></CButton>
      </div>
    </CDialog>

    <div class="p-8 mobile-custom-view-grid">
      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex mobile-custom-view-header">
        <span class="p-input-icon-left mr-2 ml-4" v-if="!addClientVisible">
          <i class="pi pi-search"/>
          <CInputText v-model="searchValue" :placeholder="t('search')"/>
        </span>

        <CDropdown
            show-clear
            v-model="selectedRiskProfile"
            :options="formattedRiskProfiles"
            optionLabel="name"
            :placeholder="t('select_risk_profile')"
            class="md:w-14rem mr-2"
            v-if="!addClientVisible"
        />

        <CButton
            :label="t('new_client')"
            icon="pi pi-plus"
            class="ml-auto black-button no-mobile-display"
            style="background-color: #000000;"
            @click="addClient"
            v-if="!addClientVisible"
        />

        <CButton
            :label="t('send_referral_link')"
            class="ml-4 mr-2 black-button no-mobile-display"
            v-if="accountStore.getUserInfo?.reg_number !== null && accountStore.getUserInfo?.reg_number !== ''"
            style="background-color: #000000;"
            @click="openRefLink"
        />

        <p class="ml-auto back-button cursor-pointer" @click="addClientVisible = false" v-if="addClientVisible">
          {{ t("close") }}
        </p>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4 mt-4" v-if="!addClientVisible">
        <div class="flex items-center justify-between py-2 mobile-custom-view">
          <h2 class="ml-4 text-xl">{{ t("active_clients") }}</h2>
          <CSelectButton
              v-model="selectedStatus"
              option-label="label"
              option-value="value"
              @change="selectedStatus = $event.value"
              :options="formattedStatus"
              aria-labelledby="basic"
              :pt="{
              button: ({ context }) => ({
                class: context.active ? 'switcher-flip-selected' : 'switcher-flip'
              })
            }"
          />
          <div class="mr-2 text-gray-400 text-sm" v-if="filteredClientList && filteredClientList.length">
            {{ t("records_number") }}: {{ filteredClientList.length }}
          </div>
        </div>
        <div class="table-row-tab-mobile" v-if="selectedStatus === 'client'">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndex($event)">
              <CAccordionTab
                  :pt="{
                  root: { style: { margin: '0', paddingBottom: '0' } },
                  headerIcon: { class: ['display-delete'] },
                  headerAction: {
                    class: ['header-action-custom'],
                    style: { margin: '0', paddingTop: '0', paddingBottom: '0', borderRadius: '0', border: '0' }
                  },
                  content: { style: { paddingTop: '0', borderRadius: '0', border: '0' } }
                }"
                  v-for="(item, index) in clientListPaginator"
                  :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t("name") }}</span>
                    <p>{{ item?.fullName }}</p>
                  </div>
                  <i :id="'pi-plus-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("email") }}</h3>
                    <p>{{ item?.birthday }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("op_validity") }}</h3>
                    <p>{{ item?.idcard_validity }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("profile") }}</h3>
                    <p>{{ item?.risk_profile?.name }}</p>
                  </div>
                </div>
                <div class="content-row-buttons">
                  <div class="content-row-item">
                    <router-link :to="{ name: 'client', params: { uuid: item?.uuid } }">
                      <button>{{ t("client_detail") }}</button>
                    </router-link>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator
              v-model:first="valueInPaginator"
              :rows="10"
              :totalRecords="filteredClientList?.length"
              template="PrevPageLink CurrentPageReport NextPageLink"
          ></Paginator>
        </div>
        <Table
            v-if="selectedStatus === 'client'"
            :columns="clientColumns"
            :data="filteredClientList"
            :pagination="true"
            rows="15"
            :rowsPerPageOptions="[5, 10, 15, 20, 50]"
            class="m-3 table-row-desktop"
            @row-click="selectClient($event)"
        >
          <CColumn field="profile" :header="t('profile')">
            <template #body="{ data }">
              <CChip
                  :label="data?.risk_profile?.name"
                  :pt="{ root: { class: ['risk-profile-chip'] } }"
                  v-if="data?.risk_profile != null"
              />
            </template>
          </CColumn>
          <CColumn field="icon">
            <template #body="{ data }">
              <router-link :to="{ name: 'client', params: { uuid: data.uuid } }">
                <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon"/>
              </router-link>
            </template>
          </CColumn>
        </Table>

        <div class="table-row-tab-mobile" v-if="selectedStatus === 'lead'">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndex($event)">
              <CAccordionTab
                  :pt="{
            root: { style: { margin: '0', paddingBottom: '0' } },
            headerIcon: { class: ['display-delete'] },
            headerAction: {
              class: ['header-action-custom'],
              style: { margin: '0', paddingTop: '0', paddingBottom: '0', borderRadius: '0', border: '0' }
            },
            content: { style: { paddingTop: '0', borderRadius: '0', border: '0' } }
          }"
                  v-for="(item, index) in leadListPaginator"
                  :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t("name") }}</span>
                    <p>{{ item?.fullName }}</p>
                  </div>
                  <i :id="'pi-plus-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("phone") }}</h3>
                    <p>{{ item?.phone }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("onboarding_step") }}</h3>
                    <p>{{ item?.onboardingStep }}</p>
                  </div>
                </div>
                <div class="content-row-buttons">
                  <div class="content-row-item">
                    <router-link :to="{ name: 'lead', params: { uuid: item?.uuid } }">
                      <button>{{ t("lead_detail") }}</button>
                    </router-link>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator
              v-model:first="valueInPaginator"
              :rows="10"
              :totalRecords="filteredLeadList?.length"
              template="PrevPageLink CurrentPageReport NextPageLink"
          ></Paginator>
        </div>
        <Table
            v-if="selectedStatus === 'lead'"
            :columns="leadColumns"
            :data="filteredLeadList"
            :pagination="true"
            rows="15"
            :rowsPerPageOptions="[5, 10, 15, 20, 50]"
            class="m-3 table-row-desktop"
            @row-click="selectClient($event)"
        >
          <CColumn field="phone" :header="t('phone')">
            <template #body="{ data }">
              <span>{{ data?.phone }}</span>
            </template>
          </CColumn>
          <CColumn field="onboardingStep" :header="t('onboarding_step')">
            <template #body="{ data }">
              <span>{{ data?.onboarding_status }}</span>
            </template>
          </CColumn>
          <CColumn field="profile" :header="t('profile')">
            <template #body="{ data }">
              <CChip
                  :label="data?.risk_profile?.name"
                  :pt="{ root: { class: ['risk-profile-chip'] } }"
                  v-if="data?.risk_profile != null"
              />
            </template>
          </CColumn>
          <CColumn field="icon">
            <template #body="{ data }">
              <router-link :to="{ name: 'client', params: { uuid: data.uuid } }">
                <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon"/>
              </router-link>
            </template>
          </CColumn>
        </Table>
      </div>


      <div class="rounded-lg shadow-md bg-white p-4 py-10 !align-center" v-if="addClientVisible">
        <div class="flex flex-row !justify-center koniec">
          <div class="flex flex-column gap-2 column-item !w-1/6">
            <label for="birthnumber" class="input-label">{{ t("bn_enter") }}</label>
            <CInputText
                id="birthnumber"
                v-model="client.birthnumber"
                aria-describedby="number-error"
                :pt="{ root: { class: ['background-color-input'] } }"
                :class="{ 'p-invalid': hasError }"
            />
          </div>
          <CButton
              :label="t('check_bn')"
              icon="pi pi-check"
              class="ml-2 black-button"
              style="background-color: #000000; height: 3rem;"
              type="submit"
              @click="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {computed, onBeforeMount, onMounted, ref, watch} from 'vue';
import {useClientsStore} from '@/stores/clientsStore';
import Table from "@/components/CustomUIComponents/Table.vue";
import {useMainStore} from "@/stores/store";
import {useEnumsStore} from "@/stores/enumsStore";
import Loader from "@/components/Loader.vue";
import {useRouter} from "vue-router";
import {useField, useForm} from 'vee-validate';
import Paginator from 'primevue/paginator';
import {useAccountStore} from "@/stores/accountStore";
import {useI18n} from "vue-i18n";


// export default {
//   name: 'MyClientsView',
// components: {Loader, Table},
// setup() {


const {t} = useI18n();

const clientsStore = useClientsStore();
const accountStore = useAccountStore();
const mainStore = useMainStore();
const enumsStore = useEnumsStore();
const router = useRouter();
const valueInPaginator = ref(0);

const formattedClientList = ref([]);
const selectedRiskProfile = ref(null);
const selectedStatus = ref('client');
const searchValue = ref(null);
const hasError = ref(false);


const addClientVisible = ref(false);
const client = ref({
  birthnumber: "",
});

const {value, errorMessage, errors} = useField('client.birthnumber', validateField);
const {handleSubmit, resetForm} = useForm();

const previousIndex = ref(null);

function updateIndex(event) {
  const element = document.getElementById("pi-plus-" + event);
  if (element === null) {
    previousIndex.value.classList.add("pi-plus");
    previousIndex.value.classList.remove("pi-minus");
  } else if (previousIndex.value === null) {
    element.classList.add("pi-minus");
    element.classList.remove("pi-plus");
  } else if (element !== previousIndex.value) {
    previousIndex.value.classList.add("pi-plus");
    previousIndex.value.classList.remove("pi-minus");
    element.classList.add("pi-minus");
    element.classList.remove("pi-plus");
  }
  previousIndex.value = element;
}

const leadListPaginator = computed(() => {
  return clientsStore.getFormattedClientList
      ?.filter((client) => client.type === "lead")
      ?.slice(valueInPaginator.value, valueInPaginator.value + 10);
});

const filteredLeadList = computed(() => {
  const allClients = clientsStore.getFormattedClientList || [];
  console.log("All clients data:", allClients);


  if (!searchValue.value) {
    return allClients; // Return all leads if no search term is present
  }

  const searchTerm = searchValue.value.toLowerCase();
  const filteredLeads = allClients.filter((lead) => {
    const leadName = `${lead?.name} ${lead?.surname}`.toLowerCase();
    return leadName.includes(searchTerm);
  });
  console.log("Filtered leads with search:", filteredLeads);
  return filteredLeads;
});


const selectLead = (lead) => {
  router.push({name: "lead", params: {uuid: lead.data.uuid}});
};

const leadColumns = [
  {field: "name", header: t("first_name"), sortable: true},
  {field: "surname", header: t("last_name"), sortable: true},
  {field: "email", header: t("email")},
];


function validateField(value) {
  if (!value) {
    return t("birth_number_required");
  }

  // Add your custom validation logic for birth numbers here
  // Example: Check if it matches a certain pattern

  return true;
}

const clientListPaginator = computed(() => {
  // Calculate the start and end indexes for pagination
  const startIndex = valueInPaginator.value;
  const endIndex = startIndex + 10;

  // Use filteredClientList for pagination instead of fetching from the store
  return filteredClientList.value.slice(startIndex, endIndex);
});


const onSubmit = handleSubmit((values, actions) => {
  if (values) {
    clientsStore.birthNumberCheck(client.value.birthnumber, false)
        .then((response) => {
          if (response.message === "Ok") {
            router.push({name: 'create-client', params: {birthnumber: client.value.birthnumber}});
          } else {
            hasError.value = true;
          }
          resetForm();
        })
        .catch((error) => {
          console.error("Error:", error);
          hasError.value = true;
          // alert("Klient s rodným číslom už existuje!");
        });
  } else {
    actions.setErrors({value: 'Enter a valid year.'});
  }
});


const isLoading = computed(() => mainStore.getIsLoading);

const clientList = computed(() => clientsStore.getFormattedClientList);
const riskProfiles = computed(() => enumsStore.getRiskProfiles);

watch([selectedRiskProfile, selectedStatus], async ([riskProfile]) => {
  await clientsStore.fetchClientList(0, 5000, selectedStatus.value, "", riskProfile?.value)
});


const addClient = () => {
  addClientVisible.value = true;
};

const openRefLink = () => {
  refDialogVisible.value = true;
};

const refDialogVisible = ref(false);
const refEmail = ref("");
const sendRef = () => {
  if (!refEmail.value) {
  } else {
    accountStore.sendReferralMail(refEmail.value, "sk")
    refDialogVisible.value = false;
    refEmail.value = "";
  }
};

const checkBirthnumber = async () => {
};

const formattedRiskProfiles = computed(() => {
  return riskProfiles.value.map(type => ({
    name: type?.name,
    value: type?.id
  }));
});

const clientColumns = [
  {field: "name", header: t("name"), sortable: true},
  {field: "surname", header: t("surname"), sortable: true},
  {field: "email", header: t("e_mail")},
  {field: "birthday", header: t("birthday")},
  {field: "idcard_validity", header: t("idcard_validity"), sortable: true},
]

const selectClient = (client) => {
  router.push({name: 'client', params: {uuid: client.data.uuid}});
};

const formattedStatus = computed(() => {
  return [
    {
      label: t('clients'),
      value: 'client'
    },
    {
      label: 'Leady',
      value: 'lead'
    },
  ];
});


const formatDate = (transactionTime) => {
  // Parse the incoming date string
  const date = new Date(transactionTime);

  // Define options for formatting
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Format the date to SK format
  return date.toLocaleString("sk-SK", options);
};


const filteredClientList = computed(() => {
  if (!searchValue.value) {
    return clientsStore.getFormattedClientList || [];
  }

  const searchTerm = searchValue.value.toLowerCase();
  return (clientsStore.getFormattedClientList || []).filter((client) => {
    const clientName = `${client.name} ${client.surname}`.toLowerCase();
    return clientName.includes(searchTerm);
  });
});


onBeforeMount(() => {
  clientsStore.fetchClientList(0, 5000, "client");
});


</script>

<style scoped lang="scss">
.column-item {
  flex-direction: column;
  width: 100%;
}

.koniec {
  align-items: flex-end;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }

}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1251px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
    }

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
