<template>
    <main class="loader-container">
        <img src="../assets/Loader.gif">
    </main>
</template>

<script>
export default {
    name: "Loader"
};
</script>

<style scoped>
.loader-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: white;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
}
</style>
