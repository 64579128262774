<template>
  <div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <div class="flex">
        <h1 class="root-ctab-panel-h1">{{ t('your_api_key') }}</h1>
        <CButton
            :label="t('reset_key')"
            class="ml-auto black-button text-xs"
            v-if="formattedApiUsername !== ''"
            @click="resetAPiKey"
        />
      </div>
      <hr />
      <div
          class="root-ctab-panel-items space-x-5 mt-7 width-mobile"
          v-if="formattedApiUsername !== ''"
      >
        <div class="flex flex-column gap-2 column-item">
          <label for="title" class="input-label">{{ t('key') }}</label>
          <span class="p-input-icon-left mr-2">
            <i
                class="pi pi-copy cursor-pointer copy-icon"
                @click="copyApiUsername"
            />
            <CInputText
                class="w-full"
                id="title"
                aria-describedby="title-help"
                v-model="formattedApiUsername"
                readonly
                :pt="{ root: { class: ['background-color-input'] } }"
            />
          </span>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="name" class="input-label">{{ t('secret') }}</label>
          <span class="p-input-icon-left mr-2">
            <i
                class="pi pi-copy cursor-pointer copy-icon"
                @click="copyApiKey"
            />
            <CInputText
                id="name"
                class="w-full"
                aria-describedby="name-help"
                v-model="formattedApiSecret"
                readonly
                :pt="{ root: { class: ['background-color-input'] } }"
            />
          </span>
        </div>
      </div>
      <div class="root-ctab-panel-items space-x-5 mt-7" v-else>
        <div class="flex">
          <CButton
              :label="t('generate_api_key')"
              class="black-button text-xs"
              icon="pi pi-sitemap"
              @click="generateApiKey"
          />
        </div>
      </div>
      <transition-group name="p-message" tag="div">
        <CMessage
            v-for="msg of messages"
            :key="msg.id"
            :severity="msg.severity"
        >
          {{ msg.content }}
        </CMessage>
      </transition-group>
    </div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">{{ t('api_documentation') }}</h1>
      <hr />
      <div class="mt-7">
        <p>
          {{ t('api_usage_info') }}
        </p>
        <a
            href="https://documenter.getpostman.com/view/24556427/2sA2rDwgF2"
            target="_blank"
        >
          <CButton
              class="black-button text-xs py-2 px-5 mt-5"
              :label="t('postman')"
              icon="pi pi-file-pdf"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/accountStore";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: 'AccountApi',
  setup() {
    const accountStore = useAccountStore();
    const apikey = computed(() => accountStore.getApiKey);

    const formattedApiUsername = computed(() => {
      if (!apikey.value.username) return '';
      return apikey.value.username.substring(0, 4) + '•'.repeat(apikey.value.username.length - 8) + apikey.value.username.substring(apikey.value.username.length - 4);
    });

    const formattedApiSecret = computed(() => {
      if (!apikey.value.username) return '';
      return apikey.value.password.substring(0, 4) + '•'.repeat(apikey.value.password.length - 4);
    });

    const messages = ref([]);

    const copyApiUsername = () => {
      navigator.clipboard.writeText(apikey.value.username);
      addCopyMessage('Kľúč ');
    }

    const copyApiKey = () => {
      navigator.clipboard.writeText(apikey.value.password);
      addCopyMessage('Secret ');
    }

    //function for reseting the api key
    const resetAPiKey = () => {
      accountStore.resetApiKey();
      apikey.value = accountStore.getApiKey;
      addResetMessage();
    }

    const generateApiKey = () => {
      accountStore.generateApiKey();
      apikey.value = accountStore.getApiKey;
      addGenerateMessage();
    }

    const addCopyMessage = (sprava) => {
      messages.value.push({
        severity: 'warn',
        content: sprava + ' bol skopírovaný do schránky. Pozor, jedná sa o produkčné prístupové údaje!',
        id: messages.value.length + 1
      });
    };

    const addGenerateMessage = () => {
      messages.value.push({
        severity: 'success',
        content: 'API kľúč bol úspešne vygenerovaný! ',
        id: messages.value.length + 1
      });
    };

    const addResetMessage = () => {
      messages.value.push({
        severity: 'warn',
        content: 'API kľúč bol úspešne zresetovaný! Pozor, jedná sa o produkčné prístupové údaje!',
        id: messages.value.length + 1
      });
    };

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      t,
      currentLocale,
      changeLanguage,
      apikey,
      copyApiUsername,
      copyApiKey,
      messages,
      formattedApiUsername,
      formattedApiSecret,
      resetAPiKey,
      generateApiKey
    };
  },
}
</script>

<style>

@media only screen and (max-width: 1372px) {
}

</style>
<style lang="scss" scoped>
.root-ctab-panel-items-mobile {
  display: none;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items-desktop {
    display: none;
  }
  .root-ctab-panel-items-mobile {
    display: block;

    h3 {
      background-color: #F2F1F1;
      color: #949494;
    }

  }
}

.copy-icon:hover {
  color: #FF5C33;
}

@media only screen and (max-width: 800px) {
  .width-mobile {
    flex-direction: column;
  }
  .column-item {
    margin-left: 0 !important;
  }
}

</style>
