<template>
  <CDataTable :value="data" :paginator="pagination" :rows="10" :rows-per-page-options="[5, 10, 20, 50]"
              tableStyle="min-width: 50rem; background-color: black"
              :pt="{bodyRow : {class: ['flip-table-row']}}">
    <CColumn v-for="column in columns"
             :key="column.field"
             :field="column.field"
             :header="column.header"
             :pt="{root: {class: [column.class]}, headerContent: {class: [column.class]}}"
             :sortable="column.sortable">
    </CColumn>
    <slot></slot>
  </CDataTable>
</template>
<script>
export default {
  props: {
    columns: Array,
    data: Array,
    pagination: Boolean,
  },
  name: "Table",
  setup(props, { emit }) {
    const onPageChange = (event) => {
      emit('page-change', event.page);
    };

    const onRowsPerPageChange = (event) => {
      emit('rows-per-page-change', event.value);
    };

    return {
      onPageChange,
      onRowsPerPageChange,
    };
  },
};
</script>

<style lang="scss">
.p-datatable .p-datatable-thead > tr > th {
  background-color: #E5E4E3 !important;
  color: rgba(0,0,0,0.8) !important;
  opacity: 0.5 !important;
  border-width: 0px !important;
}
.p-datatable .p-datatable-thead > tr > th:first-child{
  border-radius: 4px 0px 0px 4px !important;
}
.p-datatable .p-datatable-thead > tr > th:last-child{
  border-radius: 0px 4px 4px 0px !important;
}
.p-datatable-table{
  background-color: transparent !important;
}
</style>
