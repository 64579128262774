import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';
import {formatDate} from "@vueuse/core";

export const useAccountStore = defineStore({
    id: 'account',

    state: () => ({
        apiKey: Object,
        userInfo: Object,
        documents: [],
        settings: Object,
        notifications: [],
        permissions: [],
    }),

    getters: {
        getApiKey: (state) => state.apiKey,
        getUserInfo: (state) => state.userInfo,
        getDocuments: (state) => state.documents,
        getFormattedDocuments: (state) => {
            if (!state.documents || state.documents.length === 0) {
                console.warn("securities is empty or not an array:", state.documents);
                // return [];
            }

            return state.documents.map((item) => {

                const id = item.id;
                const name = item.name;
                const date = formatDate(item.date);
                const type = item.type;


                return {
                    id,
                    name,
                    date,
                    type,
                };
            });
        },
        getSettings: (state) => state.settings,
        getNotifications: (state) => state.notifications,
        getUserPermissions: (state) => state.permissions,
    },

    actions: {
        async fetchApiKey() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/admin/token';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.apiKey = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchUserInfo(loading?: boolean) {
            try {
                const apiStore = useApiStore(); // Get the apiStore instance

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = '/v1/flip/user/info';

                // Make the API request using the same apiStore instance
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.userInfo = response;
                this.loading = true;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchDocuments() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/info/documents';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.documents = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchNotifications(loading?: boolean) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }
                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/notifications/list';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.notifications = response.content;
                this.loading = true;
            } catch (error) {
                throw error;
            }
        },

        async getDocument(file_id?: any, file_name?: string) {
            try {
                const apiUrl = `${import.meta.env.VITE_API_LINK}/v1/flip/info/documents/`+ file_id;
                const filename = file_name + '.pdf';

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async resetApiKey() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'PUT';
                const url = '/v1/flip/admin/token';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.apiKey = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async generateApiKey() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'POST';
                const url = '/v1/flip/admin/token';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.apiKey = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async getQuartalReport(dateFrom: string, dateTo: string, format: string) {
            try {
                const apiUrl = `${import.meta.env.VITE_API_LINK}/v1/flip/regulation/sfa_quarterly_report`;
                const filename = 'FLIP_sfa_quarterly_report' + format;
                const data = {
                    dateFrom,
                    dateTo,
                    format,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async getAnnualReport(dateFrom: string, dateTo: string, format: string) {
            try {
                const apiUrl = `${import.meta.env.VITE_API_LINK}/v1/flip/regulation/sfa_annual_report`;
                const filename = 'FLIP_sfa_annual_report' + format;
                const data = {
                    dateFrom,
                    dateTo,
                    format,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async getCommissionsReport(dateFrom: string, dateTo: string, format: string) {
            try {
                const apiUrl =`${import.meta.env.VITE_API_LINK}/v1/flip/commissions/commissions_report`;
                const filename = 'FLIP_commissions_report' + format;
                const data = {
                    dateFrom,
                    dateTo,
                    format,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async getSummaryClients(format: string) {
            try {
                const apiUrl =`${import.meta.env.VITE_API_LINK}/v1/flip/regulation/clients_cash_assets`;
                const filename = 'FLIP_summary_clients' + format;
                const data = {
                    format,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async getSummaryAssets(format: string) {
            try {
                const apiUrl = `${import.meta.env.VITE_API_LINK}/v1/flip/regulation/summary_assets`;
                const filename = 'FLIP_summary_assets' + format;
                const data = {
                    format,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                console.log("FOrmat", format)
                console.log(import.meta.env.VITE_API_LINK)
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },

        async savePersonalInfo(
            email: string,
            phone: string,
            position: string,
        ) {
            try {
                const apiStore = useApiStore();

                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/user/update',
                        data: {
                            email: email,
                            phone: phone,
                            position: position,
                        },
                    });

                // Make the API request
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },

        async fetchUserSettings() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/user/settings';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.settings = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchUserPermissions() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/user/modules_visibility_settings';

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the api key in the state
                this.permissions = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async changeUserSettings(
            type: string,
            hodnota: boolean,
        ) {
            try {

                const apiStore = useApiStore();

                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/user/settings',
                        data: {
                            type: type,
                            value: hodnota,
                        },
                        showToast: true,
                    });

                // Make the API request
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },

        async changeUserEquitySettings(
            equity_types: any,
            firstTime?: boolean,
        ) {
            try {
                let isFirstTime = true;

                if (firstTime === false) {
                    isFirstTime = false;
                }

                const apiStore = useApiStore();


                const equityTypesString = JSON.stringify(equity_types);

                const response: {} = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/user/equity_types_settings',
                    data: {
                        equity_types: equityTypesString,
                    },
                    showToast: isFirstTime, // Show toast only on the first call
                });

                // Make the API request
                return response;
            } catch (error) {
                throw error;
            }
        },

        async changeUserModulesSettings(
            modules_visible: any,
            firstTime?: boolean,
        ) {
            try {
                let isFirstTime = true;

                if (firstTime === false) {
                    isFirstTime = false;
                }

                const apiStore = useApiStore();


                const modules_visible_string = JSON.stringify(modules_visible);

                const response: {} = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/user/modules_visibility_settings',
                    data: {
                        modules_visible: modules_visible_string,
                    },
                    showToast: isFirstTime, // Show toast only on the first call
                });

                // Make the API request
                return response;
            } catch (error) {
                throw error;
            }
        },

        async getUserEquitySettings() {
            try {
                const apiStore = useApiStore();

                const response: {} = await apiStore.makeApiRequest({
                    method: 'GET',
                    url: '/v1/flip/user/equity_types_settings',
                });

                // Make the API request
                return response;
            } catch (error) {
                throw error;
            }
        },

        async getUserModulesSettings() {
            try {
                const apiStore = useApiStore();

                const response: {} = await apiStore.makeApiRequest({
                    method: 'GET',
                    url: '/v1/flip/user/modules_visibility_settings',
                });

                // Make the API request
                return response;
            } catch (error) {
                throw error;
            }
        },

        async setNotificationAsSeen(notificationId) {
            try {
                const apiStore = useApiStore();

                // Create FormData object
                const formData = new FormData();
                formData.append('notification_id', notificationId);

                // Define the request parameters
                const method = 'PUT';
                const url = '/v1/flip/notifications/set_notification_as_seen';

                // Make the API request with FormData
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },

        async setAllNotificationAsSeen() {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'PUT';
                const url = `/v1/flip/notifications/set_all_agent_notifications_as_seen`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    showToast: true,
                });

                // Handle the response as needed

                return response;
            } catch (error) {
                throw error;
            }
        },

        async sendReferralMail(
            email: string,
            lang: string
        ) {
            try {
                const apiStore = useApiStore();
                const response: {} = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/user/send_referral_email',
                    data: {
                        email: email,
                        lang: lang
                    },
                    showToast: true,
                });
                return response;
            } catch (error) {
                throw error;
            }
        },

    },
});
