<template>
  <div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8 pb-8">
      <div class="flex justify-between items-end align-center">
        <h1 class="root-ctab-panel-h1">{{ t('notifications') }}</h1>
        <div class="flex gap-2 items-center">
          <p
              class="delete-all-pill"
              @click="markAllAsRead"
              v-if="unreadNotificationsCount > 0"
          >
            <font-awesome-icon :icon="['fas', 'check-double']" class="mr-2" />
            {{ t('mark_all_as_read') }}
          </p>
        </div>
      </div>
      <hr />
      <CDataView
          :value="notifications"
          paginator
          :paginatorTemplate="t('paginator_template')"
          :rows="5"
          v-if="unreadNotificationsCount > 0"
      >
        <template #list="spravy">
          <div
              class="flip-news-container gap-5 custom-mobile-view"
              @mouseover="setHoveredRow(spravy.data.id)"
              @mouseout="setHoveredRow(null)"
          >
            <div class="col-12 flip-inside-container">
              <div class="flex gap-1 width-mobile">
                <p class="text-sm text-gray-400 mt-1">
                  {{ formatDate(spravy.data.created_at) }}
                </p>
                <h1 class="text-xl mb-3">{{ spravy.data.subject }}</h1>
              </div>
              <p class="text-md text-gray-400">
                {{ spravy.data.notification_text }}
              </p>
            </div>
            <p
                class="delete-all-pill"
                @click="markAsRead(spravy.data.id)"
                v-show="isHoveredRow(spravy.data.id) && !spravy.data.is_read"
            >
              <font-awesome-icon :icon="['fas', 'check']" class="mr-2" />
              {{ t('mark_as_read') }}
            </p>
          </div>
        </template>
      </CDataView>
      <div class="flex justify-center pt-5" v-if="unreadNotificationsCount == 0">
        <p class="text-md text-gray-400">{{ t('no_new_notifications') }}</p>
      </div>
    </div>
  </div>
</template>


<script setup>
import {useAccountStore} from "@/stores/accountStore";
import {computed, ref} from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const accountStore = useAccountStore();
const notifications = computed(() => accountStore.getNotifications);
const howeredRow = ref(null);

const unreadNotificationsCount = computed(() => {
  return notifications.value.reduce((count, notification) => {
    return count + (notification.is_read ? 0 : 1);
  }, 0);
});

const markAsRead = (notificationId) => {
  accountStore.setNotificationAsSeen(notificationId);
  setTimeout(() => {
    accountStore.fetchNotifications();
    accountStore.fetchUserInfo();
  }, 100);
};

const markAllAsRead = () => {
  accountStore.setAllNotificationAsSeen();
  setTimeout(() => {
    accountStore.fetchNotifications();
    accountStore.fetchUserInfo();
  }, 100);
};

const setHoveredRow = (rowId) => {
  howeredRow.value = rowId;
};

const isHoveredRow = (rowId) => {
  return howeredRow.value === rowId;
};

const formatDate = (transactionTime) => {
  // Parse the incoming date string
  const date = new Date(transactionTime);

  // Define options for formatting
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Format the date to SK format
  return date.toLocaleString("sk-SK", options);
};
</script>

<style>

@media only screen and (max-width: 1372px) {
}

</style>
<style lang="scss" scoped>
.flip-news-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
  border-bottom: 1px solid #E5E5E5;
}
.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}
.delete-all-pill{
  display: flex;
  background-color: #F2F1F1;
  padding: 0.3rem 1rem;
  align-items: center !important;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: bold;
}
.align-center{
  align-items: center !important;
}
hr {
  margin: 10px 0;
}
.flip-inside-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

.read_more {
  color: #FF5C33 !important;
}

.not-read {
  color: #FF5C33 !important;
  font-size: 0.5rem;
  padding-top: 0.7rem;
  padding-right: 0.3rem;
}

@media only screen and (max-width: 600px) {
  .width-mobile{
    flex-direction: column;
  }
}
@media only screen and (max-width: 1000px) {
  .custom-mobile-view{
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
