import {defineStore} from 'pinia';
import axios, {AxiosError} from 'axios';
import { useMainStore } from './store';
import {useAuthStore} from "@/stores/authStore"; // Import the auth store
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
// import { useI18n } from 'vue-i18n'


function showMessage(message: string, lang?: string = 'sk') {
    if(lang === 'bg') {
        return message.message_bg;
    } else if (lang === 'en') {
        return message.message_en;
    } else {
        return message.message;
    }
}
export const useApiStore = defineStore({
    id: 'api',

    state: () => ({
        loading: true,
        apiAnnualReportUrl: `${apiLink()}/v1/flip/regulation/sfa_annual_report`,
    }),

    getters: {
        isAuthenticated: () => {
            const authStore = useAuthStore();
            return !!authStore.authToken;
        },
    },

    actions: {
        async makeApiRequest({
                                 method,
                                 url,
                                 params = {},
                                 data = null,
                                 requireAuth = true,
                                 contentType = 'application/x-www-form-urlencoded',
                                 showToast = false,
                                 useCustomAuth = false, // Added useCustomAuth parameter
                                 customToken = null, // Added customToken parameter
                             }: {
            method: string;
            url: string;
            params?: any;
            data?: any;
            requireAuth?: boolean;
            contentType?: string;
            showToast?: boolean;
            useCustomAuth?: boolean; // Specify type for useCustomAuth parameter
            customToken?: string | null; // Specify type for customToken parameter
        }) {
            const mainStore = useMainStore();
            const UNAUTHORIZED = 401;
            const SERVICE_UNAVAILABLE = 503;

            try {
                const headers: Record<string, string> = {
                    'Content-Type': contentType,
                };

                if (requireAuth && useCustomAuth && customToken) { // Use custom token if specified and useCustomAuth is true
                    headers.Authorization = `Bearer ${customToken}`;
                } else if (requireAuth && !useCustomAuth) { // Fallback to store's token if useCustomAuth is false
                    const authStore = useAuthStore();
                    if (authStore.authToken) {
                        headers.Authorization = `Bearer ${authStore.authToken}`;
                    }
                }

                const requestOptions: {
                    method: string;
                    url: string;
                    headers: Record<string, string>;
                    params?: any;
                    data?: any;
                } = {
                    method,
                    url: apiLink() + url,
                    headers,
                };

                if (method === 'GET') {
                    requestOptions.params = data;
                } else {
                    requestOptions.data = data;
                }

                if (this.loading) {
                    mainStore.incrementLoading(); // Increment the loading counter
                }

                const response = await axios.request(requestOptions);

                if (this.loading) {
                    mainStore.decrementLoading(); // Decrement the loading counter
                }

                this.loading = true;

                if (showToast) {
                    toast.success(showMessage(response.data), {
                        theme: 'colored',
                        autoClose: 1000,
                    });
                }
                return response.data;
            } catch (error) {
                const axiosError = error as AxiosError;
                const { status } = axiosError.response || {};

                if (status === UNAUTHORIZED) {
                    if (requireAuth) {
                        // Handle unauthorized access for authenticated requests
                    } else {
                        // Handle the error for unauthenticated requests
                    }
                }
                if (status === SERVICE_UNAVAILABLE) {
                    // Handle service unavailable error
                }
                const mainStore = useMainStore();
                if (this.loading) {
                    mainStore.decrementLoading(); // Ensure the counter is decremented on error
                }
                this.loading = true;
                if (showToast) {
                    toast.error(showMessage(error.response.data), {
                        theme: 'colored',
                        autoClose: 1000,
                    });
                }
                throw error;
            }
        },

        async downloadFile({
                               url,
                               filename,
                               requireAuth = true,
                               contentType = 'application/octet-stream',
                               data,
                           }: {
            url: string;
            filename: string;
            requireAuth?: boolean;
            contentType?: string;
            data?: any;
        }) {
            const mainStore = this.store;
            const UNAUTHORIZED = 401;
            const SERVICE_UNAVAILABLE = 503;
            const authStore = useAuthStore(); // Access the auth store

            try {
                const headers: Record<string, string> = {
                    'Content-Type': contentType,
                };

                if (requireAuth && authStore.authToken) {
                    headers.Authorization = `Bearer ${authStore.authToken}`;
                }

                const mainStore = useMainStore(); // Access the main store
                if (this.loading) {
                    mainStore.incrementLoading(); // Increment the loading counter
                }

                const response = await axios.get(url, {
                    responseType: 'blob',
                    params: data,
                    headers,
                });

                if (this.loading) {
                    mainStore.decrementLoading(); // Decrement the loading counter
                }

                const blob = new Blob([response.data], {type: contentType});

                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = filename;
                anchor.style.display = 'none';
                document.body.appendChild(anchor);

                anchor.click();

                document.body.removeChild(anchor);
            } catch (error) {
                const axiosError = error as AxiosError;
                const {status} = axiosError.response || {};

                if (status === UNAUTHORIZED) {
                    if (requireAuth) {
                        // Handle unauthorized access for authenticated requests
                    } else {
                        // Handle the error for unauthenticated requests
                    }
                }
                if (status === SERVICE_UNAVAILABLE) {
                    // Handle service unavailable error
                }
                const mainStore = useMainStore();
                if (this.loading) {
                    mainStore.decrementLoading(); // Ensure the counter is decremented on error
                }
                throw error;
            }
        },
    },
});

export const apiLink = () => {
    return import.meta.env.VITE_API_LINK;
};

