<template>
  <div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8" v-if="isAdmin === true || isAdmin === 'true'">
      <h1 class="root-ctab-panel-h1">{{ t('global_platform_settings') }}</h1>
      <hr />
      <div class="settings-row">
        <label for="title" class="input-label for-desktop">
          {{ t('equity_settings_all_agents') }}
        </label>
        <div class="flex flex-column-mobile">
          <label for="title" class="input-label for-mobile">
            {{ t('equity_settings_all_agents') }}
          </label>
          <CMultiSelect
              v-model="selectedEquityTypes"
              display="chip"
              :options="equityTypes"
              optionLabel="name"
              :placeholder="t('select_equity_types')"
              class="w-full md:w-20rem"
              style="position: relative; margin-top: 5px;"
          />
        </div>
      </div>
      <div class="settings-row mt-8">
        <label for="title" class="input-label for-desktop">
          {{ t('module_visibility_all_agents') }}
        </label>
        <label for="title" class="input-label for-mobile">
          {{ t('module_visibility_all_agents') }}
        </label>
        <CMultiSelect
            v-model="selectedModules"
            display="chip"
            :options="availableModules"
            optionLabel="title"
            :placeholder="t('select_modules')"
            class="w-full md:w-20rem"
            style="position: relative; margin-top: 5px;"
        />
      </div>
    </div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">{{ t('notification_settings') }}</h1>
      <hr />
      <div class="flex mt-8 gap-6 flex-col">
        <div class="notifikacia" v-for="notification in notifications" :key="notification.id">
          <div>
            <h2>{{ t(notification.titleKey) }}</h2>
            <p>{{ t(notification.descriptionKey) }}</p>
          </div>
          <div class="gap-12 flex">
            <div class="flex flex-column column-item">
              <label class="input-label">Flip</label>
              <CInputSwitch
                  v-model="notification.flipModel"
                  class="mt-2 mb-4"
                  @input="changeUserSettings(notification.flipKey, $event)"
              />
            </div>
            <div class="flex flex-column column-item">
              <label class="input-label">E-mail</label>
              <CInputSwitch
                  v-model="notification.emailModel"
                  class="mt-2 mb-4"
                  @input="changeUserSettings(notification.emailKey, $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computed, onMounted, onBeforeMount, ref, watch} from "vue";
import {useAccountStore} from "@/stores/accountStore";
import {useEnumsStore} from "@/stores/enumsStore";
import {useI18n} from "vue-i18n";

export default {
  name: 'AccountSettings',
  components: {},
  setup() {
    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };
    const accountStore = useAccountStore();
    const enumsStore = useEnumsStore();
    const accountSettings = computed(() => accountStore.getSettings);
    const equityTypes = computed(() => enumsStore.getEquityTypes);
    const availableModules = [
      { title: t('clients') },
      { title: t('assets') },
      { title: t('transactions') },
      { title: t('securities') },
      { title: t('commissions') },
      { title: t('agents') }
    ];

    const pripisanyVkladEmail = ref(undefined);
    const narodeninyEmail = ref(undefined);
    const platnostDotaznikaEmail = ref(undefined);
    const platnostDokladuEmail = ref(undefined);
    const pripisanyVkladFlip = ref(undefined);
    const narodeninyFlip = ref(undefined);
    const platnostDotaznikaFlip = ref(undefined);
    const platnostDokladuFlip = ref(undefined);
    const aktivovanyLeadFlip = ref(undefined);
    const aktivovanyLeadEmail = ref(undefined);
    const selectedEquityTypes = ref([]);
    const selectedModules = ref([]);

    watch(accountSettings, (newValue, oldValue) => {
      pripisanyVkladEmail.value = accountSettings.value.deposit_notification_email;
      narodeninyEmail.value = accountSettings.value.birthday_notification_email;
      platnostDotaznikaEmail.value = accountSettings.value.questionnaire_notification_email;
      platnostDokladuEmail.value = accountSettings.value.id_card_validity_notification_email;
      pripisanyVkladFlip.value = accountSettings.value.deposit_notification_flip;
      narodeninyFlip.value = accountSettings.value.birthday_notification_flip;
      platnostDotaznikaFlip.value = accountSettings.value.questionnaire_notification_flip;
      platnostDokladuFlip.value = accountSettings.value.id_card_validity_notification_flip;
      aktivovanyLeadFlip.value = accountSettings.value.lead_activated_flip;
      aktivovanyLeadEmail.value = accountSettings.value.lead_activated_email;
    })
    let changeUserEquitySettingsCallCount = 0;
    let changeUserModulesSettingsCallCount = 0;

// Internal method to check if it's the first call
    const isFirstCall = () => {
      if (changeUserEquitySettingsCallCount === 0) {
        changeUserEquitySettingsCallCount++;
        return false;
      } else {
        changeUserEquitySettingsCallCount++;
        return true;
      }
    };

    const isFirstCallModules = () => {
      if (changeUserModulesSettingsCallCount === 0) {
        changeUserModulesSettingsCallCount++;
        return false;
      } else {
        changeUserModulesSettingsCallCount++;
        return true;
      }
    };

    watch(selectedEquityTypes, (newValue, oldValue) => {
      const isFirst = isFirstCall();
      accountStore.changeUserEquitySettings(newValue, isFirst);
    });

    watch(selectedModules, (newValue, oldValue) => {
      const isFirst = isFirstCallModules();
      accountStore.changeUserModulesSettings(newValue, isFirst);
    });


    const changeUserSettings = (typ, hodnota) => {
      accountStore.changeUserSettings(typ, hodnota);
    }


    const isAdmin = computed(() => {
      return localStorage.getItem('isAdmin')
    });

    onBeforeMount(async () => {
      if (localStorage.getItem('isAdmin') === "true") {
        try {
          const response = await accountStore.getUserEquitySettings();
          const response_modules = await accountStore.getUserModulesSettings();
          // Assuming response.data is an array of selected equity types, update selectedEquityTypes
          selectedEquityTypes.value = response;
          selectedModules.value = response_modules;
        } catch (error) {
          console.error("Error fetching user equity settings:", error);
        }
      }
    });

    return {
      t,
      currentLocale,
      changeLanguage,
      pripisanyVkladEmail,
      narodeninyEmail,
      platnostDotaznikaEmail,
      platnostDokladuEmail,
      pripisanyVkladFlip,
      narodeninyFlip,
      platnostDotaznikaFlip,
      platnostDokladuFlip,
      changeUserSettings,
      aktivovanyLeadFlip,
      aktivovanyLeadEmail,
      equityTypes,
      selectedEquityTypes,
      selectedModules,
      isAdmin,
      availableModules
    };
  },
}
</script>

<style lang="scss" scoped>
.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.settings-row {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: left;
}

.notifikacia {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: lightgray 1px solid;

  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    color: black;
  }

  p {
    font-size: 0.85rem;
    color: gray;
  }
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.for-mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .notifikacia {
    flex-direction: column;
  }
  .for-mobile {
    display: block;
  }
  .for-desktop {
    display: none;
  }
  .flex-column-mobile {
    position: relative;
    flex-direction: column;
    gap: 15px;
  }
}
</style>

