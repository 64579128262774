<template>
  <div class="h-full">
    <div class="rounded-lg shadow-md bg-white p-4 m-3">
      <div class="flex items-center justify-between py-2">
        <h1 class="ml-4 root-ctab-panel-h1">{{ t('documents') }}</h1>
      </div>
      <Table
          :columns="documentsColumns"
          :data="documentsList"
          :pagination="true"
          class="m-3 table-row-desktop"
      >
        <CColumn field="icon">
          <template #body="icon">
            <font-awesome-icon
                icon="fa-solid fa-download"
                size="lg"
                class="menu-icon"
                @click="downloadFile(icon.data.id, icon.data.name)"
            />
          </template>
        </CColumn>
      </Table>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
            <CAccordionTab
                :pt="accordionStyle"
                v-for="(item, index) in tradesListPaginator"
                :key="index"
            >
              <template #header>
                <div class="customer-header-row">
                  <span>{{ t('name2') }}</span>
                  <p>{{ item?.name }}</p>
                </div>
                <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>{{ t('document_type') }}</h3>
                  <p>{{ item?.type }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>{{ t('date') }}</h3>
                  <p>{{ item?.date }}</p>
                </div>
              </div>
              <div class="content-row-buttons">
                <div class="content-row-item">
                  <button @click="downloadFile(item?.id, item?.name)">
                    {{ t('download') }}
                  </button>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator
            v-model:first="valueInPaginatorTrades"
            :rows="10"
            :totalRecords="documentsList.length"
            :template="t('paginator_template')"
        ></Paginator>
      </div>
    </div>
  </div>
</template>



<script>

import Table from "@/components/CustomUIComponents/Table.vue";
import {useAccountStore} from "@/stores/accountStore";
import {computed, ref} from "vue";
import Paginator from 'primevue/paginator';
import {useClientsStore} from "@/stores/clientsStore";
import {useI18n} from "vue-i18n";

export default {
  name: 'AccountDocuments',
  components: {Table, Paginator},
  setup() {

    const clientStore = useClientsStore();
    const accountStore = useAccountStore();
    const documentsList = computed(() => accountStore.getDocuments);
    const formatedDocumentsList = computed(() => accountStore.getFormattedDocuments);
    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (documentsList.value.length > 10) {
        return documentsList.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return documentsList.value
      }
    });
    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if(element === null){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if(previousIndexTrades.value === null){
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if(element !== previousIndexTrades.value){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }
    const downloadFile = async (id, nazov) => {
      await accountStore.getDocument(id, nazov);
    }

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      documentsColumns: [
        {field: "type", header: "Typ dokumentu"},
        {field: "name", header: "Názov"},
        {field: "date", header: "Dátum"},
      ],
      t,
      currentLocale,
      changeLanguage,
      documentsList,
      downloadFile,
      clientStore,
      formatedDocumentsList,
      valueInPaginatorTrades,
      previousIndexTrades,
      tradesListPaginator,
      updateIndexTrades
    };
  },
}
</script>

<style lang="scss" scoped>
.hlasenie-class{
  width: 25%;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;

  h3 {
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.root-ctab-panel-items-mobile {
  display: none;
}
.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items-desktop {
    display: none;
  }
  .root-ctab-panel-items-mobile {
    display: block;

    h3 {
      background-color: #F2F1F1;
      color: #949494;
    }

  }
}
.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  p {
    font-weight: 500;
    color: black;
  }
}
.content-row {
  width: 100%;
  justify-content: space-between;
  .content-row-item {
    min-width: 150px;
    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }
    p {
      font-weight: 500;
      color: black;
    }
  }
}
.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}
.content-row-buttons {
  margin-top: 10px;
  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}
.table-row-tab-mobile {
  display: none;
}
@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .mobile-custom-view{
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header{
    flex-direction: column;
    gap: 10px;
    span{
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid{
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
