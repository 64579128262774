<template>
  <div>
    <!-- Button to create a new contract -->
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <CButton
          @click="toogleSidebar = true"
          icon="pi pi-plus"
          class="black-button"
          :label="t('new_contract')">
      </CButton>
    </div>

    <!-- Table displaying created contracts -->
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8" v-if="createdContracts && createdContracts.length > 0">
      <Table
          :columns="securitiesColumns"
          :data="createdContracts"
          :pagination="true"
          class="m-3">
        <!-- Contract Type Column -->
        <CColumn field="contract_type_id">
          <template #body="{ data }">
            {{ getContractName(data.contract_type_id) }}
          </template>
        </CColumn>
        <!-- Action Column (Delete) -->
        <CColumn field="icon">
          <template #body="icon">
            <font-awesome-icon
                icon="fa-solid fa-close"
                size="lg"
                class="menu-icon"
                @click="deleteContract(icon.data)"
            />
          </template>
        </CColumn>
      </Table>
    </div>

    <!-- Sidebar for creating a new contract -->
    <CSidebar
        v-model:visible="toogleSidebar"
        position="right"
        :header="t('new_contract')"
        style="width: 500px; padding: 1rem;"
        :pt="{closeButton: {class: ['close-button']}}"
        @hide="close">
      <template #header>
        <h1 style="font-size: 1.5rem;">{{ t('new_contract') }}</h1>
      </template>
      <div class="root-ctab-panel-items mt-7">
        <!-- Dropdown to select contract type -->
        <div class="flex flex-column gap-2 column-item">
          <label for="selectedPaper" class="input-label">{{ t('contract_type') }}</label>
          <CDropdown
              v-model="selectedContract"
              :options="availableContracts"
              filter
              optionLabel="name"
              :placeholder="t('select_contract_type')"
              class="w-full md:w-14rem mb-2"/>
        </div>

        <!-- Multi-select for investment strategies -->
        <div class="flex flex-column gap-2 settings-row">
          <label for="title" class="input-label">{{ t('investment_strategies') }}</label>
          <CMultiSelect
              v-model="selectedStrategies"
              display="chip"
              :options="strategies"
              optionLabel="name"
              :placeholder="t('select_strategies')"
              class="w-full md:w-20rem"/>
        </div>
      </div>
      <!-- Button to create the contract -->
      <div class="mt-4">
        <CButton
            @click="createContract"
            :label="t('create_contract')"
            class="black-button mt-2">
        </CButton>
      </div>
    </CSidebar>
  </div>
</template>

<script setup>
import Table from "@/components/CustomUIComponents/Table.vue";
import {computed, ref, watch, defineEmits} from "vue";
import {useEnumsStore} from "@/stores/enumsStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();


const enumsStore = useEnumsStore();
const emit = defineEmits([])


const strategies = computed(() => enumsStore.getInvestmentStrategies);
const contracts = [
  {
    "id": 1,
    "name": "Zmluva o riadení portfólia",
    "name_en": "Contract on portfolio management"
  },
  {
    "id": 2,
    "name": "Komisionárska zmluva",
    "name_en": "Consignement contract"
  },
]
const toogleSidebar = ref(false)
const menu = ref();
const createdContracts = ref([]);

const selectedContract = ref(0);
const selectedStrategies = ref([]);

const deleteContract = (contract) => {
  createdContracts.value = createdContracts.value.filter(c => c.contract_type_id !== contract.contract_type_id);
};



const items = ref([
  {
    label: 'Možnosti',
    items: [
      {
        label: 'Upraviť',
        icon: 'pi pi-edit',
        command: () => {
        }
      },
      {
        label: 'Zmazať',
        icon: 'pi pi-delete',
        command: () => {
        }
      }
    ]
  }
]);

const availableContracts = computed(() => {
  // Filter out contracts that already exist in createdContracts
  return contracts.filter(contract => {
    return !createdContracts.value.some(createdContract => createdContract.contract_type_id === contract.id);
  });
});

const getContractName = (contractTypeId) => {
  const contract = contracts.find(c => c.id === contractTypeId);
  return contract ? contract.name : '';
};

const createContract = () => {
  if (!selectedContract.value) {
    return;
  }

  if (selectedStrategies.value.length === 0) {
    const newContract = {
      contract_type_id: selectedContract.value.id,
    };
    createdContracts.value.push(newContract);
  } else {
    const newContract = {
      contract_type_id: selectedContract.value.id,
      investment_strategies: selectedStrategies.value.map(strategy => ({
        investment_strategy_id: strategy.id,
        date_from: new Date().toISOString().split('T')[0], // Current date
      })),
    };
    createdContracts.value.push(newContract);
  }

  emit('createdContractUpdate', createdContracts.value);

  selectedContract.value = null;
  selectedStrategies.value = [];
  toogleSidebar.value = false;
};


const toggle = (data, event) => {
  menu.value.toggle(event);
  // selectedSecurity.value = data;
};


const securitiesColumns = [
  { field: 'contract_type_id', header: 'Typ zmluvy' },
];

</script>

<style lang="scss" scoped>
hr {
  margin: 10px 0;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.settings-row {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: left;
}
</style>
