<template>
  <apexchart
      :type="type"
      v-if="chartData.length > 0"
      :options="chartOptions"
      :series="chartData"
      height="450"
      ref="apexChart"
  />
</template>

<script setup lang="ts">
import {ref, onMounted, watch, toRef, defineProps} from "vue";

const props = defineProps<{
  data: any;
  options: any;
  type: any;
}>();

const chartData = ref([]);
const chartOptions = ref({});
const chartDataRef = toRef(props, 'data');

onMounted(() => {
  // Call these functions once when the component is mounted
  getChartOptions();
  getChartSeries();
});

// Use a watcher to react to changes in props.data
watch(chartDataRef, () => {
  getChartOptions();
  getChartSeries();
});

const getChartOptions = () => {
  const maxChartValue = Math.max(...props.data.datasets[0].data.map(parseFloat));

  chartOptions.value = {
    chart: {
      id: "vuechart-example",
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "solid",
      opacity: [1, 1],
    },
    xaxis: {
      categories: props.data.labels,
      labels: {
        style: {
          colors: ['rgba(0, 0, 0)'],
          fontSize: '14px',
        },
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: 'end',
      }
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 4,
      labels: {
        style: {
          colors: ['rgba(0, 0, 0, 0.4)'],
          fontSize: '14px',
        },
        formatter: function (value) {
          // Format the y-axis labels as currency
          return customFormatter(value);
        },
      },
      max: maxChartValue,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          // Format the y-axis labels as currency
          return formatter(value);
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "rgb(255, 92, 51)",
        opacity: 1
      },
    },
  };
};

const formatter = (value) => {
  // Format numbers in thousands, millions, etc.
  const numberFormat = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
  return numberFormat.format(value);
};

const customFormatter = (value) => {
  // format numbers to thousands, millions -> put th. or m. after the number
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + ' mil. €';
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(0) + ' tis. €';
  }
  return value;
};

const getChartSeries = () => {
  // Extract values from the object, parse them as numbers, and then format them
  const formattedData = props.data.datasets[0].data.map((value) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return {
        value: parsedValue,
        formattedValue: formatter(parsedValue),
      };
    } else {
      return null; // Handle invalid values as needed
    }
  });

  chartData.value = [
    {
      name: "AUM",
      data: formattedData.map((dataPoint) => dataPoint.value),
    },
  ];
};


</script>
