<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <div class="p-8 mobile-view-padding">
      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex justify-between custom-header-assets">
        <div class="flex items-center custom-header-assets-item">
          <span class="p-input-icon-left mx-2 ml-4 custom-header-assets-item-first">
            <i class="pi pi-search"/>
            <CInputText v-model="searchValue" :placeholder="t('search')"/>
          </span>
          <CDropdown
              filter
              show-clear
              v-model="selectedStrategy"
              :options="agentStrategies"
              optionLabel="name"
              :placeholder="t('select_strategy')"
              class="md:w-14rem ml-3 custom-header-assets-item-second"
              :pt="{ panel: { style: 'width:350px;' } }"
          />
        </div>
        <div class="left-bar-panel m-2 text-white custom-header-assets-item-third">
          <h6>{{ t('portfolio_value') }}</h6>
          <div class="left-bar-panel-item">
            <p>{{ sumValueEur }}</p>
          </div>
        </div>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4 mt-4">
        <div class="flex items-center justify-between py-2 mobile-view-header">
          <h2 class="ml-4 text-xl">{{ t('asset_list') }}</h2>
          <div class="mr-2 text-gray-400 text-sm" v-if="filteredAssetsList && filteredAssetsList.length">
            {{ t('records_number') }}: {{ filteredAssetsList.length }}
          </div>
        </div>
        <Table :columns="assetsColumns" :data="filteredAssetsList" :pagination="true" class="table-row-desktop m-3">
        </Table>
        <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexCash($event)">
              <CAccordionTab
                  :pt="{
                  root: { style: { margin: '0', paddingBottom: '0' } },
                  headerIcon: { class: ['display-delete'] },
                  headerAction: {
                    class: ['header-action-custom'],
                    style: { margin: '0', paddingTop: '0', paddingBottom: '0', borderRadius: '0', border: '0' }
                  },
                  content: { style: { paddingTop: '0', borderRadius: '0', border: '0' } }
                }"
                  v-for="(item, index) in cashListPaginator"
                  :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t('client') }}</span>
                    <p>{{ item?.client }}</p>
                  </div>
                  <i :id="'pi-plus-cashCustom-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('strategy') }}</h3>
                    <p>{{ item?.strategy_name }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('ticker') }}</h3>
                    <p>{{ item?.ticker }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('asset') }}</h3>
                    <p>{{ item?.security_name }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('isin') }}</h3>
                    <p>{{ item?.security_isin }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('asset_type') }}</h3>
                    <p>{{ item?.security_type }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('quantity') }}</h3>
                    <p>{{ item?.amount }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('value_in_eur') }}</h3>
                    <p>{{ item?.sum_value }}</p>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator
              v-model:first="valueInPaginatorCash"
              :rows="10"
              :totalRecords="filteredAssetsList.length"
              template="PrevPageLink CurrentPageReport NextPageLink"
          ></Paginator>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Table from "@/components/CustomUIComponents/Table.vue";
import {useAssetsStore} from "@/stores/assetsStore";
import {useMainStore} from "@/stores/store";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useEnumsStore} from "@/stores/enumsStore";
import Paginator from 'primevue/paginator';
import Loader from "@/components/Loader.vue";
import {useI18n} from "vue-i18n";

export default {
  name: "MyAssetsView",
  components: {Loader, Table, Paginator},
  setup() {
    const assetsStore = useAssetsStore();
    const mainStore = useMainStore();
    const enumsStore = useEnumsStore();
    const strategiesTypes = computed(() => enumsStore.getInvestmentStrategies);
    const agentStrategies = computed(() => enumsStore.getAgentStrategies);

    const isLoading = computed(() => mainStore.getIsLoading);
    const formattedAssetsList = computed(() => assetsStore.getFormattedAssetsList);

    const selectedStrategy = ref(null);
    const searchValue = ref(null);
    const valueInPaginatorCash = ref(0);
    const previousIndexCash = ref(null);

    const cashListPaginator = computed(() => {
      if (filteredAssetsList.value.length > 10) {
        return filteredAssetsList.value.slice(valueInPaginatorCash.value, valueInPaginatorCash.value + 10);
      } else {
        return filteredAssetsList.value
      }
    });
    function updateIndexCash(event) {
      const element = document.getElementById("pi-plus-cashCustom-" + event);
      if(element === null){
        previousIndexCash.value.classList.add("pi-plus");
        previousIndexCash.value.classList.remove("pi-minus");
      } else if(previousIndexCash.value === null){
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if(element !== previousIndexCash.value){
        previousIndexCash.value.classList.add("pi-plus");
        previousIndexCash.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexCash.value = element;
    }
    onBeforeMount(() => {
      enumsStore.fetchAgentStrategies();
      assetsStore.fetchAssetsList(0, 100000);
    });

    const filteredAssetsList = computed(() => {
      if (!formattedAssetsList.value) {
        return [];
      }

      let assets = formattedAssetsList.value;

      if (selectedStrategy.value) {
        assets = assets.filter((asset) => {
          return asset.strategy_name === selectedStrategy.value.name;
        });
      }

      if (searchValue.value) {
        const searchTerm = searchValue.value.toLowerCase();
        assets = assets.filter((asset) => {
          const clientName = asset.client.toLowerCase();
          const strategyName = asset.strategy_name.toLowerCase();
          const ticker = asset.ticker.toLowerCase();
          const title = asset.security_name.toLowerCase();
          const isin = asset.security_isin.toLowerCase();
          return (
              clientName.includes(searchTerm) ||
              strategyName.includes(searchTerm) ||
              ticker.includes(searchTerm) ||
              title.includes(searchTerm) ||
              isin.includes(searchTerm)
          );
        });
      }

      return assets;
    });




    watch(selectedStrategy, () => {
    });

    const formattedStrategies = computed(() => {
      return strategiesTypes.value.map((type) => ({
        name: type.name,
        value: type.id
      }));
    });

    const sumValueEur = computed(() => {
      let totalSumValueEur = 0;
      if (filteredAssetsList.value) {
        filteredAssetsList.value.forEach((asset) => {
          const value = parseFloat(asset.sum_value_eur.replace(/,/g, '.').replace(/\s/g, ''));
          if (!isNaN(value)) {
            totalSumValueEur += value;
          }
        });
      }
      return totalSumValueEur.toLocaleString('sk-SK', {style: 'currency', currency: 'EUR'});
    });

    // const sumValueEur = computed(() => {
    //   let totalSumValueEur = 0;
    //   filteredAssetsList.value.forEach((asset) => {
    //     let value = parseFloat(asset.sum_value_eur.replace(/,/g, '.').replace(/\s/g, ''));
    //     value = Math.round(value * 100) / 100; // Round to two decimal places
    //     if (!isNaN(value) && value > 0) { // Check if value is greater than zero
    //       totalSumValueEur += value;
    //     }
    //   });
    //   return totalSumValueEur.toLocaleString('sk-SK', {style: 'currency', currency: 'EUR'});
    // });
    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      assetsColumns: [
        {field: "client", header: t("client"), sortable: true},
        {field: "strategy_name", header: t("strategy")},
        {field: "ticker", header: "Ticker", sortable: true},
        {field: "security_name", header: t("asset"), sortable: true},
        {field: "security_isin", header: t("isin"), sortable: true},
        {field: "security_type", header: t("asset_type")},
        {field: "amount", header: t("number"), class: "!text-right !justify-end", sortable: true},
        {field: "sum_value_eur", header: t("value_in_eur"), class: "font-bold !text-right !justify-end", sortable: true}
      ],

      t,
      currentLocale,
      changeLanguage,

      isLoading,
      formattedAssetsList,
      selectedStrategy,
      formattedStrategies,
      searchValue,
      filteredAssetsList,
      sumValueEur,
      valueInPaginatorCash,
      cashListPaginator,
      updateIndexCash,
      agentStrategies
    };
  }
};
</script>

<style scoped lang="scss">
hr {
  margin: 10px 0;
}

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 5px;
  padding-top: 7px;
  padding-left: 50px;
  padding-right: 10px;
  padding-bottom: 7px;

  h6 {
    font-size: 12px;
  }

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  p {
    font-weight: 500;
    color: black;
  }
}
.content-row {
  width: 100%;
  justify-content: space-between;
  .content-row-item {
    min-width: 150px;
    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }
    p {
      font-weight: 500;
      color: black;
    }
  }
}
.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}
.content-row-buttons {
  margin-top: 10px;
  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}
.table-row-tab-mobile {
  display: none;
}
@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .custom-header-assets{
    flex-direction: column;
    gap: 10px;
  }
  .custom-header-assets-item-first{
    margin-left: 0;
    width: 100%;
  }
  .custom-header-assets-item-second{
    margin-left: 0;
    width: 100%;
  }
  .custom-header-assets-item-first input{
    width: 100%;
  }

  .custom-header-assets-item-third{
    width: 100%;
    margin-left: 0;
    max-width: 100%;
  }
  .custom-header-assets-item{
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

  }
}
@media only screen and (max-width: 800px) {
  .content-row .content-row-item {
    min-width: 120px;
  }
  .mobile-view-padding{
    padding: 10px;
  }
  .mobile-view-header{
    flex-direction: column;
    gap: 10px;
  }
  .mobile-view-header h2{
    margin-left: 0;
  }
}
</style>

