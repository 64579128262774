<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <div class="p-8 mobile-view">
      <div class="rounded-lg shadow-md bg-white p-4 desktop-table width-mobile mb-4 flex">
        <span class="p-input-icon-left mr-2 ml-4 custom-item-mobile custom-item-mobile-search">
          <i class="pi pi-search"/>
          <CInputText v-model="searchValue" :placeholder="t('search')"/>
        </span>

        <CDropdown
            show-clear
            v-model="selectedTransactionType"
            :options="formattedTransactionTypes"
            optionLabel="name"
            :placeholder="t('select_transaction_type')"
            class="md:w-14rem mr-2 custom-item-mobile"
        />
        <CDropdown
            show-clear
            v-model="selectedStrategy"
            :options="agentStrategy"
            optionLabel="name"
            :placeholder="t('select_strategy')"
            class="md:w-14rem custom-item-mobile custom-item-mobile-custom"
        />

        <div style="display: flex; align-items: center;" class="width-mobile mobile-custom-view-calendar-gap">
          <Calendar
              :placeholder="t('from')"
              v-model="dateFrom"
              dateFormat="dd.mm.yy"
              class="mr-2 ml-2 no-margin width-mobile-inputs"
              ref="calendarFromRef"
              showIcon
          />
          <Calendar
              :placeholder="t('to')"
              v-model="dateTo"
              dateFormat="dd.mm.yy"
              class="width-mobile-inputs"
              ref="calendarToRef"
              showIcon
          />
        </div>
        <CButton
            :label="t('add_money')"
            icon="pi pi-plus"
            class="ml-auto mr-3 black-button custom-item-button custom-item-button-for-desktop"
            style="background-color: #000000;"
            @click="addMoney"
        />
        <CButton
            :aria-label="t('add_money')"
            type="button"
            icon="pi pi-plus"
            class="ml-auto mr-3 black-button custom-item-button-for-mobile"
            style="background-color: #000000 !important; color:white !important;"
            @click="addMoney"
        />
      </div>

      <div class="rounded-lg shadow-md bg-white p-4 mt-4 desktop-table">
        <div class="flex items-center justify-between py-2 custom-css">
          <h2 class="ml-4 text-xl mobile-view-delete-margin">{{ t("all_transactions") }}</h2>
          <div class="text-gray-400 text-sm mr-3" v-if="filteredTransactionsList && filteredTransactionsList.length">
            {{ t("records_number") }}: {{ transactionsList?.totalElements }} (Max: 10000)
          </div>
        </div>
        <Table
            :columns="clientColumns"
            :data="filteredTransactionsList"
            :pagination="true"
            @row-click="showTransactionDetails($event.data)"
            class="m-3 table-row-desktop"
        >
          <CColumn field="icon">
            <template #body="icon">
              <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  size="lg"
                  class="menu-icon"
                  @click="showTransactionDetails(icon.data)"
              />
            </template>
          </CColumn>
        </Table>
        <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
              <CAccordionTab
                  :pt="{
                  root: { style: { margin: '0', paddingBottom: '0' } },
                  headerIcon: { class: ['display-delete'] },
                  headerAction: {
                    class: ['header-action-custom'],
                    style: { margin: '0', paddingTop: '0', paddingBottom: '0', borderRadius: '0', border: '0' }
                  },
                  content: { style: { paddingTop: '0', borderRadius: '0', border: '0' } }
                }"
                  v-for="(item, index) in tradesListPaginator"
                  :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t("transaction_type") }}</span>
                    <p>{{ item?.transactionType }}</p>
                  </div>
                  <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("strategy") }}</h3>
                    <p>{{ item?.investmentStrategy }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("date") }}</h3>
                    <p>{{ item?.transactionDate }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("client") }}</h3>
                    <p>{{ item?.clientName }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("amount") }}</h3>
                    <p>{{ item?.value }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t("currency") }}</h3>
                    <p>{{ item?.currencyName }}</p>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator
              v-model:first="valueInPaginatorTrades"
              :rows="10"
              :totalRecords="transactionsList.content.length"
              template="PrevPageLink CurrentPageReport NextPageLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Table from "@/components/CustomUIComponents/Table.vue";
import Loader from "@/components/Loader.vue";
import {useTransactionsStore} from "@/stores/transactionsStore";
import {useMainStore} from "@/stores/store";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Paginator from "primevue/paginator";
import Calendar from "@/components/CustomUIComponents/Calendar/Calendar.vue";
import {useEnumsStore} from "@/stores/enumsStore";
import {useClientsStore} from "@/stores/clientsStore";
import { useI18n } from "vue-i18n";

export default {
  name: "TransactionsView",
  components: {Loader, Table, Accordion, AccordionTab, Paginator, Calendar},

  setup() {

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    const transactionsStore = useTransactionsStore();
    const mainStore = useMainStore();
    const enumsStore = useEnumsStore();
    const clientsStore = useClientsStore();
    const transactionTypes = computed(() => enumsStore.getTransactionsTypes);
    const strategiesTypes = computed(() => enumsStore.getInvestmentStrategies);
    const agentStrategy = computed(() => enumsStore.getAgentStrategies);
    const clientStrategy = computed(() => clientsStore.getClientStrategies);


    const isLoading = computed(() => mainStore.getIsLoading);
    const transactionsList = computed(() => transactionsStore.getTransactionList);
    const clientList = computed(() => clientsStore.getFormattedClientList);
    const formattedTransactionsList = computed(() => transactionsStore.getFormattedTransactionList);
    // const formattedTransactionsList = ref([]);

    const totalRecordsNumber = 100;
    const rowNumber = 10;
    const first = ref(0);
    const spec = ref("1000");
    const selectedClient = ref(undefined);
    const selectedClientStrategy = ref(undefined);
    const selectedCurrency = ref("");

    const visibleRight = ref(false);
    const showAddMoney = ref(false);
    const transactionsInfo = ref(false);
    const searchValue = ref(null);

    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (filteredTransactionsList.value.length > 10) {
        return filteredTransactionsList.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return filteredTransactionsList.value
      }
    });

    const currentPage = Math.floor(valueInPaginatorTrades.value / 10) + 1;
    const pageSize = 10;
    const totalRecords = transactionsList.value.content.length;

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    watch(valueInPaginatorTrades, (newValue, oldValue) => {
      if (previousIndexTrades.value) {
        previousIndexTrades.value.classList.remove("pi-plus");
        previousIndexTrades.value.classList.add("pi-minus");
      }
    })
    const bankInfo = ref({});

    const currencyList = [
      {
        name: "EUR"
      },
      {
        name: "BGN"
      }
    ]

    watch([selectedCurrency, selectedClientStrategy, selectedClient], ([newCurrency, newClientStrategy], [oldCurrency, oldClientStrategy]) => {
      updateBankInfo(newCurrency?.name, newClientStrategy?.specific_symbol);
    });

    watch(selectedClient, (newClient, oldClient) => {
      console.log(newClient)
      clientsStore.fetchClientStrategies(newClient?.uuid, false);
    });

    const filteredTransactionsList = computed(() => {
      if (!searchValue.value) {
        return formattedTransactionsList.value;
      }

      const searchTerm = searchValue.value.toLowerCase();
      return formattedTransactionsList.value.filter((transaction) => {
        const clientName = transaction.clientName.toLowerCase();
        const strategyName = transaction.investmentStrategy.toLowerCase();

        return (
            clientName.includes(searchTerm) ||
            strategyName.includes(searchTerm)
        );
      });
    });

    const updateBankInfo = (currency, specificSymbol) => {
      if (currency === "EUR") {
        bankInfo.value = {
          bankName: "Tatra banka, a.s.",
          iban: "SK61 1100 0000 0029 4504 5506",
          variableSymbol: selectedClient?.value?.birthnumber,
          specificSymbol: specificSymbol,
          bic: "TATRSKBX"
        };
      } else {
        bankInfo.value = {
          bankName: "UniCredit Bulbank",
          iban: "BG73 UNCR 7000 1523 4392 40",
          variableSymbol: selectedClient?.value?.birthnumber,
          specificSymbol: specificSymbol,
          bic: "UNCRBGSF"
        };
      }
    };

    const formattedTransactionTypes = computed(() => {
      return transactionTypes.value.map(type => ({
        name: type.long_name,
        value: type.id
      }));
    });

    const formattedStrategies = computed(() => {
      return strategiesTypes.value.map(type => ({
        name: type.name,
        value: type.id
      }));
    });

    const search = ref('');
    const selectedTransactionType = ref(null);
    const selectedStrategy = ref(null);
    const dateFrom = ref('');
    const dateTo = ref('');

    const selectedTransaction = ref({});


    watch([search, selectedTransactionType, selectedStrategy, dateFrom, dateTo], ([newSearch, newTransactionType, newSelectedStrategy, newDateFrom, newDateTo], [oldSearch, oldTransactionType, oldSelectedStrategy, oldDateFrom, oldDateTo]) => {

      const shouldUseLoader = newTransactionType !== oldTransactionType ? true : false;
      transactionsStore.fetchTransactionsList(0, 10000, newTransactionType?.value, newSelectedStrategy?.id, newDateFrom, newDateTo, shouldUseLoader);

      // console.log(newSelectedStrategy.id)
      // if (newTransactionType) {
      //   transactionsStore.fetchTransactionsList(0, 10000, newTransactionType?.value, newSelectedStrategy?.id, newDateFrom, newDateTo,);
      // } else {
      //   transactionsStore.fetchTransactionsList(0, 10000, newTransactionType?.value, newSelectedStrategy?.id, newDateFrom, newDateTo, false);
      // }

    });


    const formatDate = (transactionTime) => {
      // Parse the incoming date string
      const date = new Date(transactionTime);

      // Define options for formatting
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      // Format the date to SK format
      return date.toLocaleString("sk-SK", options);
    };

    /*FIX -> mne sa zda ze tato funkcia robi blbost ked niekedy neukaze transakcie*/
    const formatToSkFormat = (number) => {
      return number.toLocaleString('sk-SK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const showTransactionDetails = (transaction) => {
      selectedTransaction.value = transaction;
      visibleRight.value = true;
      transactionsInfo.value = true;
    };

    const addMoney = () => {
      visibleRight.value = true;
      showAddMoney.value = true;
    };

    const close = () => {
      showAddMoney.value = false;
      transactionsInfo.value = false;

      selectedClient.value = undefined;
      selectedClientStrategy.value = undefined;
      selectedCurrency.value = undefined;
    }

    onBeforeMount(() => {
      transactionsStore.fetchTransactionsList(0, 10000);
      clientsStore.fetchClientList();
    });

    const sendPaymentData = async () => {
      await clientsStore.sendPaymentDetailsEmail(bankInfo.value.variableSymbol, bankInfo.value.specificSymbol, bankInfo.value.iban, bankInfo.value.bic, selectedClient.value.uuid);
      visibleRight.value = false;
    };

    return {

      t,
      currentLocale,
      changeLanguage,

      clientColumns: [
        {field: "investmentStrategy", header: t("strategy")},
        {field: "transactionType", header: t("transaction_type"), sortable: true},
        {field: "transactionDate", header: t("date"), sortable: true},
        {field: "clientName", header: t("client")},
        {field: "value", header: t("amount"), class: "font-bold !text-right !justify-end", sortable: true},
        {field: "currencyName", header: t("currency"), sortable: true},
        // {field: "contact.phone", header: "Hodnota nakupu"},
      ],
      transactionsList,
      updateIndexTrades,
      valueInPaginatorTrades,
      tradesListPaginator,
      isLoading,
      totalRecordsNumber,
      rowNumber,
      first,
      formattedTransactionsList,
      visibleRight,
      showTransactionDetails,
      selectedTransaction,
      formattedTransactionTypes,
      search,
      selectedTransactionType,
      dateFrom,
      dateTo,
      formattedStrategies,
      selectedStrategy,
      showAddMoney,
      transactionsInfo,
      addMoney,
      close,
      spec,
      selectedCurrency,
      selectedClient,
      strategiesTypes,
      selectedClientStrategy,
      clientList,
      currencyList,
      bankInfo,
      filteredTransactionsList,
      searchValue,
      updateBankInfo,
      formatDate,
      formatToSkFormat,
      sendPaymentData,
      agentStrategy,
      clientStrategy,
    };
  },
}
</script>

<style lang="scss">
.display-delete {
  display: none !important;
}

.header-action-custom {
  border-bottom: 0px solid rgba(0, 0, 0, 0.322) !important;
}
</style>
<style lang="scss" scoped>
.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.item-in-accordion-tab {
  .item-in-accordion-tab-item {
    width: 250px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }

}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

.width-mobile-inputs {
  width: 170px;
}

.custom-item-button-for-mobile {
  display: none;
}

@media only screen and (max-width: 1635px) {
  .custom-item-button-for-mobile {
    display: block;
  }
  .custom-item-button-for-desktop {
    display: none;
  }
}

@media only screen and (max-width: 1372px) {
  .custom-item-button-for-mobile {
    display: none;
  }
  .custom-item-button-for-desktop {
    display: block;
  }
  .width-mobile-inputs {
    width: 100%;
  }
  .mobile-custom-view-calendar-gap {
    gap: 10px;
  }
  .mobile-custom-view-calendar {
    display: none;
  }
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
  .width-mobile {
    flex-direction: column;
  }
  .custom-item-mobile {
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
  .custom-item-mobile-search {
    margin-left: 0;
  }
  .custom-item-mobile-custom {
    margin-bottom: 15px;
  }
  .custom-item-button {
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
  .custom-item-mobile input {
    width: 100%;
  }
  .no-margin {
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  .custom-css {
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
    }
  }
  .column-item {
    max-width: 300px;
  }
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

@media only screen and (max-width: 538px) {
  .content-row {
    flex-direction: column;
  }
  .mobile-view {
    padding: 1rem;
  }
  .mobile-view-delete-margin {
    margin-left: 0
  }
}
</style>
