<template>
  <apexchart
      v-if="data"
      :type="type"
      height="400"
      :options="chartOptions"
      :series="chartData"
      ref="apexChart"
      class="chart"
  />
</template>

<script setup lang="ts">
import {ref, onMounted, watch, toRef, defineProps} from "vue";

const props = defineProps<{
  data: any;
  options: any;
  type: any;
}>();

const chartData = ref([]);
const chartOptions = ref({});
const chartDataRef = toRef(props, 'data');

onMounted(() => {
  // Call these functions once when the component is mounted
  getChartOptions();
  getChartSeries();
});

// Use a watcher to react to changes in props.data
watch(chartDataRef, () => {
  getChartOptions();
  getChartSeries();
});

const getChartOptions = () => {
  chartOptions.value = {
    labels: props.options,
    chart: {
      id: "vuechart-example",
      toolbar: {
        show: false
      },
      width: "100%",
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return new Intl.NumberFormat('sk-SK', {style: 'currency', currency: 'EUR'}).format(val);
        }
      }
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "solid",
      opacity: [1, 1],
    },
    xaxis: {
      categories: "",
      labels: {
        show: true,
      }
    },
    yaxis: {
      tickAmount: 6,
      labels: {
        style: {
          colors: ['rgba(0, 0, 0, 0.4)'],
          fontSize: '14px',
        },
        formatter: function (value) {
          // Format the y-axis labels as currency
          return formatter(value);
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#F37021",
        opacity: 1
      },
    },
  };
};


const formatter = (value) => {
  const numberFormat = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
  });
  return numberFormat.format(value);
};

const getChartSeries = () => {
  const formattedData = props.data.map((value) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return {
        // name: "Value",
        value: parsedValue,
        formattedValue: formatter(parsedValue),
      };
    } else {
      return null; // Handle invalid values as needed
    }
  });


  chartData.value = [
    {
      name: "Provízie",
      data: props.data,
    },
  ];
};


// const getChartSeries = () => {
//   const formattedData = props.data.map((value, index) => {
//     const parsedValue = parseFloat(value);
//     if (!isNaN(parsedValue)) {
//       return {
//         name: `Series`,
//         data: parsedValue,
//         formattedValue: formatter(parsedValue),
//       };
//     } else {
//       return null; // Handle invalid values as needed
//     }
//   });
//
//   chartData.value = [
//     {
//       data: props.data,
//     },
//   ]
// };
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
