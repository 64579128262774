import { createI18n } from 'vue-i18n';

// Import translation files
import en from './locales/en.json';
import sk from './locales/sk.json';
import cz from './locales/cz.json';

// Load saved language from localStorage or fallback to 'en'
const savedLanguage = localStorage.getItem('appLanguage') || 'sk';

const i18n = createI18n({
    legacy: false, // Ensure compatibility with Composition API
    locale: savedLanguage, // Default locale
    fallbackLocale: 'en', // Fallback if translation is missing
    globalInjection: true, // Inject $t globally
    messages: {
        en,
        sk,
        cz,
    },
});

export default i18n;
