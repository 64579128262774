<template>
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8" v-if="!contractDetailOpened">
    <div class="flex items-center justify-between py-2">
      <h1 class="root-ctab-panel-h1">{{ t('client_contracts') }}</h1>
      <CButton @click="toogleSidebar = true" icon="pi pi-plus" class="black-button" :label="t('new_contract')"></CButton>
    </div>
    <div>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7 table-row-desktop">
        <Table
            :columns="contractsColumns"
            :data="contracts"
            :pagination="paginationOn"
            style="width: 100%"
        >
          <CColumn field="icon">
            <template #body="{ data }">
              <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon"
                                 @click="showContractDetail(data)"/>
            </template>
          </CColumn>
        </Table>
      </div>

      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexContracts($event)">
            <CAccordionTab v-for="(item, index) in contractsListPaginator" :key="index" :pt="accordionStyle">
              <template #header>
                <div class="customer-header-row" v-if="item?.contractInfo?.contract_type?.name">
                  <span>{{ t('contract_type') }}</span>
                  <p>{{ item?.contractInfo?.contract_type?.name }}</p>
                </div>
                <i :id="'pi-plus-contracts-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item" v-if="item?.full_name">
                  <h3>{{ t('intermediary') }}</h3>
                  <p>{{ item?.full_name }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item" v-if="item?.contractInfo?.contract_closure_date">
                  <h3>{{ t('contract_closure_date') }}</h3>
                  <p>{{ item?.contractInfo?.contract_closure_date }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item" v-if="item?.contractInfo?.questionnaire_date">
                  <h3>{{ t('questionnaire_date') }}</h3>
                  <p>{{ item?.contractInfo?.questionnaire_date }}</p>
                </div>
              </div>
              <div class="content-row-buttons">
                <div class="content-row-item" v-if="item">
                  <button @click="showContractDetail(item)">{{ t('detail') }}</button>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator
            v-model:first="valueInPaginatorContracts"
            :rows="10"
            :totalRecords="contracts?.length"
            :template="t('paginator_template')"
        ></Paginator>
      </div>
    </div>
  </div>

  <div v-if="contractDetailOpened" class="w-auto p-3">
    <div class="p-7 bg-white rounded-lg shadow-md w-auto">
      <div class="transfer-header flex items-center justify-between">
        <h1 class="root-ctab-panel-h1">{{ t('contract_detail') }}</h1>
        <div class="flex items-center space-x-4 pl-4">
          <p class="back-button cursor-pointer" @click="toogleContractDetail">
            {{ t('close') }}
          </p>
        </div>
      </div>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-3">
        <div class="flex flex-column gap-2 column-item">
          <label for="contract_type" class="input-label">{{ t('contract_type') }}</label>
          <CInputText id="contract_type" :model-value="selectedContract?.contractInfo?.contract_type?.name" disabled/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="contract_closure_date" class="input-label">{{ t('contract_closure_date') }}</label>
          <CCalendar id="contract_closure_date" :model-value="selectedContract?.contractInfo?.contract_closure_date" disabled/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="questionnaire_date" class="input-label">{{ t('questionnaire_date') }}</label>
          <CCalendar id="questionnaire_date" :model-value="selectedContract?.contractInfo?.questionnaire_date" disabled/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="intermediary" class="input-label">{{ t('intermediary') }}</label>
          <CInputText id="intermediary"
                      :model-value="selectedContract?.intermediaryInfo?.name + ' ' + selectedContract?.intermediaryInfo?.surname"
                      disabled/>
        </div>
      </div>
    </div>
  </div>

  <div v-if="contractDetailOpened" class="w-auto p-3">
    <div class="p-7 bg-white rounded-lg shadow-md w-auto">
      <div class="transfer-header flex items-center justify-between">
        <h1 class="root-ctab-panel-h1">{{ t('strategies') }}</h1>
        <CButton @click="editContract = true" icon="pi pi-plus" class="black-button" :label="t('add_strategy')"></CButton>
      </div>

      <div class="pt-4 mr-2">
        <Table
            :columns="strategiesColumns"
            :data="selectedContract?.strategiesInfo"
            :pagination="paginationOn"
        />
      </div>
    </div>
  </div>

  <CSidebar v-model:visible="editContract"
            position="right"
            header=""
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="close">
    <template #header>
      <h1>{{ t('add_strategy') }}</h1>
    </template>
    <div class="pb-2 flex justify-around">
      <CSelectButton
          v-model="selectedStatus"
          v-if="client.status === 'client'"
          :options="formattedStatus"
          :aria-labelledby="t('basic')"
          :pt="{
            button: ({ context }) => ({
                class: context.active ? 'switcher-flip-selected' : 'switcher-flip'
            })
          }"
      />
    </div>
    <div class="flex flex-column gap-2 column-item">
      <label for="selectedStrategy" class="input-label">{{ t('select_strategy') }}</label>
      <CDropdown
          v-model="selectedStrategy"
          :options="strategies"
          filter
          optionLabel="name"
          :placeholder="t('select_strategy')"
          class="w-full md:w-14rem mb-2"
      />
    </div>
    <div>
      <h3 v-if="selectedStatus == 'electronic'">{{ t('electronic_request_notice') }}</h3>
    </div>

    <div class="mt-4">
      <CButton @click="addStrategy" :label="t('create_contract')" class="black-button mt-2"></CButton>
    </div>
  </CSidebar>

  <CSidebar v-model:visible="toogleSidebar"
            position="right"
            header=""
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="close">
    <template #header>
      <h1>{{ t('new_contract') }}</h1>
    </template>
    <div class="flex flex-column gap-2 column-item">
      <label for="selectedContractType" class="input-label">{{ t('contract_type') }}</label>
      <CDropdown
          v-model="selectedNewContract"
          :options="availableContracts"
          filter
          optionLabel="name"
          :placeholder="t('select_contract_type')"
          class="w-full md:w-14rem mb-2"
      />
    </div>
    <div class="flex flex-column gap-2 settings-row">
      <label for="title" class="input-label">{{ t('investment_strategies') }}</label>
      <CMultiSelect
          v-model="selectedStrategies"
          display="chip"
          :options="strategiesFilteredByLevel(client?.risk_profile?.level)"
          optionLabel="name"
          :placeholder="t('select_strategies')"
          class="w-full md:w-20rem"
      />
    </div>

    <div class="flex flex-column gap-2 settings-row" v-if="client.status === 'client'">
      <label for="riskProfile" class="input-label">{{ t('risk_profile') }}</label>
      <CDropdown
          v-model="selectedRiskProfile"
          :options="riskProfiles"
          filter
          optionLabel="name"
          :placeholder="t('select_risk_profile')"
          class="w-full md:w-14rem mb-2"
      />
    </div>

    <div class="mt-4">
      <CButton @click="createContract" :label="t('create_contract')" class="black-button mt-2"></CButton>
    </div>
  </CSidebar>
</template>


<script>
import {useClientsStore} from "@/stores/clientsStore";
import {computed, getCurrentInstance, onBeforeUnmount, ref} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import Paginator from 'primevue/paginator';
import {useEnumsStore} from "@/stores/enumsStore";
import Calendar from "@/components/CustomUIComponents/Calendar/Calendar.vue";
import {useI18n} from "vue-i18n";


export default {
  name: 'ClientContracts',
  components: {Table, Paginator, Calendar},
  setup() {
    const currentInstance = getCurrentInstance();
    const enumsStore = useEnumsStore();
    const clientStore = useClientsStore();
    // const contracts = computed(() => clientStore.getContracts);
    const contracts = computed(() => clientStore.getFormattedContracts);
    const client = computed(() => clientStore.getClient);
    const valueInPaginatorContracts = ref(0);
    const previousIndexContracts = ref(null);
    const amlDate = ref('');
    const questionaireDate = ref('');
    const selectedFileType = ref(undefined);
    const file = ref(undefined);
    const uploadedFiles = ref([]);
    const paginationOn = computed((cislo) => {
      if (cislo > 10) {
        return true;
      } else {
        return false;
      }
    });
    const contractsListPaginator = computed(() => {
      if (contracts.value && contracts.value.length > 10) {
        return contracts.value.slice(valueInPaginatorContracts.value, valueInPaginatorContracts.value + 10);
      } else {
        return contracts.value
      }
    });

    function updateIndexContracts(event) {
      const element = document.getElementById("pi-plus-contracts-" + event);
      if (element === null) {
        previousIndexContracts?.value.classList.add("pi-plus");
        previousIndexContracts?.value.classList.remove("pi-minus");
      } else if (previousIndexContracts.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexContracts.value) {
        previousIndexContracts.value.classList.add("pi-plus");
        previousIndexContracts.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexContracts.value = element;
    }

    const modalOpened = ref(false);
    const selectedContract = ref(null);
    const contractDetailOpened = ref(false);
    const selectedStatus = ref("manual");

    const toogleContractDetail = () => {
      contractDetailOpened.value = !contractDetailOpened.value;
    }

    const showContractDetail = (contract) => {
      contractDetailOpened.value = true;
      selectedContract.value = contract;
      console.log("Selected contract: ", selectedContract.value)
    }

    const toogleSidebar = ref(false)
    const editContract = ref(false)
    const menu = ref();
    const createdContracts = ref([]);

    const selectedNewContract = ref(0);
    const selectedStrategies = ref([]);
    const selectedRiskProfile = ref([]);


    const strategies = computed(() => {
      // Filter strategies based on the selected status
      if (selectedStatus.value === 'electronic') {
        const selectedStrategiesIds = [26, 31, 30, 12, 28, 57, 49, 19, 27, 15, 16, 14, 13, 73, 29, 64, 65, 50, 74, 75, 80];
        return enumsStore.getInvestmentStrategies.filter(strategy => selectedStrategiesIds.includes(strategy.id));
      } else {
        // Return all strategies if status is not "electric"
        return enumsStore.getInvestmentStrategies;
      }
    });


    const strategiesFilteredByLevel = (level) => {
      // Filter strategies based on the specified risk profile level
      return strategies.value.filter(strategy => {
        const strategyLevel = strategy.risk_profile?.level || 0;
        // Filter out strategies that don't match the level criteria
        if (level === 0) {
          return true;
        } else if (level === 4) {
          return strategyLevel <= level;
        } else if (level === 3) {
          return strategyLevel <= level;
        } else if (level === 2) {
          // For "konzervativny", allow only risk profile levels 2 and below
          return strategyLevel <= level;
        } else {
          // For other levels, return false (strategy will be filtered out)
          return false;
        }
      });
    };

    const filteredStrategies = strategiesFilteredByLevel(1); // Get strategies for "dynamicky" investor
    console.log(filteredStrategies);

    const riskProfiles = computed(() => enumsStore.getRiskProfiles);
    const contractss = [
      {
        "id": 1,
        "name": "Zmluva o riadení portfólia",
        "name_en": "Contract on portfolio management"
      },
      {
        "id": 2,
        "name": "Komisionárska zmluva",
        "name_en": "Consignement contract"
      },
    ]

    const formattedStatus = computed(() => {
      return [
        {
          label: t('manually'),
          value: 'manual'
        },
        {
          label: t('electronic'),
          value: 'electronic'
        },
      ];
    });

    const handleFileChange = (event) => {
      // Handle file selection
      file.value = event.target.files;
      uploadedFiles.value.push(...file.value);
      console.log("Uploaded files: ", uploadedFiles.value)
    };

    const deleteDocument = (index) => {
      // Delete a file from the uploaded files list
      uploadedFiles.value.splice(index, 1);
    };

    const uploadDocument = () => {
      if (file.value) {
        // Loop through selected files and add them to the uploaded files list
        for (let i = 0; i < file.value.length; i++) {
          uploadedFiles.value.push({
            name: file.value[i].name,
            fileType: selectedFileType?.value.name,
            size: file.value[i].size,
            type: selectedFileType?.value.id,
            data: file.value[i],
          });
        }
        // Reset selected file and file type
        selectedFileType.value = undefined;
        file.value = undefined;
        // Perform upload logic here if needed
        // clientStore.leadUploadFile(route.params.uuid, selectedFileType?.value.id, file.value);
      }
    };
    const deleteContract = (contract) => {
      createdContracts.value = createdContracts.value.filter(c => c.contract_type_id !== contract.contract_type_id);
    };

    const availableContracts = computed(() => {
      // Filter out contractss that already exist in createdContracts
      return contractss.filter(contract => {
        // console.log(contract.value)
        console.log(contract)
        console.log(contracts.value)
        return !contracts.value.some(createdContract => createdContract.contractInfo?.contract_type?.id === contract.id);
      });
    });

    const getContractName = (contractTypeId) => {
      const contract = contractss.value.find(c => c.id === contractTypeId);
      return contract ? contract.name : '';
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const selectedStrategy = ref(null);
    // const addStrategyToContract = () => {
    //   console.log("Add strategy to contract")
    //   console.log(selectedStrategy.value)
    //   console.log(selectedContract.value)
    //
    //   if (client.value.status === 'lead') {
    //
    //   } else {
    //
    //   }
    //
    //   selectedStrategies.value = [];
    //   selectedNewContract.value = null;
    //   toogleSidebar.value = false;
    // };

    const addStrategy = () => {
      const route = currentInstance.appContext.config.globalProperties.$route;
      console.log("Selected strategy", selectedStrategy.value)
      console.log("Selected contract", selectedContract.value)
      if (client.value.status === 'lead') {
        clientStore.addStrategyToLeadContract(route.params.uuid, selectedContract.value.contractInfo?.contract_type?.id, selectedStrategy.value.id);
      } else {
        clientStore.addStrategyToClientContract(route.params.uuid, selectedContract.value.contractInfo?.contract_type?.id, selectedStrategy.value.id, uploadedFiles.value, selectedStatus.value);
      }
      editContract.value = false;
      selectedStatus.value = 'manual';
      uploadedFiles.value = [];
      selectedStrategy.value = null;
    };


    const createContract = () => {
      console.log(selectedNewContract.value)
      console.log(selectedStrategies.value)
      if (!selectedNewContract.value) {
        return;
      }
      if (client.value.status === 'lead') {
        if (selectedStrategies.value.length === 0) {
          const newContract = {
            contract_type_id: selectedNewContract.value.id,
          };
          createdContracts.value.push(newContract);
        } else {
          const newContract = {
            contract_type_id: selectedNewContract.value.id,
            investment_strategies: selectedStrategies.value.map(strategy => ({
              investment_strategy_id: strategy.id,
              date_from: new Date().toISOString().split('T')[0], // Current date
            })),
          };
          createdContracts.value.push(newContract);
          const route = currentInstance.appContext.config.globalProperties.$route;
          // console.log(route.params.uuid);
          console.log("Investment strategies: ", selectedStrategies.value);
          clientStore.createLeadContract(route.params.uuid, JSON.stringify(createdContracts._rawValue));
          console.log("JSON.stringify(createdContracts): ", JSON.stringify(createdContracts?._rawValue));
        }
      } else {
        const newContract = {
          contract_type_id: selectedNewContract.value.id,
          risk_profile_id: selectedRiskProfile.value.id,
          contract_closure_date: new Date().toISOString().split('T')[0],
          questionnaire_date: formatDate(questionaireDate.value),
          aml_date: formatDate(amlDate.value),
          investment_strategies: selectedStrategies.value.map(strategy => ({
            investment_strategy_id: strategy.id,
            date_from: new Date().toISOString().split('T')[0], // Current date
          })),
        };
        createdContracts.value.push(newContract);
        const route = currentInstance.appContext.config.globalProperties.$route;
        console.log(route.params.uuid);
        console.log("Investment strategies: ", selectedStrategies.value);
        clientStore.createClientContract(route.params.uuid, JSON.stringify(createdContracts._rawValue), uploadedFiles.value);
        console.log("JSON.stringify(createdContracts): ", JSON.stringify(createdContracts?._rawValue));
      }

      // const route = currentInstance.appContext.config.globalProperties.$route;
      // console.log(route.params.uuid);
      // console.log("Investment strategies: ", selectedStrategies.value);
      // clientStore.createLeadContract(route.params.uuid, JSON.stringify(createdContracts._rawValue));
      // console.log("JSON.stringify(createdContracts): ", JSON.stringify(createdContracts?._rawValue));

      // emit('createdContractUpdate', createdContracts.value);

      selectedNewContract.value = null;
      selectedStrategies.value = [];
      toogleSidebar.value = false;
      uploadedFiles.value = [];
    };

    onBeforeUnmount(() => {
      toogleSidebar.value = false;
      selectedStrategies.value = [];
      selectedNewContract.value = null;
      contracts.value = [];
      uploadedFiles.value = [];
    });

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };


    return {
      contractsColumns: [
        {field: "full_name", header: t("agent_2")},
        {field: "contractInfo.contract_type.name", header: t("contract_type")},
        {field: "contractInfo.contract_closure_date", header: t("contract_closure_date")},
        {field: "contractInfo.questionnaire_date", header: t("questionnaire_date")}
      ],
      strategiesColumns: [
        {field: "name", header: t("strategy_name")},
        {field: "date_from", header: t("date")},
      ],
      t,
      currentLocale,
      changeLanguage,
      contracts,
      paginationOn,
      modalOpened,
      showContractDetail,
      selectedContract,
      toogleContractDetail,
      contractDetailOpened,
      updateIndexContracts,
      contractsListPaginator,
      valueInPaginatorContracts,
      toogleSidebar,
      editContract,
      availableContracts,
      selectedNewContract,
      selectedStrategies,
      createContract,
      deleteContract,
      getContractName,
      strategies,
      contractss,
      createdContracts,
      selectedRiskProfile,
      riskProfiles,
      amlDate,
      questionaireDate,
      client,
      addStrategy,
      selectedStrategy,
      handleFileChange,
      deleteDocument,
      uploadDocument,
      uploadedFiles,
      selectedStatus,
      formattedStatus,
      strategiesFilteredByLevel,
      filteredStrategies
    };
  },
}
</script>
<style lang="scss" scoped>

hr {
  margin: 10px 0;
}


.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

</style>

<style scoped>


.file-input-container {
  @apply relative w-full mt-5 h-20 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer overflow-hidden;

  &:hover {
    @apply border-[#FF5C33];
  }
}

input[type="file"] {
  @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
}

.file-input-label {
  @apply text-center text-gray-500;
}


.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.settings-row {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: left;
}


.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.transfer-header {
  background-color: white;
  width: 100%;
  align-content: center !important;

  h1 {
    font-size: 20px;
    font-weight: 400;
  }
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items {
    flex-direction: column;
  }

  .column-item {
    margin-left: 0 !important;
  }

  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }

  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }

  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }

  .mobile-custom-view-grid {
    padding: 0.5rem;
  }

  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
