<template>
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <!-- Header Section -->
    <h1 class="root-ctab-panel-h1">{{ t('agent_clients_header') }}</h1>
    <div class="root-ctab-panel-items mt-7">
      <!-- Desktop Table -->
      <Table
          :columns="clientsColumns"
          :data="clients"
          :pagination="true"
          class="table-row-desktop"
      >
      </Table>

      <!-- Mobile Accordion -->
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
            <CAccordionTab
                :pt="{
                root: {
                  style: {
                    margin: '0',
                    'padding-bottom': '0',
                  },
                },
                headerIcon: {
                  class: ['display-delete'],
                },
                headerAction: {
                  class: ['header-action-custom'],
                  style: {
                    margin: '0',
                    'padding-top': '0',
                    'padding-bottom': '0',
                    'border-radius': '0',
                    border: '0',
                  },
                },
                content: {
                  style: {
                    'padding-top': '0',
                    'border-radius': '0',
                    border: '0',
                  },
                },
              }"
                v-for="(item, index) in tradesListPaginator"
                :key="index"
            >
              <!-- Accordion Header -->
              <template #header>
                <div class="customer-header-row">
                  <span>{{ t('name') }}</span>
                  <p>{{ item?.name + ' ' + item?.surname }}</p>
                </div>
                <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
              </template>

              <!-- Accordion Content -->
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>{{ t('email') }}</h3>
                  <p>{{ item?.email }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>{{ t('birthday') }}</h3>
                  <p>{{ item?.birthday }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>{{ t('idcard_validity') }}</h3>
                  <p>{{ item?.idcard_validity }}</p>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <!-- Paginator for Mobile View -->
        <Paginator
            v-model:first="valueInPaginatorTrades"
            :rows="10"
            :totalRecords="clients.length"
            template="PrevPageLink CurrentPageReport NextPageLink"
        ></Paginator>
      </div>
    </div>
  </div>
</template>



<script>
import {computed} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import {useAgentStore} from "@/stores/agentStore";
import {ref} from "vue";
import Paginator from 'primevue/paginator';
import {useI18n} from "vue-i18n";

export default {
  name: 'AgentClients',
  components: {Table, Paginator},
  setup() {
    const agentStore = useAgentStore();
    const clients = computed(() => agentStore.getFormattedAgentClients);
    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (clients.value.length > 10) {
        return clients.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return clients.value
      }
    });
    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if(element === null){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if(previousIndexTrades.value === null){
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if(element !== previousIndexTrades.value){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      clientsColumns: [
        {field: "name", header: t("name")},
        {field: "surname", header: t("surname")},
        {field: "email", header: t("email")},
        {field: "birthday", header: t("birthday")},
        {field: "idcard_validity", header: t("idcard_validity")},
      ],
      t,
      currentLocale,
      changeLanguage,
      clients,
      updateIndexTrades,
      valueInPaginatorTrades,
      tradesListPaginator,
      previousIndexTrades,
    };
  },
}
</script>
<style lang="scss" scoped>

hr {
  margin: 10px 0;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}
.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  p {
    font-weight: 500;
    color: black;
  }
}
.content-row {
  width: 100%;
  justify-content: space-between;
  .content-row-item {
    min-width: 150px;
    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }
    p {
      font-weight: 500;
      color: black;
    }
  }
}
.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}
.content-row-buttons {
  margin-top: 10px;
  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}
.table-row-tab-mobile {
  display: none;
}
@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .mobile-view{
    padding: 5px;
  }
  .mobile-view-search{
    flex-direction: column;
    gap: 10px;
    input{
      width: 100%;
    }
    button{
      width: 100%;
    }
  }
  .mobile-custom-view{
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header{
    flex-direction: column;
    gap: 10px;
    span{
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid{
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
