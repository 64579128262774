import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useAgentsStore = defineStore({
    id: 'agents',

    state: () => ({
        agentsList: [],
    }),

    getters: {
        getAgentsList: (state) => state.agentsList,
        getFormattedAgentsList: (state) => {
            if (!state.agentsList || state.agentsList.length === 0) {
                console.warn("securitiesList is empty or not an array:", state.agentsList);
                return [];
            }

            return state.agentsList.content.map((item) => {
                const uuid = item.uuid;
                const name = item.name;
                const surname = item.surname;
                const fullName = item.name + ' ' + item.surname;
                const email = item.email;
                const phone = item.phone;
                const reg_number = item.reg_number;
                const position = item.position;

                return {
                    uuid,
                    name,
                    surname,
                    fullName,
                    email,
                    phone,
                    reg_number,
                    position
                };
            });
        },
        getFormattedAgentsList: (state) => {
            if (!state.agentsList || state.agentsList.length === 0) {
                console.warn("securitiesList is empty or not an array:", state.agentsList);
                return [];
            }

            return state.agentsList.content.map((item) => {
                const uuid = item.uuid;
                const name = item.name;
                const surname = item.surname;
                const fullName = item.name + ' ' + item.surname;
                const email = item.email;
                const phone = item.phone;
                const reg_number = item.reg_number;
                const position = item.position;

                return {
                    uuid,
                    name,
                    surname,
                    fullName,
                    email,
                    phone,
                    reg_number,
                    position
                };
            });
        }
    },

    actions: {
        async fetchAgentsList(
            page: number = 0,
            size: number = 1000,
        ) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/agents/list';
                const data = {
                    page: page, // Add the page parameter
                    size: size, // Add the size parameter
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.agentsList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async createAgent(
            name: string,
            surname: string,
            country: object,
            email: string,
            phone: string,
            position: string,
            reg_number: string) {
            try {
                const apiStore = useApiStore();


                // Define the request parameters
                const method = 'POST';
                const url = '/v1/flip/agents/create';

                // Create a raw request body as a JSON string
                const requestBody = JSON.stringify({
                    name,
                    surname,
                    country,
                    email,
                    phone,
                    position,
                    reg_number
                });

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: requestBody,
                    contentType: 'application/json',
                    showToast: true,
                });

                // showToast('This is a success message', 'success');

                console.log(response);
                // return Promise.resolve(response, showToast('This is a success message', 'success'));
                return response;
            } catch (error) {
                // showToast(error?.response?.data?.message, 'error', "Chyba")
                throw error
            }
        },


    },
});
