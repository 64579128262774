<template>
  <div class="h-full">
    <!-- Header with logo -->
    <header class="flex items-start py-4 ml-12">
      <RouterLink to="/">
        <img src="../assets/Logo.svg" alt="Logo" class="w-20 h-20 large-logo"/>
      </RouterLink>
      <RouterLink to="/">
        <img src="../assets/Logo_small.svg" alt="Logo" class="w-20 h-20 small-logo"/>
      </RouterLink>

    </header>

<!--    <div class="flex justify-start items-start  ml-[50px] my-6 space-x-2">-->

<!--      <span-->
<!--          class="cursor-pointer hover:underline"-->
<!--          @click="switchLanguage('sk')"-->
<!--      >-->
<!--    SK-->
<!--  </span>-->
<!--      <span>|</span>-->
<!--      <span-->
<!--          class="cursor-pointer hover:underline"-->
<!--          @click="switchLanguage('cz')"-->
<!--      >-->
<!--    CZ-->
<!--  </span>-->
<!--      <span>|</span>-->
<!--      <span-->
<!--          class="cursor-pointer hover:underline"-->
<!--          @click="switchLanguage('en')"-->
<!--      >-->
<!--    EN-->
<!--  </span>-->
<!--    </div>-->


    <nav class="flex flex-col nav-container py-8 ml-12">
      <!-- Fixed Dashboard Link -->
      <RouterLink to="/" class="router-link">
        <font-awesome-icon
            icon="fa-solid fa-columns"
            size="xs"
            class="menu-icon"
            :class="{ 'router-link-active': $route.path === '/' }"
        />
        <span
            class="menu-text"
            :class="{ 'menu-text-active': $route.path === '/' }"
        >
          {{ $t('dashboard') }}
        </span>
      </RouterLink>

      <!-- Dynamic Navigation Items -->
      <RouterLink
          v-for="item in filteredNavigationItems"
          :key="item.title"
          :to="item.route"
          class="router-link"
      >
        <font-awesome-icon
            :icon="item.icon"
            size="xs"
            class="menu-icon"
            :class="{ 'router-link-active': $route.path.match(item.route) }"
        />
        <span
            class="menu-text"
            :class="{ 'menu-text-active': $route.path.match(item.route) }"
        >
          {{ item.title }}
        </span>
      </RouterLink>
    </nav>

    <!-- Footer with "Logout" -->
    <footer class="mt-auto flex flex-col ml-12 footer">
      <RouterLink to="/login" class="router-link" @click="logout">
        <font-awesome-icon
            icon="fa-solid fa-sign-out-alt"
            size="xs"
            class="menu-icon"
            :class="{ 'router-link-active': $route.path === '/logout' }"
        />
        <span
            class="menu-text"
            :class="{ 'menu-text-active': $route.path === '/logout' }"
        >
          {{ $t('logout') }}
        </span>
      </RouterLink>
    </footer>
  </div>
</template>


<script setup>
import {RouterLink} from 'vue-router';
import {useAuthStore} from "@/stores/authStore";
import {computed, onBeforeMount, ref} from "vue";
import {useAccountStore} from "@/stores/accountStore";
import {useI18n} from "vue-i18n";

const {t, locale} = useI18n();

const accountStore = useAccountStore();
const authStore = useAuthStore();
const navigationItems = [
  {title: t("clients"), route: "/clients", icon: "fa-solid fa-users"},
  {title: t("assets"), route: "/assets", icon: "fa-solid fa-chart-column"},
  {title: t("transactions"), route: "/transactions", icon: "fa-solid fa-hand-holding-usd"},
  {title: t("securities"), route: "/securities", icon: "fa-solid fa-chart-line"},
  {title: t("commissions"), route: "/commissions", icon: "fa-solid fa-file-invoice-dollar"},
  {title: t("agents"), route: "/agents", icon: "fa-solid fa-users"},
];

const filteredNavigationItems = ref([]);

const switchLanguage = (lang) => {
  localStorage.setItem('appLanguage', lang);
  locale.value = lang;
  window.location.reload();
};


onBeforeMount(async () => {
  if (authStore.authToken) {
    await accountStore.fetchUserPermissions();
    const userPermissions = accountStore.getUserPermissions;

    filteredNavigationItems.value = navigationItems.filter(item =>
        userPermissions.some(permission => permission.title === item.title)
    );
  }
});


const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('authTokenExpiresAt');
  localStorage.removeItem('auth');
  localStorage.removeItem('isAdmin');
  localStorage.removeItem('clients');
  authStore.authToken = "";
  authStore.authTokenExpiresAt = "";
  authStore.auth = "";
  authStore.isAdmin = false;
  // $router.push('/login');
};
</script>

<style scoped>
/* Default styling for menu items */
.router-link-exact-active {
  color: white;
}

.router-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1.5rem;
  font-size: 20px;
}

.footer {
  position: absolute;
  bottom: 0;
}

/* Hide menu text on screens with a max-width of 768px */
@media (max-width: 768px) {
  .router-link .menu-text {
    display: none;
  }
}

@media (min-width: 1500px) {
  .small-logo {
    display: none;
  }

  /* Style for menu icons */
  .menu-icon {
    margin-right: 1rem;
    width: 24px;
  }
}

@media only screen and (max-width: 1500px) {
  span {
    display: none;
  }

  .large-logo {
    display: none;
  }

  .nav-container {
    align-items: center;
    margin-bottom: auto;
    position: relative;
  }

  .footer {
    align-items: center;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    margin-left: 12px !important;
  }

  nav, header, footer {
    margin-left: 3px !important;
  }

  button {
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #f3f4f6;
  }

}
</style>
