<template>
  <div class="root-client-view xl:!h-full">
    <CDialog v-model:visible="refDialogVisible" :header="t('send_referral_link')" :style="{ width: '25rem' }">
      <label for="email" class="font-semibold w-6rem">{{ t('email') }}</label>
      <div class="flex align-items-center gap-3 mb-5">
        <CInputText id="email" class="flex-auto" autocomplete="off" v-model="refEmail"/>
      </div>
      <div class="flex justify-content-end gap-2">
        <CButton class="black-button" type="button" :label="t('send')" @click="sendRef"></CButton>
      </div>
    </CDialog>

    <!-- Sidebar (2/12 width) -->
    <div
        class="root-client-view-first w-3/12 bg-white h-full p-4 border-r-gray-200 border-r-2 min-w-3/12 custom-mobile-view">
      <div className="flex flex-col items-center">
        <div className="items-center flex w-[283px] xl:w-full sm:max-v-full flex-col mt-4 mobile-panel">
          <div class="left-bar-header">
            <div class="avatar">
              <div class="avatar-circle">{{ initials }}</div>
            </div>
            <h1>{{ userInfo.name + ' ' + userInfo.surname }}</h1>
            <div class="small-reg-pill gap-1" v-tooltip.bottom="t('registration_tooltip')">
              <p>{{ userInfo.reg_number }}</p>
              <i class="pi pi-question-circle" style="font-size: 0.8rem; color: gray;"/>
            </div>
          </div>
          <div class="left-bar-panel  m-10">
            <h3>{{ t('aum') }}</h3>
            <div class="left-bar-panel-item" v-if="headlines.length">
              <p>{{ formatNumber(headlines[0]?.last_number) + ' €' }}</p>
              <RouterLink
                  to="/assets"
              >
                <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon cursor-pointer"/>
              </RouterLink>
            </div>
          </div>
          <div class="left-bar-table">
            <h2>{{ t('information') }}</h2>
            <hr>
            <div class="left-bar-table-item gap-3">
              <h3>{{ t('position') }}</h3>
              <CInputText v-model="userInfo.position" v-if="editVisible" class="small-input-flip"/>
              <p v-else>{{ userInfo.position }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item gap-3">
              <h3>{{ t('email') }}</h3>
              <CInputText v-model="userInfo.email" v-if="editVisible" class="small-input-flip"/>
              <p v-else>{{ userInfo.email }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item gap-3">
              <h3>{{ t('phone') }}</h3>
              <CInputText v-model="userInfo.phone" v-if="editVisible" class="small-input-flip"/>
              <p v-else>{{ userInfo.phone }}</p>
            </div>
            <hr>
          </div>
          <div class="left-bar-buttons pt-10">
            <CButton :label="t('send_referral_link')" class="ml-auto black-button no-mobile-display mb-4"
                     style="background-color: #000000;" @click="openRefLink"
                     v-if="accountStore.getUserInfo?.reg_number !== null && accountStore.getUserInfo?.reg_number !== ''  && !editVisible"
            />
            <button class="border-button" @click="editVisible=true" v-if="!editVisible">{{ t('update_info') }}</button>
            <button class="black-button" v-if="editVisible" @click="savePersonalInfo">{{ t('save_info') }}</button>
            <button class="border-button mt-3" @click="editVisible=false" v-if="editVisible">{{ t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Content area (10/12 width) -->
    <div class="root-client-view-second w-9/12 h-full ">
      <CTabView :pt="{
                panelContainer:{
                   style: 'background-color: #E5E4E3;',
                    class:[
                        'padding-for-mobile'
                    ]},
                    root:{
                      class:[
                          'width-for-mobile'
                      ]
                    },
                    nav:{
                      class:[
                          'custom-width'
                      ]
                    }}">
        <CTabPanel :header="t('notifications')" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <AccountNotifications/>
        </CTabPanel>
        <CTabPanel :header="t('documents')" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <AccountDocuments/>
        </CTabPanel>
        <CTabPanel :header="t('reporting')" :pt="{headerAction:{class:['padding-for-mobile']}}" v-if="authStore.isAdmin === 'true' || authStore.isAdmin === true">
          <AccountReporting/>
        </CTabPanel>
        <CTabPanel :header="t('api')" :pt="{headerAction:{class:['padding-for-mobile']}}" v-if="authStore.isAdmin === 'true' || authStore.isAdmin === true">
          <AccountApi/>
        </CTabPanel>
        <CTabPanel :header="t('settings')" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <AccountSettings/>
        </CTabPanel>
      </CTabView>
    </div>
  </div>
</template>


<script>
import {computed, onBeforeMount, ref} from 'vue';
import AccountMessages from "@/components/MyAccount/AccountMessages.vue";
import AccountApi from "@/components/MyAccount/AccountApi.vue";
import {useAccountStore} from "@/stores/accountStore";
import {useDashboardStore} from "@/stores/dashboardStore";
import {useAuthStore} from "@/stores/authStore";
import AccountReporting from "@/components/MyAccount/AccountReporting.vue";
import AccountDocuments from "@/components/MyAccount/AccountDocuments.vue";
import AccountNotifications from "@/components/MyAccount/AccountNotifications.vue";
import AccountSettings from "@/components/MyAccount/AccountSettings.vue";
import {useI18n} from "vue-i18n";

export default {
  name: "MyAccountView",
  components: {
    AccountSettings,
    AccountNotifications,
    AccountDocuments,
    AccountReporting,
    AccountApi,
    AccountMessages
  },
  setup() {
    const accountStore = useAccountStore();
    const dashboardStore = useDashboardStore();
    const authStore = useAuthStore();
    const editVisible = ref(false);

    const isAdmin = computed(() => {
      return localStorage.getItem('isAdmin')
    });

    onBeforeMount(() => {
      accountStore.fetchUserInfo();
      accountStore.fetchDocuments();
      accountStore.fetchUserSettings();
      accountStore.fetchNotifications();
      dashboardStore.fetchHeadliness(0);

      if (localStorage.getItem('isAdmin') === 'true') {
        isAdmin.value = true;
      } else {
        isAdmin.value = false;
      }

      if (isAdmin.value === true || isAdmin.value === 'true') {
        accountStore.fetchApiKey();
      }
    });

    const openRefLink = () => {
      refDialogVisible.value = true;
    };

    const refDialogVisible = ref(false);
    const refEmail = ref("");
    const sendRef = () => {
      if (!refEmail.value) {
      } else {
        accountStore.sendReferralMail(refEmail.value, "sk")
        refDialogVisible.value = false;
        refEmail.value = "";
      }
    };

    const apiKey = computed(() => accountStore.getApiKey);
    const userInfo = computed(() => accountStore.getUserInfo);
    const headlines = computed(() => dashboardStore.getHeadliness);

    const savePersonalInfo = () => {
      accountStore.savePersonalInfo(userInfo.value.email, userInfo.value.phone,
          userInfo.value.position).then((response) => {
        //if response is ok
        if (response.status === 200) {
          accountStore.fetchUserInfo();
        }
      });
      editVisible.value = false;
    };

    const formatNumber = (num) => {
      const parts = num.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    };

    const initials = computed(() => {
      const fullName = userInfo.value.name + ' ' + userInfo.value.surname;
      if (fullName) {
        const names = fullName.split(' ');
        return names.map(name => name.charAt(0)).join('').toUpperCase();
      } else {
        return '';
      }
    });

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      t,
      currentLocale,
      changeLanguage,
      apiKey,
      initials,
      userInfo,
      headlines,
      formatNumber,
      savePersonalInfo,
      editVisible,
      isAdmin,
      openRefLink,
      refDialogVisible,
      refEmail,
      sendRef,
      accountStore,
      authStore
    };
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #808080 ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circle {
  font-size: 20px;
  color: #fff;
}

.small-input-flip {
  font-size: 0.8rem !important;
  padding: 0.2rem 0.5rem !important;
}

.small-reg-pill {
  font-size: 0.8rem !important;
  padding: 0.20rem 0.6em !important;
  border-radius: 50px !important;
  background-color: #F2F1F1 !important;
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.avatar-image {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.background-color-input {
  background-color: #F2F1F1 !important;
  color: black;
}

.tab-panel-custom {
  background-color: red !important;
}


.custom-gray-background {
  background-color: black;
}


.left-bar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    padding: 10px 0;
    border-radius: 10px;
    min-width: 85px;
    font-size: 14px;
    width: 100%;
  }

  .delete-button {
    margin-top: 300px;
    border: 1px solid #949494;
  }
}

.left-bar-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    font-weight: bold;
  }

  .left-bar-table-item {
    display: flex;
    justify-content: space-between !important;
    font-size: 14px;
    align-items: center !important;

    h3 {
      color: #949494;
    }

    p {
      font-weight: bold;
      text-align: right;
    }
  }
}

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.left-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    height: 115px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    color: #949494;
  }
}

hr {
  margin: 10px 0;
}

.root-client-view {
  display: flex;
}

@media only screen and (max-width: 1372px) {
  .custom-mobile-view {
    height: 700px;
  }
  .root-client-view {
    display: block !important;
    height: 550px !important;
  }
  .root-client-view-first {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .root-client-view-second {
    background-color: rgb(229, 228, 227);
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .width-for-mobile {
    width: 100% !important;
  }

  .mobile-panel {
    width: 100vw !important;
  }
}

.delete-button {
  margin-top: 10px !important;
}

.left-bar-panel, .left-bar-table, .left-bar-buttons {
  width: 90% !important;
}
</style>

<style>
@media only screen and (max-width: 600px) {
  .custom-width {
    width: 550px;
  }
}
</style>
