// Import necessary modules
import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useTransactionsStore = defineStore({
    id: 'transactions',

    state: () => ({
        transactionsList: {content: [] as any[]},
    }),

    getters: {
        getTransactionList: (state) => state.transactionsList,
        getFormattedTransactionList: (state) => {
            // Check if transactionsList is empty
            if (!state.transactionsList.content || state.transactionsList.content.length === 0) {
                console.warn("transactionsList is empty or not an array:", state.transactionsList.content);
                return [];
            }

            // Import any necessary modules
            const formatToSkFormat = (number: number) => {
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            const formatDate = (transactionTime: string) => {
                const date = new Date(transactionTime);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            // Map over transactionsList directly
            return state.transactionsList.content.map((item) => {
                const id = item.id;
                const transactionType = item.transaction_type.long_name;
                const transactionTypeId = item.transaction_type.id;
                const investmentStrategy = item.investment_strategy.name;
                const specificSymbol = item.investment_strategy.specific_symbol;
                const clientUuid = item.client.uuid;
                const clientName = item.client.name + " " + item.client.surname;
                const clientSurname = item.client.surname;
                const value = formatToSkFormat(item.value); // Make sure formatToSkFormat is available
                const transactionDate = formatDate(item.transaction_date); // Make sure formatDate is available
                const note = item.note;
                const currencyName = item.currency.name;

                // Returning the formatted object
                const additionalFields = {};
                if (item.amount) {
                    additionalFields.amount = formatToSkFormat(item.amount);
                }
                if (item.price) {
                    additionalFields.price = formatToSkFormat(item.price)
                }
                if (item.isin) {
                    additionalFields.isin = item.isin;
                }
                if (item.equity_title) {
                    additionalFields.equityTitle = item.equity_title;
                }

                // Merge additional fields with the main fields
                const formattedTransaction = {
                    id,
                    transactionType,
                    transactionTypeId,
                    investmentStrategy,
                    specificSymbol,
                    clientUuid,
                    clientName,
                    clientSurname,
                    value,
                    transactionDate,
                    note,
                    currencyName,
                    ...additionalFields,
                };

                return formattedTransaction;
            });
        },
    },

    actions: {
        async fetchTransactionsList(
            page: number,
            size: number,
            transaction_type_id?: number,
            strategy_id?: number,
            date_from?: string,
            date_to?: string,
            loading?: boolean) {
            try {

                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/transactions/list';
                const data = {
                    page,
                    size,
                    transaction_type_id,
                    strategy_id,
                    date_from,
                    date_to,
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.transactionsList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },
    },
});
