import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useCommissionsStore = defineStore({
    id: 'commissions',

    state: () => ({
        commissionsList: [],
        strategyCommissionList: [],
        feeTypeCommissionList: [],
        commissionsTimelineLabels: [],
        commissionsTimelineData: [],
    }),

    getters: {
        getCommissionsList: (state) => state.commissionsList,
        getFormattedCommissionsList: (state) => {
            if (!state.commissionsList || state.commissionsList.length === 0) {
                console.warn("commissionsList is empty or not an array:", state.commissionsList);
                return [];
            }

            const formatToSkFormat = (number: number | null | undefined) => {
                if (number == null) {
                    return ''; // or any other default value you prefer
                }
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            const formatDate = (transactionTime: string) => {
                const date = new Date(transactionTime);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            // console.log("commissionsList", state.commissionsList.content);
            return state.commissionsList.content.map((item) => {
                const strategy_name = item.investment_strategy.name;
                const client = item.client.name + ' ' + item.client.surname;
                const client_birthnumber = item.client.birthnumber;
                const fee_type = item.fee_type.name;
                const transaction_type = item.transaction_type.name;
                const date = formatDate(item.provision_booked_date);
                const agent = item.agent.name + ' ' + item.agent.surname;
                const provision_sfa = formatToSkFormat(item.provision_sfa);
                const provision_pfa = formatToSkFormat(item.provision_pfa == null ? 0 : item.provision_pfa);

                // console.log("strategy_name", strategy_name);
                // console.log("client", client);
                // console.log("client_birthnumber", client_birthnumber);
                // console.log("fee_type", fee_type);
                // console.log("transaction_type", transaction_type);
                // console.log("date", date);
                // console.log("agent", agent);
                // console.log("provision_sfa", provision_sfa);
                // console.log("provision_pfa", provision_pfa);

                return {
                    strategy_name,
                    client,
                    client_birthnumber,
                    fee_type,
                    transaction_type,
                    date,
                    agent,
                    provision_sfa,
                    provision_pfa,
                };
            });
        },
        getStrategyCommissionList: (state) => {
            const names = state.strategyCommissionList.map((item) => item.name);
            const values = state.strategyCommissionList.map((item) => item.value);
            return {names, values};
        },
        getFeeTypeCommissionListNames: (state) => {
            return state.feeTypeCommissionList.map((item) => item.name);
        },
        getFeeTypeCommissionListValues: (state) => {
            return state.feeTypeCommissionList.map((item) => item.value);
        },
        getFeeTypeCommissionList: (state) => {
            const formatter = (value) => {
                const numberFormat = new Intl.NumberFormat('de-DE', {
                    maximumFractionDigits: 2,
                });
                return numberFormat.format(value);
            };

            const formattedData = state.feeTypeCommissionList.map((item) => {
                const parsedValue = parseFloat(item.value);
                return {
                    name: item.name,
                    value: parsedValue,
                    formattedValue: formatter(parsedValue),
                };
            });
            return formattedData;
        },
        getCommissionsTimelineLabels: (state) => {
            return state.commissionsTimelineLabels;
        },
        getCommissionsTimelineData: (state) => {
            return state.commissionsTimelineData;
        },
    },

    actions: {
        async fetchCommissionsList(
            page: number = 0,
            size: number = 100000,
            date_from: string = '',
            date_to: string = '',
        ) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/commissions/list';
                const data = {
                    date_from: date_from,
                    date_to: date_to,
                    page: page, // Add the page parameter
                    size: size, // Add the size parameter
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.commissionsList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchCommissionsGroupedByStrategy(
            date_from: string,
            date_to: string,
        ) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/commissions/commissions_grouped';
                const data = {
                    date_from: date_from,
                    date_to: date_to,
                    group_type: "strategy"
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.strategyCommissionList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchCommissionsGroupedByFeeType(
            date_from: string,
            date_to: string,
        ) {
            try {
                const apiStore = useApiStore();

                const method = 'GET';
                const url = '/v1/flip/commissions/commissions_grouped';
                const data = {
                    date_from: date_from,
                    date_to: date_to,
                    group_type: "fee_type",
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.feeTypeCommissionList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchCommissionsTimeline(
            date_from: string,
            date_to: string,
        ) {
            try {
                const apiStore = useApiStore();

                const method = 'GET';
                const url = '/v1/flip/commissions/commissions_timeline';
                const data = {
                    date_from: date_from,
                    date_to: date_to,
                    group_type: "fee_type",
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.commissionsTimelineLabels = response.map((item: Item) => item.name);
                this.commissionsTimelineData = response.map((item: Item) => item.value);
                // console.log("commissionsTimelineList", this.commissionsTimelineList);

                return response;
            } catch (error) {
                throw error;
            }
        },
    },
});
