import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useAssetsStore = defineStore({
    id: 'assets',

    state: () => ({
        assetsList: Object,
    }),

    getters: {
        getAssetsList: (state) => state.assetsList,
        getFormattedAssetsList: (state) => {
            if(!state.assetsList || state.assetsList.length === 0) {
                console.warn("assetsList is empty or not an array:", state.assetsList);
                return [];
            }

            const formatToSkFormat = (number: number) => {
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            return state.assetsList?.content?.map((item) => {
                const strategy_name = item.strategy.name;
                const sum_value = item.sum_value;
                const sum_value_eur = formatToSkFormat(item.sum_value_eur);
                const client = item.client.name + ' ' + item.client.surname;
                const client_birthnumber = item.client.birthnumber;
                const ticker = item.security == undefined ? 'CASH' : item.security.ticker;
                const security_name = item.security == undefined ? 'Hotovosť' : item.security.name;
                const security_isin = item.security == undefined ? '' : item.security.isin;
                const security_type = item.security == undefined ? 'Hotovosť' : item.security.equity_type.name;
                const amount = formatToSkFormat(item.amount == undefined ? 0 : item.amount);

                return {
                    strategy_name,
                    sum_value,
                    sum_value_eur,
                    client,
                    client_birthnumber,
                    security_name,
                    security_isin,
                    security_type,
                    amount,
                    ticker,
                };
            });
        },
    },

    actions: {
        async fetchAssetsList(
            page: number = 0,
            size: number = 10000,
        ) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/assets/list';
                const data = {
                    page: page, // Add the page parameter
                    size: size, // Add the size parameter
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.assetsList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },
    },
});
