import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useSecuritiesStore = defineStore({
    id: 'securities',

    state: () => ({
        securitiesList: Object,
        validFromDateArray: [],
        pricesArray: [],
        pricesEurArray: [],
        cumulativePerformancesArray: [],
        cumulativePerformancesEurArray: [],
    }),

    getters: {
        getSecuritiesList: (state) => state.securitiesList,
        getFormattedSecuritiesList: (state) => {
            if (!state.securitiesList || state.securitiesList.length === 0) {
                console.warn("securitiesList is empty or not an array:", state.securitiesList);
                return [];
            }

            const formatNumber = (num) => {
                const parts = num.toFixed(2).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(",");
            };

            const formatToSkFormat = (number: number) => {
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            const formatDate = (transactionTime) => {
                // Parse the incoming date string
                const date = new Date(transactionTime);

                // Define options for formatting
                const options = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };

                // Format the date to SK format
                return date.toLocaleString("sk-SK", options);
            };

            return state.securitiesList?.content?.map((item) => {
                const name = item.name;
                const isin = item.isin;
                const ticker = item.ticker;
                const currency = item.currency.name;
                const type = item.equity_type?.name;
                const last_price = formatToSkFormat(item.last_price);
                const percentage_diff = item.percentage_diff;
                const percentage_diff_formatted = formatNumber(item.percentage_diff) + " %";
                const id = item.id;

                return {
                    name,
                    isin,
                    ticker,
                    currency,
                    type,
                    last_price,
                    percentage_diff,
                    percentage_diff_formatted,
                    id,
                };
            });
        },
        getValidFromDateArray: (state) => state.validFromDateArray,
        getPricesArray: (state) => state.pricesArray,
        getPricesEurArray: (state) => state.pricesEurArray,
        getCumulativePerformancesArray: (state) => state.cumulativePerformancesArray,
        getCumulativePerformancesEurArray: (state) => state.cumulativePerformancesEurArray,
    },

    actions: {
        async fetchSecuritiesList(
            page: number = 0,
            size: number = 100000,
            days_diff: number = 4
        ) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/securities/list';
                const data = {
                    days_diff: days_diff, // Add the days_diff parameter
                    page: page, // Add the page parameter
                    size: size, // Add the size parameter
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of transactions in the state
                this.securitiesList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchHistoricalPrices(
            security_id: number,
            from: string,
            to: string,
            size: number = 3000,
            loading?: boolean
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }


                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/securities/historical_prices/${security_id}`;
                const data = {
                    date_from: from,
                    date_to: to,
                    size: size,
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Extract and store the values in separate arrays
                const validFrom = response.content.map(item => item.valid_from);
                const prices = response.content.map(item => item.price);
                const pricesEur = response.content.map(item => item.price_eur);
                const cumulativePerformances = response.content.map(item => item.cumulative_performance);
                const cumulativePerformancesEur = response.content.map(item => item.cumulative_performance_eur);

                // Store the arrays in the state
                this.validFromDateArray = validFrom;
                this.pricesArray = prices;
                this.pricesEurArray = pricesEur;
                this.cumulativePerformancesArray = cumulativePerformances;
                this.cumulativePerformancesEurArray = cumulativePerformancesEur;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async newSecurity(
            isin: string,
            ticker: string,
            title: string,
            currency: string,
            note: string,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }
                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/securities/new_security_request',
                        data: {
                            isin: isin,
                            ticker: ticker,
                            title: title,
                            currency: currency,
                            reason: note,
                        },
                        showToast: true,
                    });
                return response;
            } catch (error) {
                throw error;
            }
        },

    }
});
