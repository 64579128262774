import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';

export const useRequestStore = defineStore({
    id: 'request',

    state: () => ({
        buySellResponse: Object,
    }),

    getters: {},

    actions: {
        async buySellRequest(
            client_uuid: string,
            strategy_id: string,
            request_type_id: number,
            equity_id: number,
            quantity: number,
            note?: string
        ) {
            try {
                const apiStore = useApiStore();

                const method = 'POST';
                const url = '/v1/flip/requests/create';

                const formData = new FormData();
                formData.append('client_uuid', client_uuid);
                formData.append('strategy_id', strategy_id);
                formData.append('request_type_id', request_type_id);
                formData.append('equity_id', equity_id);
                formData.append('quantity', quantity);
                if (note) {
                    formData.append('note', note);
                }

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: formData,
                    contentType: 'multipart/form-data',
                    showToast: true,
                });

                this.buySellResponse = response;

                return response;
            } catch (error) {
                throw error;
            }
        },


        async withdrawRequest(
            client_uuid: string,
            strategy_id: string,
            request_type_id: number,
            amount: number,
            all_amount: boolean,
            client_bank_account_id: number,
            note?: string
        ) {
            try {
                const apiStore = useApiStore();

                const method = 'POST';
                const url = '/v1/flip/requests/create';

                const formData = new FormData();
                formData.append('client_uuid', client_uuid);
                formData.append('strategy_id', strategy_id);
                formData.append('request_type_id', request_type_id);
                formData.append('amount', amount);
                formData.append('all_amount', all_amount);
                formData.append('client_bank_account_id', client_bank_account_id);
                if (note) {
                    formData.append('note', note);
                }

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: formData,
                    contentType: 'multipart/form-data',
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },

        async moveStrategyRequest(
            client_uuid: string,
            strategy_id: string,
            target_strategy_id: string,
            amount: number,
            all_amount: boolean,
            note?: string
        ) {
            try {
                const apiStore = useApiStore();

                const method = 'POST';
                const url = '/v1/flip/requests/create';

                const formData = new FormData();
                formData.append('client_uuid', client_uuid);
                formData.append('strategy_id', strategy_id);
                formData.append('target_strategy_id', target_strategy_id);
                formData.append('request_type_id', 2);
                formData.append('amount', amount);
                formData.append('all_amount', all_amount);
                if (note) {
                    formData.append('note', note);
                }

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: formData,
                    contentType: 'multipart/form-data',
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        }

    },
});
