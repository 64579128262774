<template>
  <apexchart
      type="line"
      v-if="props.data.length > 0"
      :options="chartOptions"
      :series="chartData"
      ref="apexChart"
      height="350"
  ></apexchart>
  <h3 v-else>{{ t("no_data_available") }}</h3>
<!--  <h1>{{props.data}}</h1>-->
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  data: number[];
  options: string[];
  type: any;
}>();

const chartData = ref([]);
const chartOptions = ref({});
const chartDataRef = toRef(props, 'data');

onMounted(() => {
  // Call these functions once when the component is mounted
  getChartOptions();
  getChartSeries();
});

// Use a watcher to react to changes in props.data
watch(chartDataRef, () => {
  getChartOptions();
  getChartSeries();
});

const getChartOptions = () => {
  chartOptions.value = {
    chart: {
      id: "vuechart-example",
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "solid",
      opacity: [1, 1],
    },
    xaxis: {
      categories: props.options,
      type: 'datetime',
    },
    yaxis: {
      tickAmount: 6,
      labels: {
        formatter: function (value) {
          return formatter(value);
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#F37021",
        opacity: 1
      },
    },
  };
};

const formatter = (value) => {
  // Format numbers in thousands, millions, etc.
  const numberFormat = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
  return numberFormat.format(value);
};

const getChartSeries = () => {
  const formattedData = props.data.map((value) => {
    const parsedValue = parseFloat(String(value));
    if (!isNaN(parsedValue)) {
      return {
        value: parsedValue,
        formattedValue: formatter(parsedValue),
      };
    } else {
      return null; // Handle invalid values as needed
    }
  });

  chartData.value = [
    {
      name: "Výška provízie",
      data: formattedData.map((dataPoint) => dataPoint?.value),
    },
  ];
};
</script>
