<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <div class="p-8 mobile-view">
      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex justify-between mobile-view-search" v-if="shouldShowTab">
        <span class="p-input-icon-left mr-2 ml-3">
          <h2 class="ml-4 text-xl center-title pt-2">{{ t('commissions_overview') }}</h2>
        </span>
        <div>
          <div class="last-row-first-column-header-item flex">
            <CSelectButton v-model="selectedStatus"
                           option-label="label"
                           option-value="value"
                           @change="selectedStatus = $event.value"
                           :options="formattedStatus"
                           aria-labelledby="basic"
                           :pt="{
                                button: ({ context }) => ({
                                    class: context.active ? 'switcher-flip-selected' : 'switcher-flip'
                                })
                            }"/>
          </div>
        </div>
      </div>

      <div class="flex  mb-4 mobile-view-graph" v-if="selectedStatus === 'time'">
        <div class="rounded-lg shadow-md bg-white p-4 w-full">
          <div class="flex items-center justify-between py-2">
            <h2 class="ml-4 text-xl">{{ t('commissions_by_strategy') }}</h2>
          </div>
          <div class="p-4 gap-8 items-center">
            <div class="w-full flex justify-center">
              <CSkeleton width="100%" height="250px" v-if="!strategyCommissionsChartData"/>
              <TimeProvisionChart v-if="commissionsTimelineChartData.length" :data="commissionsTimelineChartData"
                                  :options="commissionsTimelineChartLabels" type="line" class="w-full"/>
              <h1 v-else class="text-center">{{ t('no_records') }}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-4 mb-4 mobile-view-graph" v-if="selectedStatus === 'type'">
        <div class="rounded-lg shadow-md bg-white p-4 w-1/2">
          <div class="flex items-center justify-between py-2">
            <h2 class="ml-4 text-xl">{{ t('commissions_by_strategy') }}</h2>
          </div>
          <div class="p-4 gap-8 items-center">
            <div class="w-full flex justify-center">
              <CSkeleton width="100%" height="250px" v-if="!strategyCommissionsChartData"/>
              <StrategyCommissionsChart :key="strategyCommissionsChartData.values"
                                        :data="strategyCommissionsChartData.values"
                                        :options="strategyCommissionsChartData.names" type="donut"
                                        v-if="strategyCommissionsChartData"/>
              <h1 v-if="!strategyCommissionsChartData.values.length" class="text-center">{{ t('no_records') }}</h1>
            </div>
          </div>
        </div>

        <div class="rounded-lg shadow-md bg-white p-4 w-1/2">
          <div class="flex items-center justify-between py-2">
            <h2 class="ml-4 text-xl">{{ t('commissions_by_fee_type') }}</h2>
          </div>
          <div class="p-4 gap-8 items-center">
            <div class="w-full flex">
              <CSkeleton width="100%" height="250px" v-if="!feeTypeCommissionsChartData"/>
              <FeeTypeCommissionsChart :data="feeTypeCommissionsChartValues"
                                       :options="feeTypeCommissionsChartName" type="bar"
                                       v-if="feeTypeCommissionsChartName.length > 0"/>
              <h1 v-if="!feeTypeCommissionsChartData.length" class="text-center">{{ t('no_records') }}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex justify-between mobile-view-search">
        <span class="p-input-icon-left mr-2 ml-3">
          <i class="pi pi-search"/>
          <CInputText v-model="searchValue" :placeholder="t('search')"/>
        </span>
        <div>
          <CButton
              v-for="range in chartRanges"
              :key="range.value"
              :class="{ 'time-range-button-selected': chartRange === range.value, 'time-range-button': chartRange !== range.value, 'mobile-view-search-button': true }"
              @click="changeChartRange(range.value)"
          >
            {{ range.label }}
          </CButton>
        </div>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4">
        <div class="flex items-center justify-between py-2">
          <h2 class="ml-4 text-xl">{{ t('commissions') }}</h2>
          <div class="mr-3 text-gray-400 text-sm" v-if="filteredCommissionsList && filteredCommissionsList.length">
            {{ t('records_number') }}: {{ filteredCommissionsList.length }}
          </div>
        </div>
        <div class="table-row-desktop">
          <Table
              v-if="filteredCommissionsList && filteredCommissionsList.length"
              :columns="commissionsColumns"
              :data="filteredCommissionsList"
              :pagination="true"
              class="m-3"
          ></Table>
          <h1 v-else class="text-center">{{ t('no_records') }}</h1>
        </div>
        <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
              <CAccordionTab
                  :pt="{
                  root: { style: { margin: '0', paddingBottom: '0' } },
                  headerIcon: { class: ['display-delete'] },
                  headerAction: {
                    class: ['header-action-custom'],
                    style: { margin: '0', paddingTop: '0', paddingBottom: '0', borderRadius: '0', border: '0' }
                  },
                  content: { style: { paddingTop: '0', borderRadius: '0', border: '0' } }
                }"
                  v-for="(item, index) in tradesListPaginator"
                  :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t('fee_type') }}</span>
                    <p>{{ item?.fee_type }}</p>
                  </div>
                  <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('date') }}</h3>
                    <p>{{ item?.date }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('client') }}</h3>
                    <p>{{ item?.client }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('strategy') }}</h3>
                    <p>{{ item?.strategy_name }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('provision_sfa') }}</h3>
                    <p>{{ item?.provision_sfa }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('provision_pfa') }}</h3>
                    <p>{{ item?.provision_sfa }}</p>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator
              v-model:first="valueInPaginatorTrades"
              :rows="10"
              :totalRecords="filteredCommissionsList.length"
              template="PrevPageLink CurrentPageReport NextPageLink"
          ></Paginator>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Table from "@/components/CustomUIComponents/Table.vue";
import {useCommissionsStore} from "@/stores/commissionsStore";
import {useMainStore} from "@/stores/store";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import StrategyCommissionsChart from "@/components/Charts/StrategyCommissionsChart.vue";
import FeeTypeCommissionsChart from "@/components/Charts/FeeTypeCommissionsChart.vue";
import Paginator from 'primevue/paginator';
import AumChart from "@/components/Charts/agent/AumChart.vue";
import TimeProvisionChart from "@/components/Charts/TimeProvisionChart.vue";
import {useI18n} from "vue-i18n";

export default {
  name: "Commissions",
  components: {TimeProvisionChart, AumChart, FeeTypeCommissionsChart, StrategyCommissionsChart, Table, Paginator},
  setup() {
    const commissionsStore = useCommissionsStore();
    const mainStore = useMainStore();

    const isLoading = computed(() => mainStore.getIsLoading);
    const formattedCommissionsList = computed(() => commissionsStore.getFormattedCommissionsList);
    const strategyCommissionsChartData = computed(() => commissionsStore.getStrategyCommissionList);
    const feeTypeCommissionsChartData = computed(() => commissionsStore.getFeeTypeCommissionList);
    const feeTypeCommissionsChartName = computed(() => commissionsStore.getFeeTypeCommissionListNames);
    const feeTypeCommissionsChartValues = computed(() => commissionsStore.getFeeTypeCommissionListValues);
    const commissionsTimelineChartLabels = computed(() => commissionsStore.getCommissionsTimelineLabels);
    const commissionsTimelineChartData = computed(() => commissionsStore.getCommissionsTimelineData);
    // console.log(commissionsTimelineChartData.value)
    const strategyChartData = ref();
    const strategychartOptions = ref();

    const feeTypeChartData = ref();
    const feeTypechartOptions = ref();

    const searchValue = ref(undefined);

    const chartRange = ref('0Y');

    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);


    const chartRanges = [
      {label: 'Tento mesiac', value: '0M'},
      {label: 'Minulý mesiac', value: '-1M'},
      {label: 'Tento rok', value: '0Y'},
      {label: 'Minulý rok', value: '-1Y'}
    ];

    const filteredCommissionsList = computed(() => {
      if (!searchValue.value) {
        return formattedCommissionsList.value;
      }
      return formattedCommissionsList.value.filter(commission => {
        const search = searchValue.value.toLowerCase();
        const commissionName = commission.strategy_name.toLowerCase();
        const feeType = commission.fee_type.toLowerCase();
        const client = commission.client.toLowerCase();

        return commissionName.includes(search) || feeType.includes(search) || client.includes(search);
      });
    });

    const tradesListPaginator = computed(() => {
      if (filteredCommissionsList.value.length > 10) {
        return filteredCommissionsList.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return filteredCommissionsList.value
      }
    });

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    onBeforeMount(async () => {
      const from_date = new Date();
      const to_date = new Date();
      //from date is the beginning of the current year
      from_date.setMonth(0, 1);
      //to date is the end of the current year
      to_date.setMonth(11, 31);
      await commissionsStore.fetchCommissionsList(0, 100000, from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
      await commissionsStore.fetchCommissionsGroupedByStrategy(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
      await commissionsStore.fetchCommissionsGroupedByFeeType(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
      await commissionsStore.fetchCommissionsTimeline(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
    });


    const changeChartRange = async (range) => {
      const from_date = new Date();
      const to_date = new Date();
      switch (range) {
        case '0M':
          //from date is the beginning of the current month
          from_date.setDate(1);
          //to date is the end of the current month
          to_date.setMonth(to_date.getMonth() + 1, 0);
          break;
        case '-1M':
          //from date is the beginning of the previous month
          from_date.setMonth(from_date.getMonth() - 1, 1);
          //to date is the end of the previous month
          to_date.setDate(0);
          break;
        case '0Y':
          //from date is the beginning of the current year
          from_date.setMonth(0, 1);
          //to date is the end of the current year
          to_date.setMonth(11, 31);
          break;
        case '-1Y':
          //from date is the beginning of the previous year
          from_date.setFullYear(from_date.getFullYear() - 1, 0, 1);
          //to date is the end of the previous year
          to_date.setFullYear(to_date.getFullYear() - 1, 11, 31);
          break;
      }
      chartRange.value = range;
      await commissionsStore.fetchCommissionsList(0, 100000, from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0]);
      await commissionsStore.fetchCommissionsGroupedByStrategy(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
      await commissionsStore.fetchCommissionsGroupedByFeeType(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
      await commissionsStore.fetchCommissionsTimeline(from_date.toISOString().split('T')[0], to_date.toISOString().split('T')[0])
    };

    const selectedStatus = ref('time');
    const formattedStatus = computed(() => {
      return [
        {
          label: t('development_over_time'),
          value: 'time'
        },
        {
          label: t('by_type'),
          value: 'type'
        },
      ];
    });

    const shouldShowTab = ref(false);

    onMounted(() => {
      updateShouldShowTab();
      window.addEventListener('resize', updateShouldShowTab);
    });

    const updateShouldShowTab = () => {
      shouldShowTab.value = window.innerWidth > 1372;
    };

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      commissionsColumns: [
        {field: "date", header: t("date")},
        {field: "fee_type", header: t("fee_type")},
        {field: "client", header: t("client")},
        {field: "strategy_name", header: t("strategy")},
        {field: "provision_sfa", header: t("provision_sfa"), class: "font-bold !text-right !justify-end"},
        {field: "provision_pfa", header: t("provision_pfa"), class: "font-bold !text-right !justify-end"},
      ],

      t,
      currentLocale,
      changeLanguage,

      isLoading,
      formattedCommissionsList,
      filteredCommissionsList,
      searchValue,
      chartRanges,
      chartRange,
      valueInPaginatorTrades,
      tradesListPaginator,
      updateIndexTrades,
      changeChartRange,
      strategyCommissionsChartData,
      feeTypeCommissionsChartData,
      strategyChartData,
      strategychartOptions,
      feeTypeChartData,
      feeTypechartOptions,
      selectedStatus,
      formattedStatus,
      commissionsTimelineChartData,
      commissionsTimelineChartLabels,
      feeTypeCommissionsChartName,
      feeTypeCommissionsChartValues,
      shouldShowTab
    };
  }
}
</script>

<style lang="scss" scoped>
.center-title {
  margin: 0 auto;
}

.time-range-button {
  background-color: white !important;
  color: gray;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  font-size: 0.9rem;

  &:hover {
    color: gray !important;
  }
}

.time-range-button-selected {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 4px !important;
  font-size: 0.9rem;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
  .mobile-view-graph {
    flex-direction: column;
    align-items: center;
    display: none;
  }
  .mobile-view-search {
    flex-direction: column;

    input {
      width: 100%;
    }
  }
  .mobile-view-search-button {
    margin-top: 5px;
    padding: 3px;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-view {
    padding: 1rem;
  }
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
