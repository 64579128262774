
// import { useI18n } from 'vue-i18n'
// const { locale } = useI18n()


export function downloadFile(fileName: string, base64: string) {
 const linkSource = `data:application/octet-stream;base64,${base64}`;
 const downloadLink = document.createElement('a');
 document.body.appendChild(downloadLink);

 downloadLink.href = linkSource;
 downloadLink.target = '_self';
 downloadLink.download = fileName;
 downloadLink.click();
}

export function downloadPDF(file: any, name: any) {
 const hex = file;
 const binary: number[] = [];
 for (let i = 0; i < hex.length / 2; i++) {
  const h = hex.substr(i * 2, 2);
  binary[i] = parseInt(h, 16);
 }
 const byteArray = new Uint8Array(binary);
 const blob = new Blob([byteArray], {type: 'application/pdf'});
 const link = document.createElement('a');
 link.href = URL.createObjectURL(file);
 link.download = name;
 link.click();
 URL.revokeObjectURL(link.href);
}
