import {createRouter, createWebHashHistory} from 'vue-router'
import {useAuthStore} from "@/stores/authStore";

import HomeView from '../views/HomeView.vue'
import TransactionsView from '../views/TransactionsView.vue'
import MyClientsView from '../views/MyClientsView.vue'
import SettingsView from '../views/SettingsView.vue'
import AgentsView from '../views/AgentsView.vue'
import MyAccountView from '../views/MyAccountView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import MyAssetsView from '@/views/MyAssetsView.vue'
import ComponentsView from '@/views/ComponentsView.vue'
import ClientView from '@/views/ClientView.vue'
import AdminView from '@/views/AdminView.vue'
import SecuritiesView from "@/views/SecuritiesView.vue";
import CommissionsView from "@/views/CommissionsView.vue";
import CreateClient from "@/views/CreateClient.vue";
import AgentView from "@/views/AgentView.vue";
import FAView from "@/views/2FAView.vue";


const router = createRouter({
    history: createWebHashHistory(),
    // Use createWebHashHistory for hash mode
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: TransactionsView
        },
        {
            path: '/clients',
            name: 'clients',
            component: MyClientsView
        },
        {
            path: '/create-client/:birthnumber',
            name: 'create-client',
            component: CreateClient
        },
        {
            path: '/clients/:uuid',
            name: 'client',
            component: ClientView,
            props: true
        },
        {
            path: '/assets',
            name: 'assets',
            component: MyAssetsView
        },
        {
            path: '/securities',
            name: 'securities',
            component: SecuritiesView
        },
        {
            path: '/commissions',
            name: 'commissions',
            component: CommissionsView
        },
        {
            path: '/agents',
            name: 'agents',
            component: AgentsView
        },
        {
            path: '/agents/:uuid',
            name: 'agent',
            component: AgentView,
            props: true
        },
        {
            path: '/account',
            name: 'account',
            component: MyAccountView
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView
        },
        {
            path: '/register',
            name: 'register',
            component: RegisterView
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPasswordView
        },
        {
            path: '/fa',
            name: 'fa',
            component: FAView
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: ResetPasswordView
        },
        {
            path: '/admin',
            name: 'admin',
            component: AdminView
        }
    ]
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('authToken') // Check if the user has a token

    if (
        to.name !== 'login' &&
        to.name !== 'forgot-password' &&
        to.name !== 'fa' &&
        !isAuthenticated &&
        (to.name !== 'reset-password' || !to.params.token)
    ) {
        // If not authenticated and not on the login or forgot password page,
        // and it's not the reset-password route or there's no token, redirect to login
        next({name: 'login'})
    } else {
        // User is authenticated or on the login, forgot password, or reset password page with a valid token, proceed
        if (from.name === 'login' && to.name === 'home') {
            setTimeout(() => {
                window.location.reload();
            }, 20);
        }
        next()
    }
})

export default router
