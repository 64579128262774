import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';
import {useAuthStore} from "@/stores/authStore";


export const useMainStore = defineStore({
    id: 'store',

    state: () => ({
        loadingRequests: 0,
    }),

    getters: {
        getIsLoading: (state) => {
            return state.loadingRequests > 0
        }
    },

    actions: {
        incrementLoading() {
            this.loadingRequests++;
        },

        decrementLoading() {
            this.loadingRequests--;

            if (this.loadingRequests < 0) {
                this.loadingRequests = 0;
            }
        },

        stopLoading() {
            this.loadingRequests = 0;
        },
    },
});;
