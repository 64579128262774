Format to eur values in chart<template>
  <apexchart
      v-if="data.length > 0 && options"
      width="800"
      height="400"
      type="donut"
      :options="chartOptions"
      :series="series"
      ref="apexChart"
  >
  </apexchart>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, defineProps } from "vue";

const props = defineProps<{
  data: any;
  options: any;
  type: any;
}>();

const series = ref(props.data);
const chartOptions = ref({
  labels: props.options,
  colors: ['#FF5C33', '#00362E', '#9EC4D6', "#ADABA6", "#000000"],
  dataLabels: {
    enabled: true,
    // formatter: function (val: number) {
    //   return new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(val);
    // },
  },
  tooltip: {
    y: {
      formatter: function (val: number) {
        return new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(val);
      }
    }
  },
  legend: {
    show: false,
  },
});

watch(() => props.data, (newData) => {
  series.value = newData;
});

watch(() => props.options, (newOptions) => {
  chartOptions.value.labels = newOptions;
});
</script>
