<template>
  <header class="bg-primary text-white h-20 flex items-center justify-between px-4 border-b-gray-200 border-b-2">
    <!-- Flex container for back arrow and text -->
    <div id="hamburger-navigation" class="hamburger-navigation nav-close">
      <div class="ham-nav-closed" @click="openNav()">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      <div v-if="navigationOpen" class="ham-nav-open" @click="openNav()">X</div>
    </div>
    <div class="flex items-center space-x-4 pl-4 custom-view-on-mobile">
        <nav id="nav-container" class="flex flex-col nav-container py-8 ml-12">
          <RouterLink
              to="/"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-columns" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path === '/'}"/>
            <span class="menu-text" :class="{'menu-text-active': $route.path === '/'}">{{
                $t("navigation.dashboard")
              }}</span>
          </RouterLink>
          <RouterLink
              to="/clients"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-users" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/clients.*')}"/>
            <span class="menu-text"
                  :class="{'menu-text-active': $route.path.match('/clients.*')}">{{ $t("navigation.clients") }}</span>
          </RouterLink>
          <RouterLink
              to="/assets"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-chart-column" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/assets.*')}"/>
            <span class="menu-text"
                  :class="{'menu-text-active': $route.path.match('/assets.*')}">{{ $t("navigation.assets") }}</span>
          </RouterLink>
          <RouterLink
              to="/transactions"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-hand-holding-usd" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/transactions.*')}"/>
            <span class="menu-text" :class="{'menu-text-active': $route.path.match('/transactions.*')}">{{
                $t("navigation.transactions")
              }}</span>
          </RouterLink>
          <RouterLink
              to="/securities"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-chart-line" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/securities.*')}"/>
            <span class="menu-text" :class="{'menu-text-active': $route.path.match('/securities.*')}">{{
                $t("navigation.securities")
              }}</span>
          </RouterLink>
          <RouterLink
              to="/commissions"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-file-invoice-dollar" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/commissions.*')}"/>
            <span class="menu-text" :class="{'menu-text-active': $route.path.match('/commissions.*')}">{{
                $t("navigation.commissions")
              }}</span>
          </RouterLink>
          <RouterLink
              to="/agents"
              class="router-link"
              @click="openNav()"
          >
            <font-awesome-icon icon="fa-solid fa-users" size="xs" class="menu-icon"
                              :class="{'router-link-active': $route.path.match('/agents.*')}"/>
            <span class="menu-text"
                  :class="{'menu-text-active': $route.path.match('/agents.*')}">{{ $t("navigation.agents") }}</span>
          </RouterLink>
          <RouterLink
          to="/login"
          class="router-link"

          @click="logout"
      >
        <font-awesome-icon icon="fa-solid fa-sign-out-alt" size="xs" class="menu-icon"
                           :class="{'router-link-active': $route.path === '/logout'}"/>
        <span class="menu-text" :class="{'menu-text-active': $route.path === '/logout'}">{{
            $t("navigation.logout")
          }}</span>
      </RouterLink>
        </nav>
      <div>
      <router-link
          v-if="showBackButton"
          :to="{ name: 'clients' }"
          class="flex items-center space-x-2 cursor-pointer"
      >
        <p class="back-button">
          <font-awesome-icon icon="fa-solid fa-arrow-left" size="lg"/>
        </p>
        <span style="color: black">Späť na zoznam</span>
      </router-link>
      <router-link
          v-else-if="showBackButtonAgents"
          :to="{ name: 'agents' }"
          class="flex items-center space-x-2 cursor-pointer"
      >
        <p class="back-button">
          <font-awesome-icon icon="fa-solid fa-arrow-left" size="lg"/>
        </p>
        <span style="color: black">Späť na zoznam</span>
      </router-link>
      <h1 class="text-3xl text-black pl-3 custom-view-for-mobile-device" v-else>{{ getPageName }}</h1>
    </div>
  </div>
    <!-- Flex container for icon and black circle -->
    <div class="flex items-center space-x-4 pr-4">
      <!-- Icon on the left -->
      <RouterLink
          to="/account"
          class="router-link spravy"
          :class="{'router-link-exact-active': $route.path === '/account'}"
      ><p :class="{'notification-counter-shown': accountStore.getUserInfo?.unread_notifications > 0, 'notification-counter-hidden': accountStore.getUserInfo?.unread_notifications === 0}">
        {{ accountStore.getUserInfo?.unread_notifications}}
      </p>
        <p>
          <img src="@/assets/icons/Message.svg" alt="Icon" class="w-8 h-8"/>
        </p>
      </RouterLink>
      <!-- Black circle on the right -->
      <RouterLink
          to="/account"
          class="router-link"
          :class="{'router-link-exact-active': $route.path === '/account'}"
      >
        <CAvatar :label="getAvatarLabel" size="large"
                 shape="circle"
                 class="avatar-flip"/>
      </RouterLink>
    </div>
  </header>
  <!-- Slot for additional content -->
  <slot></slot>

</template>

<script setup lang="ts">
import {RouterLink, RouterView, useRoute} from 'vue-router';
import {computed, ref, onBeforeMount} from 'vue';
import {useAccountStore} from "@/stores/accountStore";
import {useAuthStore} from "@/stores/authStore";
import {useI18n} from "vue-i18n";


const {t, locale} = useI18n();

const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('authTokenExpiresAt');
  localStorage.removeItem('auth');
  useAuthStore().authToken = "";
  useAuthStore().authTokenExpiresAt = "";
};
const route = useRoute();
const accountStore = useAccountStore();

const showNavigation = computed(() => {
  const routesToHideNavigation = ['/login', '/register', '/forgot-password', '/fa'];
  return !routesToHideNavigation.includes(route.path);
});


const showBackButton = computed(() => {
  // Check if the current route matches the client details route pattern
  return /^\/clients\/[a-f0-9-]+$/.test(route.path);
});
const showBackButtonAgents = computed(() => {
  // Check if the current route matches the client details route pattern
  return /^\/agents\/[a-f0-9-]+$/.test(route.path);
});

const getPageName = computed(() => {
  const routeNameMap: Record<string, string> = {
    '/': t('pages.overview'),
    '/transactions': t('pages.transactions'),
    '/clients': t('pages.clients'),
    '/assets': t('pages.assets'),
    '/securities': t('pages.securities'),
    '/commissions': t('pages.commissions'),
    '/settings': t('pages.settings'),
    '/account': t('pages.profile'),
    '/admin': t('pages.admin'),
    '/agents': t('pages.agents'),
  };

  if (route.path.includes('/create-client')) {
    return t('pages.create_client');
  }

  return routeNameMap[route.path] || t('pages.unknown');
});


const updateNotificationCounter = () => {
  setInterval(() => {
    accountStore.fetchUserInfo(false);
    accountStore.fetchNotifications(false);
  }, 120000);
};

const getAvatarLabel = computed(() => {
  const userInfo = accountStore.getUserInfo;
  // console.log('User Info:', userInfo)

  if (userInfo && userInfo.username) {
    if (userInfo.username.charAt(0) === '.') {
      const secondLetter = userInfo.username.charAt(1).toUpperCase();
      // console.log('Initial:', secondLetter);
      return secondLetter;
    } else {
      // If username is not empty and doesn't start with a dot, show it
      return userInfo.name.charAt(0).toUpperCase() + userInfo.username.charAt(0).toUpperCase();;
    }
  } else if (userInfo && userInfo.name && userInfo.username) {
    // If username is not available, show name if it exists
    return userInfo.name.charAt(0).toUpperCase() + userInfo.username.charAt(0).toUpperCase();
  }
  return '';
});



const navigationOpen = ref(false);

const openNav = () => {
  if (!navigationOpen.value) {
    document.getElementById("hamburger-navigation").classList.remove("nav-close");
    document.getElementById("hamburger-navigation").classList.add("nav-open");
    document.getElementById("nav-container").style.display = "flex";
    navigationOpen.value = true;
  } else {
    document.getElementById("hamburger-navigation").classList.remove("nav-open");
    document.getElementById("hamburger-navigation").classList.add("nav-close");
    document.getElementById("nav-container").style.display = "none";
    navigationOpen.value = false;
  }

};

onBeforeMount(() => {
  if (localStorage.getItem('authToken')) {
    accountStore.fetchUserInfo();
  }
  updateNotificationCounter();
});
</script>
<style scoped lang="scss">
.hamburger-navigation{
  display: none;
  margin-right: 10px;
}


.ham-nav-closed{
  display: block;
  width: 30px;
  height: 30px;
  z-index: 999;
  margin-top: -2px;
  span{
    display: block;
    height: 2px;
    width: 100%;
    background: black;
    margin-bottom: 3px;
    background: black;
}
}

.ham-nav-open{
  position: relative;
  width: 30px;
  height: 30px;
  left: 25px;
  top: -10px;
  z-index: 999;
  color: white;
  font-size: 32px;
  background: black;
}

.nav-open{
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  height: 950px;
}

.nav-close{
  margin-top: -10px;
  width: 0;
  height: 0;
  background: black;
}
.avatar-flip {
  background-color: black;
  color: white;
  border-radius: 15px !important;
}

router-link {
  color: white;
}

body {
  overflow: hidden;
}

.nav-container{
  background: black;
  display: none;
  position: absolute;
  top: 60px;
  left: -20px;
  z-index: 999;
  font-size: 20px;
  font-weight: 600;
  font-size: 26px;
  a{
    margin: 10px 0;
  }
  .menu-icon{
    margin-right: 10px;
  }
}

.notification-counter-shown {
  background-color: #FF5C33;
  padding: 2px 5px 2px 5px;
  color: white;
  border-radius: 50px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  left: -20px;
  top: 15px;
}
.notification-counter-hidden {
  visibility: hidden;
  background-color: transparent;
  padding: 2px 5px 2px 5px;
  color: transparent;
  border-radius: 50px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  left: -20px;
  top: 15px;
}

.spravy {
  margin-top: -15px !important;
}

/* Define a custom CSS class for scrollable content */

@media screen and (max-width: 550px) {
  .custom-view-for-mobile-device {
    padding-left: 0;
    margin-left: -30px;
    font-size: 24px;
  }
  .custom-view-on-mobile{
    padding: 0;
  }
  .custom-view-on-mobile h1{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 160px;
  }
}

@media screen and (max-width: 890px) {
  .hamburger-navigation{
  display: block;
  color: black;
}
}

</style>
