<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <div class="p-8 mobile-view">
      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex justify-between mobile-view-search">
        <span class="p-input-icon-left mr-2 ml-3">
          <i class="pi pi-search"/>
          <CInputText v-model="searchValue" :placeholder="t('search')"/>
        </span>
        <div class="space-x-4 mr-2">
          <CButton :label="t('create_agent')" class="black-button" @click="modalOpened = true"/>
        </div>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4">
        <div class="flex items-center justify-between py-2 custom-css">
          <h2 class="ml-4 text-xl">{{ t('agents_list') }}</h2>
          <div class="mr-2 text-gray-400 text-sm" v-if="filteredAgentsList && filteredAgentsList.length">
            {{ t('records_number') }}: {{ filteredAgentsList.length }}
          </div>
        </div>
        <Table :columns="agentsColumns"
               :data="filteredAgentsList"
               :pagination="true"
               class="m-3 table-row-desktop"
               @row-click="selectAgent($event)">
          <CColumn field="icon">
            <template #body="{ data }">
              <p class="menu-icon-clickable">
                <font-awesome-icon icon="fa-solid fa-ellipsis-h" size="lg" class="nested-icon"/>
              </p>
            </template>
          </CColumn>
        </Table>
        <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
              <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                             v-for="(item, index) in tradesListPaginator" :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>{{ t('name') }}</span>
                    <p>{{ item?.name }} {{ item?.surname }}</p>
                  </div>
                  <i  :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('email') }}</h3>
                    <p>{{ item?.email }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('phone') }}</h3>
                    <p>{{ item?.phone }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('reg_number') }}</h3>
                    <p>{{ item?.reg_number }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>{{ t('position') }}</h3>
                    <p>{{ item?.position }}</p>
                  </div>
                </div>
                <div class="content-row-buttons">
                  <div class="content-row-item">
                    <router-link :to="{ name: 'agent', params: { uuid: item.uuid } }">
                      <button>{{ t('details') }}</button>
                    </router-link>
                  </div>
                </div>
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator v-model:first="valueInPaginatorTrades" :rows="10" :totalRecords="filteredAgentsList.length" template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
        </div>
      </div>
    </div>

    <CSidebar
        :header="t('instruction')"
        v-model:visible="modalOpened"
        position="right"
        :pt="{closeButton: {class: ['close-button']}}"
        style="width: 500px;">
      <template #header>
        <h1 style="font-size: 1.5rem;">{{ t('new_agent') }}</h1>
      </template>
      <div class="root-ctab-panel-items mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('name') }}</label>
          <CInputText v-model="newAgent.name"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('surname') }}</label>
          <CInputText v-model="newAgent.surname"/>
        </div>

        <div class="flex flex-column gap-2 column-item">
          <label for="selectedContract" class="input-label">{{ t('country') }}</label>
          <CDropdown v-model="newAgent.country" :options="countryList" filter
                     optionLabel="name"
                     :placeholder="t('search_country')" class="w-full md:w-14rem mb-2"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('email') }}</label>
          <CInputText v-model="newAgent.email"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('phone') }}</label>
          <CInputText v-model="newAgent.phone"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('position') }}</label>
          <CInputText v-model="newAgent.position"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="note" class="input-label">{{ t('agent_reg_number') }}</label>
          <CInputText v-model="newAgent.reg_number"/>
        </div>
      </div>
      <div class="mt-5 flex justify-between">
        <CButton class="gray-button grow !mr-4" :label="t('close')" @click="modalOpened = false"/>
        <CButton :label="t('create')" class="black-button grow !ml-4" :disabled="buttonDisabled" @click="createAgent" />
      </div>
    </CSidebar>
  </div>
</template>


<script>
import Table from "@/components/CustomUIComponents/Table.vue";
import Loader from "@/components/Loader.vue";
import {useAgentsStore} from "@/stores/agentsStore";
import {useEnumsStore} from "@/stores/enumsStore";
import {useMainStore} from "@/stores/store";
import {computed, onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import Paginator from 'primevue/paginator';
import {useI18n} from "vue-i18n";

export default {
  name: "AgentsView",
  components: {Loader, Table, Paginator},
  setup() {
    const agentsStore = useAgentsStore();
    const mainStore = useMainStore();
    const enumsStore = useEnumsStore();
    const router = useRouter();

    const isLoading = computed(() => mainStore.getIsLoading);
    const agentsList = computed(() => agentsStore.getFormattedAgentsList);
    const countryList = computed(() => enumsStore.getCountries);
    const formattedAgentsList = computed(() => agentsStore.getFormattedAgentsList);

    const filteredAgentsList = computed(() => {
      if (!searchValue.value) {
        return formattedAgentsList.value;
      }
      return formattedAgentsList.value.filter(agent => {
        const search = searchValue.value.toLowerCase();
        const agentName = agent.name.toLowerCase();
        const agentSurname = agent.surname.toLowerCase();

        return agentName.includes(search) || agentSurname.includes(search);
      });
    });

    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (formattedAgentsList.value.length > 10) {
        return formattedAgentsList.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return formattedAgentsList.value
      }
    });
    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if(element === null){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if(previousIndexTrades.value === null){
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if(element !== previousIndexTrades.value){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    const modalOpened = ref(false);
    const searchValue = ref(undefined);

    const buttonDisabled = computed(() => {
      const { name, surname, country, email, phone, position } = newAgent.value;
      return !name || !surname || !country || !email || !phone || !position;
    });

    // const showError = () => {
    //   toast.add({ severity: 'error', summary: 'Error Message', detail: 'Message Content', life: 3000 });
    // };

    const newAgent = ref({
      name: "",
      surname: "",
      country: {},
      email: "",
      phone: "",
      position: "",
      reg_number: "",
    });

    onBeforeMount(() => {
      agentsStore.fetchAgentsList(0, 1000);
    });

    const selectAgent = (agent) => {
      router.push({name: 'agent', params: {uuid: agent.data.uuid}});
    };

    const createAgent = async () => {
      try {
        const response = await agentsStore.createAgent(newAgent.value.name, newAgent.value.surname, newAgent.value.country, newAgent.value.email, newAgent.value.phone, newAgent.value.position, newAgent.value.reg_number);

        // Log the response
        console.log('Response:', response);

        // Check if the status is 200
        // if (response.status === 200) {
          // Close the modal if the status is 200
          modalOpened.value = false;

          // Refresh the agents list
          agentsStore.fetchAgentsList(0, 1000);

          // Reset the newAgent value
          newAgent.value = {
            name: "",
            surname: "",
            country_id: null,
            email: "",
            phone: "",
            position: "",
            reg_number: "",
          };
        // } else {
          // Handle other status codes if needed
          console.error('Unexpected status code:', response.status);
        // }
      } catch (error) {
        // Handle errors here
        console.error('Error creating agent:', error);
        // Optionally, you can display an error message or handle the error in another way
      }
    };
    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      agentsColumns: [
        {field: "name", header: t("name"), sortable: true},
        {field: "surname", header: t("surname"), sortable: true},
        {field: "email", header: t("email")},
        {field: "phone", header: t("phone")},
        {field: "reg_number", header: t("reg_number"), sortable: true},
        {field: "position", header: t("position")},
      ],
      t,
      currentLocale,
      changeLanguage,
      agentsList,
      isLoading,
      formattedAgentsList,
      modalOpened,
      newAgent,
      countryList,
      createAgent,
      filteredAgentsList,
      searchValue,
      selectAgent,
      updateIndexTrades,
      tradesListPaginator,
      valueInPaginatorTrades,
      buttonDisabled,
    };
  }
}
</script>

<style scoped>

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.menu-icon-clickable {
  cursor: pointer;
  padding: 12px 14px 12px 14px;
  border-radius: 5px;
  width: 50px;
  text-align: center;
}

.menu-icon-clickable:hover {
  background-color: #000000;
  color: #fff;
  padding: 12px 14px 12px 14px;
  border-radius: 5px;
  width: 50px;
  text-align: center;
}

.change-pill-down {
  padding: 0px 10px 0px 10px;
  border-radius: 100px;
  text-align: center;
  background-color: rgb(237, 94, 53);
  color: white;
  font-size: 0.8rem;
}

.change-pill-up {
  padding: 0px 10px 0px 10px;
  border-radius: 100px;
  text-align: center;
  background-color: #122f29;
  color: white;
  font-size: 0.8rem;
}
.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  p {
    font-weight: 500;
    color: black;
  }
}
.content-row {
  width: 100%;
  justify-content: space-between;
  .content-row-item {
    min-width: 150px;
    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }
    p {
      font-weight: 500;
      color: black;
    }
  }
}
.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}
.content-row-buttons {
  margin-top: 10px;
  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}
.table-row-tab-mobile {
  display: none;
}
@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .custom-css {
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
    }
  }
  .mobile-view{
    padding: 5px;
  }
  .mobile-view-search{
    flex-direction: column;
    gap: 10px;
    input{
      width: 100%;
    }
    button{
      width: 100%;
    }
  }
  .mobile-custom-view{
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header{
    flex-direction: column;
    gap: 10px;
    span{
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid{
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
