<template>
  <div v-if="maintenanceMode" class="flex h-screen items-center justify-center">
    <div :class="showNavigation ? 'w-10/12' : 'w-full'" class="h-full scrollable-content">
      <MaintenancePage/>
    </div>
  </div>
  <div v-else class="flex h-screen">
    <!-- Navigation - 2/12 column -->
    <nav v-if="showNavigation" class="w-2/12 h-full bg-black p-4 text-white wd custom-mobile-view">
      <Navigation/>
    </nav>

    <!-- RouterView - 10/12 column -->
    <div :class="showNavigation ? 'w-10/12' : 'w-full'" class="h-full scrollable-content">
      <!-- Header -->
      <HeaderComponent v-if="showNavigation"/>
      <div class="h-full bg-[#E5E4E3]">
        <div class="h-full flex items-center justify-center" v-if="isLoading">
          <Loader/>
        </div>
        <RouterView class="h-max bg-[#E5E4E3]"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {RouterView, useRoute, useRouter} from 'vue-router';
import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import Navigation from './components/Navigation.vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import Loader from "@/components/Loader.vue";
import MaintenancePage from "@/views/Maintenance.vue";
import {useAuthStore} from "@/stores/authStore";
import {useEnumsStore} from '@/stores/enumsStore';
import {useMainStore} from "@/stores/store";

// Stores
const authStore = useAuthStore();
const enumsStore = useEnumsStore();
const mainStore = useMainStore();

// Computed properties
const transactionTypes = computed(() => enumsStore.getTransactionsTypes);
const isLoading = computed(() => mainStore.getIsLoading);
const showNavigation = computed(() => {
  const routesToHideNavigation = ['/login', '/register', '/forgot-password', '/fa'];
  return !routesToHideNavigation.includes(route.path) && !route.path.includes('/reset-password/');
});
const isMyClientsRoute = computed(() => route.path === '/clients');

// State variables
const route = useRoute();
const router = useRouter();
const search = ref('');
let logoutTimer = ref(null);
const maintenanceMode = ref(false);

// Helper functions
const startLogoutTimer = () => {
  if (logoutTimer.value) {
    clearTimeout(logoutTimer.value);
  }

  logoutTimer.value = setTimeout(() => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenExpiresAt');
    localStorage.removeItem('auth');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('clients');
    router.push({name: 'login'});
  }, 5 * 60 * 1000); // 5 minutes timeout
};

const resetLogoutTimer = () => {
  document.addEventListener('mousemove', startLogoutTimer);
  document.addEventListener('keypress', startLogoutTimer);
  document.addEventListener('touchstart', startLogoutTimer);
};

const checkApiHealth = async () => {
  const url = `https://api-flip.wem.sk/actuator/health`;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 6000); // 6 seconds timeout

  try {
    const response = await fetch(url, { signal: controller.signal });
    clearTimeout(timeoutId);
    const data = await response.json();

    if (response.ok && data.status === 'UP') {
      maintenanceMode.value = false;
    } else {
      maintenanceMode.value = true;
      console.error("API health check failed:", data);
    }
  } catch (error) {
    maintenanceMode.value = true;
    console.error("Error checking API health:", error);
  }
};

const startApiHealthCheck = () => {
  checkApiHealth(); // Initial check
  setInterval(checkApiHealth, 30000); // Check every 30 seconds
};

// Lifecycle hooks
onMounted(() => {
  authStore.checkTokenExpiration();
  resetLogoutTimer();
  startApiHealthCheck();

  const tokenExpirationCheckInterval = setInterval(() => {
    authStore.checkTokenExpiration();
  }, 2000);

  onBeforeUnmount(() => {
    clearInterval(tokenExpirationCheckInterval);
    clearTimeout(logoutTimer.value);
    document.removeEventListener('mousemove', startLogoutTimer);
    document.removeEventListener('keypress', startLogoutTimer);
    document.removeEventListener('touchstart', startLogoutTimer);
  });
});
</script>

<style lang="scss" scoped>
router-link {
  color: white;
}

body {
  overflow: hidden;
}

/* Define a custom CSS class for scrollable content */
.scrollable-content {
  overflow: auto;
}

@media only screen and (max-width: 1500px) {
  nav {
    width: 75px !important;
  }
  .scrollable-content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 890px) {
  .custom-mobile-view {
    display: none;
  }
}
</style>
