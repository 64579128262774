import { fr } from '@formkit/i18n'
// import { DefaultConfigOptions } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
// @ts-ignore
import myTailwindTheme from './tailwind-theme.js'

export default {
    icons: {
        ...genesisIcons,
    },
    config: {
        classes: generateClasses(myTailwindTheme),
        locales: { fr },
        locale: 'fr',
    },
}
