<template>
  <div class="h-full">
    <div class="rounded-lg shadow-md bg-white p-4 m-3" v-if="!visibleMessage">
      <div class="flex filters items-center justify-between py-2">
        <h2 class="ml-4 root-ctab-panel-h1">{{ t('messages') }}</h2>
        <span class="p-input-icon-left mx-2 ml-4 custom-header-assets-item-first">
          <i class="pi pi-search" />
          <CInputText
              v-model="searchQuery"
              :placeholder="t('search')"
              class="w-64 mr-3"
          />
        </span>
      </div>
      <Table
          :columns="messagesColumns"
          :data="filteredMessages"
          :pagination="true"
          class="m-3 table-row-desktop"
      >
        <CColumn field="icon">
          <template #body="icon">
            <font-awesome-icon
                icon="fa-solid fa-eye"
                size="lg"
                class="menu-icon"
                @click="showMessage(icon.data)"
            />
          </template>
        </CColumn>
      </Table>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
            <CAccordionTab :pt="accordionStyle">
              <template #header>
                <div class="customer-header-row">
                  <span>{{ t('subject') }}</span>
                  <p>{{ item?.subject }}</p>
                </div>
                <i :id="'pi-plus-messages-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex" v-if="item?.messageWithHtml">
                <div class="content-row-item">
                  <h3>{{ t('message_text') }}</h3>
                  <p v-html="item?.messageWithHtml"></p>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>

        <Paginator
            v-model:first="valueInPaginatorTrades"
            :rows="10"
            :totalRecords="filteredMessages.length"
            :template="t('paginator_template')"
        ></Paginator>
      </div>
    </div>

    <div class="rounded-lg shadow-md bg-white p-7 m-3" v-if="visibleMessage">
      <div class="flex items-center justify-between">
        <h2 class="root-ctab-panel-h1">{{ t('messages') }}</h2>
      </div>
      <span @click="visibleMessage = false" class="cursor-pointer flex gap-1 mt-4 spat">
        <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            size="lg"
            class="menu-icon"
            @click="visibleMessage = false"
        />
        <p>{{ t('back_to_list') }}</p>
      </span>
      <hr />
      <div class="sprava pt-7">
        <div class="message-header">
          <h1 class="font-bold text-xl">{{ selectedMessage.subject }}</h1>
          <div class="message-details">
            <p class="message-type">{{ selectedMessage?.type }}</p>
            <font-awesome-icon
                icon="fa-solid fa-calendar"
                size="lg"
                class="menu-icon mr-3"
            />
            <p>{{ selectedMessage?.date }}</p>
          </div>
        </div>
        <div v-html="selectedMessage.messageWithHtml" class="pt-6" />
        <div
            v-for="file in selectedMessage.files"
            :key="file.id"
            class="file-card mt-4 p-4 border rounded-lg flex items-center justify-between"
        >
          <p class="file-title">{{ file?.file_name }}</p>
          <font-awesome-icon
              icon="fa-solid fa-download"
              size="lg"
              class="menu-icon cursor-pointer"
              @click="downloadMessageFile(uuid, file.file_id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {useClientsStore} from "@/stores/clientsStore";
import {useRoute} from "vue-router";
import {computed, ref} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Paginator from 'primevue/paginator';
import {useI18n} from "vue-i18n";

export default {
  name: 'ClientMessages',
  components: {FontAwesomeIcon, Table, Paginator},
  setup() {
    const clientStore = useClientsStore();
    const messages = computed(() => clientStore.getFormattedMessagesList);

    const searchQuery = ref(""); // Pridané vyhľadávanie

    // Filtrované správy podľa vyhľadávacieho dotazu
    const filteredMessages = computed(() => {
      if (!searchQuery.value) {
        return messages.value;
      }
      return messages.value.filter(message =>
          message.subject.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          message.messageWithoutHtml.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          message.type.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          message.date.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (filteredMessages.value.length > 10) {
        return filteredMessages.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return filteredMessages.value;
      }
    });

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-messages-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    const route = useRoute();
    const uuid = computed(() => route.params.uuid);

    const selectedMessage = ref(undefined);
    const visibleMessage = ref(false);

    const formatDate = (dateString) => {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      const date = new Date(dateString);
      return date.toLocaleDateString('sk-SK', options);
    };

    const showMessage = (message) => {
      visibleMessage.value = true;
      selectedMessage.value = message;
    }

    // const downloadMessageFile = (client_uuid, file_id) => {
    //   clientStore.fetchMessageFile(client_uuid, file_id);
    // }

    const downloadMessageFile = async (client_uuid, file_id) => {
      const fileData = await clientStore.fetchMessageFile(client_uuid, file_id);
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${fileData.file}`;
      link.download = fileData.name;
      link.click();
    };

    const { t, locale } = useI18n();

    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    return {
      searchQuery, // Pridaná premenna
      filteredMessages, // Pridané filtrovanie
      messagesColumns: [
        {field: "subject", header: "Predmet"},
        {field: "messageWithoutHtml", header: "Text správy"},
        {field: "type", header: "Typ"},
        {field: "date", header: "Dátum"},
      ],
      t,
      currentLocale,
      changeLanguage,
      messages,
      formatDate,
      showMessage,
      selectedMessage,
      downloadMessageFile,
      uuid,
      visibleMessage,
      valueInPaginatorTrades,
      updateIndexTrades,
      tradesListPaginator,
    };
  },
}
</script>

<style lang="scss" scoped>
.spat {
  align-items: center;
  font-size: 0.85rem;
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.message-details {
  display: flex;
  align-items: center;
  color: gray;
}

.message-type {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 0.1rem 0.8rem;
  text-align: center;
  margin-right: 2rem;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

hr {
  margin: 10px 0;
  color: black !important;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

</style>
