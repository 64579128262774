import { defineStore } from 'pinia';
import { useApiStore } from './apiStore';

interface EnumsState {
    transactionsTypes: any[];
    investmentStrategies: any[];
    contractTypes: any[];
    fileTypes: any[];
    equityTypes: any[];
    personTypes: any[];
    requestTypes: any[];
    riskProfiles: any[];
    idcardTypes: any[];
    currencies: any[];
    banks: any[];
    countries: any[];
    agentStrategies: any[];
}

export const useEnumsStore = defineStore({
    id: 'enums',

    state: (): EnumsState => ({
        transactionsTypes: [],
        investmentStrategies: [],
        contractTypes: [],
        fileTypes: [],
        equityTypes: [],
        personTypes: [],
        requestTypes: [],
        riskProfiles: [],
        idcardTypes: [],
        currencies: [],
        banks: [],
        countries: [],
        agentStrategies: [],
    }),

    getters: {
        getTransactionsTypes(): any[] {
            return this.transactionsTypes;
        },
        getInvestmentStrategies(): any[] {
            return this.investmentStrategies;
        },
        getContractTypes(): any[] {
            return this.contractTypes;
        },
        getFileTypes(): any[] {
            return this.fileTypes;
        },
        getEquityTypes(): any[] {
            return this.equityTypes;
        },
        getPersonTypes(): any[] {
            return this.personTypes;
        },
        getRequestTypes(): any[] {
            return this.requestTypes;
        },
        getRiskProfiles(): any[] {
            return this.riskProfiles;
        },
        getIdcardTypes(): any[] {
            return this.idcardTypes;
        },
        getCurrencies(): any[] {
            return this.currencies;
        },
        getBanks(): any[] {
            return this.banks;
        },
        getCountries(): any[] {
            return this.countries;
        },
        getAgentStrategies(): any[] {
            return this.agentStrategies;
        },
    },

    actions: {
        async fetchTransactionsTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/transaction_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.transactionsTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchInvestmentStrategies(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/investment_strategies';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.investmentStrategies = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchAgentStrategies(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/agents/agent_strategies';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.agentStrategies = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchContractTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/contract_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.contractTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchFileTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/file_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.fileTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchEquityTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/equity_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.equityTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchPersonTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/person_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.personTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchRequestTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/request_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.requestTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchRiskProfiles(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/risk_profiles';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.riskProfiles = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchIdcardTypes(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/idcard_types';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.idcardTypes = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchCurrencies(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/currencies';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.currencies = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchBanks(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/banks';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.banks = response;

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchCountries(): Promise<any> {
            try {
                const apiStore = useApiStore();
                apiStore.loading = false

                const method = 'GET';
                const url = '/v1/flip/enums/countries';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                this.countries = response;

                return response;
            } catch (error) {
                throw error;
            }
        },
    },
    persist: true,
});
