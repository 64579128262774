<template>
  <!-- Number of Clients Over Time -->
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <h1 class="root-ctab-panel-h1">{{ t('clients_over_time') }}</h1>
    <div class="root-ctab-panel-items space-x-5 mt-7">
      <ClientsChart
          v-if="agentClientsData && agentClientsLabels"
          :data="agentClientsData"
          :options="agentClientsLabels"
          type="line"
      />
    </div>
  </div>

  <!-- AUM Over Time -->
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <h1 class="root-ctab-panel-h1">{{ t('aum_over_time') }}</h1>
    <div class="root-ctab-panel-items space-x-5 mt-7">
      <AumChart
          v-if="agentAumLabels && agentAumData"
          :data="agentAumData"
          :options="agentAumLabels"
          type="line"
      />
    </div>
  </div>

  <!-- Provisions Over Time -->
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <h1 class="root-ctab-panel-h1">{{ t('provisions_over_time') }}</h1>
    <div class="root-ctab-panel-items space-x-5 mt-7">
      <ProvisionChart
          v-if="agentProvisionData && agentProvisionLabels"
          :data="agentProvisionData"
          :options="agentProvisionLabels"
          type="line"
      />
    </div>
  </div>

  <!-- AUM by Strategies and Top Clients -->
  <div class="m-3 riadok">
    <!-- AUM by Strategies -->
    <div class="p-7 bg-white rounded-lg shadow-md mb-8 w-1/2 riadok-item mr-8">
      <h1 class="root-ctab-panel-h1">{{ t('aum_by_strategy') }}</h1>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <AumByStrategyChart
            :data="agentAumByStrategyDataAssets"
            :options="agentAumByStrategyLabels"
        />
      </div>
    </div>

    <!-- Top 10 Clients -->
    <div class="p-7 bg-white rounded-lg shadow-md mb-8 w-1/2 riadok-item">
      <h1 class="root-ctab-panel-h1">{{ t('top_clients') }}</h1>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <TopClientsChart
            :data="getAgentTopClientsDataAssets"
            :options="agentTopClientsLabels"
        />
      </div>
    </div>
  </div>
</template>


<script setup>
import {computed, onBeforeMount, ref} from "vue";
import {useAgentStore} from "@/stores/agentStore";
import ClientsChart from "@/components/Charts/agent/ClientsChart.vue";
import AumChart from "@/components/Charts/agent/AumChart.vue";
import ProvisionChart from "@/components/Charts/agent/ProvisionChart.vue";
import AumByStrategyChart from "@/components/Charts/agent/AumByStrategyChart.vue";
import TopClientsChart from "@/components/Charts/agent/TopClientsChart.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const agentStore = useAgentStore();
const agentClientsLabels = computed(() => agentStore.getAgentNoOfClientsLabels);
const agentClientsData = computed(() => agentStore.getAgentNoOfClientsData);
const agentAumLabels = computed(() => agentStore.getAgentAumLabels);
const agentAumData = computed(() => agentStore.getAgentAumData);
const agentProvisionLabels = computed(() => agentStore.getAgentProvisionLabel);
const agentProvisionData = computed(() => agentStore.getAgentProvisionData);
const agentAumByStrategyLabels = computed(() => agentStore.getAgentAumByStrategyLabel);
const agentAumByStrategyDataAssets = computed(() => agentStore.getAgentAumByStrategyDataAssets);
const agentAumByStrategyDataCash = computed(() => agentStore.getAgentAumByStrategyDataCash);
const agentAumByStrategyDataAum = computed(() => agentStore.getAgentAumByStrategyDataAum);
const agentTopClientsLabels = computed(() => agentStore.getAgentTopClientsLabel);
const getAgentTopClientsDataAssets = computed(() => agentStore.getAgentTopClientsDataAssets);
const getAgentTopClientsDataCash = computed(() => agentStore.getAgentTopClientsDataCash);
const getAgentTopClientsDataAum = computed(() => agentStore.getAgentTopClientsDataAum);



</script>
<style lang="scss" scoped>

hr {
  margin: 10px 0;
}

.riadok {
  display: flex;
  flex-direction: row;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

@media only screen and (max-width: 1372px) {
  .riadok{
    flex-direction: column;
  }
  .riadok-item{
    width: 100%;
  }
}

</style>
