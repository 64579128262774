<template>
  <div class="flex h-screen bg-white">
    <!-- Left Side: Login Form -->
    <div class="w-1/2 p-8 relative flex flex-col justify-center items-center">
      <!-- Logo at the very top -->
      <img src="../assets/Logo_Clear.svg" alt="Logo" class="w-[100px] h-[100px] absolute top-0 left-0 mt-12 ml-9"/>

      <!-- Body Section -->
      <form @submit.prevent="resetPassword" class="text-left">
        <!-- Header Section -->
        <div class="mb-8">
          <!-- Header Text -->
          <h1 class="text-4xl">{{ t('change_password') }}</h1>
        </div>

        <!-- Password Input -->
        <p class="text-sm text-gray-400 py-1">{{ t('new_password') }}</p>
        <div class="mb-4">
          <CInputText
              v-model="password"
              type="password"
              class="w-420 px-3 py-2 border rounded-md"
          />
        </div>

        <!-- Password Confirm Input -->
        <p class="text-sm text-gray-400 py-1">{{ t('confirm_new_password') }}</p>
        <div class="mb-4">
          <CInputText
              v-model="passwordConfirm"
              type="password"
              class="w-420 px-3 py-2 border rounded-md"
          />
        </div>

        <!-- horizontal line -->
        <div class="py-4">
          <hr class="mb-4">
        </div>

        <div class="mb-6" v-if="passwordMismatch">
          <h3 class="p-error">{{ t('password_mismatch') }}</h3>
        </div>

        <div class="mb-6" v-if="passwordShort">
          <h3 class="p-error">{{ t('password_short') }}</h3>
        </div>

        <!-- Submit Button -->
        <div class="mb-4">
          <button
              type="submit"
              class="w-420 bg-black text-white py-2 rounded-md"
          >
            {{ t('change_password_button') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Right Side: Video -->
    <div class="w-1/2 bg-black relative custom-css-mobile" v-if="showVideo()">
      <video autoplay loop muted class="w-full h-full absolute inset-0 object-cover">
        <source src="../assets/BallVideo.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>


<script>
import {useAuthStore} from "@/stores/authStore";
import {useRouter} from 'vue-router';
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';

export default {
  name: "ResetPasswordView",
  data() {
    return {
      password: "",
      passwordConfirm: "",
      passwordMismatch: false,
      passwordShort: false,
    };
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const password = ref(""); // Create a ref for password
    const passwordConfirm = ref(""); // Create a ref for passwordConfirm
    const passwordMismatch = ref(false); // Create a ref for passwordMismatch
    const passwordShort = ref(false); // Create a ref for passwordShort

    const {t, locale} = useI18n(); // Use the I18n plugin
    const currentLocale = ref(locale.value);
    const changeLanguage = () => {
      locale.value = currentLocale.value;
    };

    const showVideo = () => {
      return window.innerWidth > 950;
    }

    const resetPassword = async () => {
      if (password.value.length < 8) {
        passwordShort.value = true; // Set the flag to true
        return;
      }

      // Check if passwords match
      if (password.value !== passwordConfirm.value) {
        passwordMismatch.value = true; // Set the flag to true
        return;
      }

      try {
        const {token} = router.currentRoute.value.params;

        // Call the changePassword action with the token and new password
        await authStore.changePassword({
          token,
          new_password: password.value, // Use the ref value
        });

        // Redirect to a success page or home
        router.push("/login");
      } catch (error) {
        console.error("Reset password error:", error);
      }
    };



    return {
      t,
      currentLocale,
      changeLanguage,
      password,
      passwordConfirm,
      passwordMismatch,
      passwordShort,
      resetPassword,
      showVideo,
    };
  },
}
</script>

<style scoped>
.w-420 {
  width: 420px;
}

@media only screen and (max-width: 950px) {
  .custom-css-mobile {
    display: none;
  }
}
</style>
